import React from "react";
import { Doughnut } from "react-chartjs-2";


const GraficoApontamento = ({ graficoDadosApontamento }) => {
  let restoApont = 100 - parseInt(graficoDadosApontamento.PERCENT_APONTADO) 
  if(restoApont<0){
    restoApont = 0
  }
  let apontado = parseInt(graficoDadosApontamento.PERCENT_APONTADO)
  if(!apontado){
    apontado = 0
    restoApont= 100
  }
  const grGrafico = [apontado,restoApont]

  let corVermelho = ["rgb(247, 71, 71)", "rgba(30,30,30,0.2)"]
  let corVerde =  ["rgb(13, 224, 112)", "rgba(30,30,30,0.2)"]

  const data = {
    labels: [],
    datasets: [
      {
        label: "Tf",
        data: grGrafico,
        backgroundColor:grGrafico[0]<90?corVermelho:corVerde,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '85%', // Ajuste este valor para alterar a espessura do gráfico
  };

  return <Doughnut data={data} options={options} />;
};

export default GraficoApontamento;
