import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MsgAviso from '../tela_Msg/msg';
import Ld from '../loading/BtnLogin/BtnLogin';
import './LoginApp.css'
import MsgInstalacao from '../tela_Msg/Msg_Instalacao'
const serverid = ''
//const serverid2 = 'https://api.connecct.net'
export default function LoginApp() {

const [btnspn, setbtnSpn] = useState(false);
var [msg, setmsg] = useState(false)
var [msgConteudo, setmsgConteudo] = useState(false)
  const naveLogin = useNavigate();


  var [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (e) => {
    let user = e.target.value;
    user = user.toUpperCase();
    user = user.replace(/\s/g, "")
    setUsername(user);
  };

  const handlePasswordChange = (e) => {
    let psw  = e.target.value
    psw = psw.replace(/\s/g, "")
    setPassword(psw);
  };

  

  const serverLogin = async (e) => {
    e.preventDefault();
    try {
     
      if(btnspn){
        return
      }
      setbtnSpn(true);
      const json = {
        username: username,
        password: password,
      };
      const dados = await axios.post(serverid+"/auth/login", json);
      const results = await dados.data;
      //document.cookie = `usuario=${username}; expires=Thu, 01 Jan 2050 00:00:00 UTC; path=/; SameSite=None; Secure`;
      console.log(results)
      document.cookie = `usuario=${username}; expires=Thu, 01 Jan 2050 00:00:00 UTC; path=/;`;
      document.cookie = `token=${results.token}; expires=Thu, 01 Jan 2050 00:00:00 UTC; path=/;`;

      localStorage.setItem('usuario',username);
      localStorage.setItem('SETOR',results.SETOR);
      setbtnSpn(false);
      naveLogin("/Home");
    } catch (error) {
      setmsgConteudo(error.response.data)
      setmsg(false)
      setbtnSpn(false)     
    }
  };


  useEffect(()=>{

    if (localStorage.getItem('usuario')){
        naveLogin("/Orisol-Castanhal");
    }

  },[naveLogin])

  const [showInstallMessage, setShowInstallMessage] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    // Verificar se o aplicativo está instalado
    const isAppInstalled =
      window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;

    // Adicionar um evento de instalação
    const handleBeforeInstallPrompt = (event) => {
      // Impedir o prompt padrão de instalação
      event.preventDefault();

      // Armazenar o evento deferredPrompt para usar mais tarde
      setDeferredPrompt(event);

      // Mostrar a mensagem de instalação quando o aplicativo não está instalado
      if (!isAppInstalled) {
        setShowInstallMessage(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Remover o ouvinte de eventos ao desmontar o componente
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []); // Executar o efeito apenas uma vez durante a montagem do componente

  const handleInstallClick = () => {
    // Verificar se há um prompt de instalação adiado
    if (deferredPrompt) {
      // Iniciar o prompt de instalação
      deferredPrompt.prompt();

      // Aguarde a escolha do usuário
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {

        } else {

        }

        // Resetar o prompt adiado
        setDeferredPrompt(null);

        // Ocultar a mensagem de instalação
        setShowInstallMessage(false);
      });
    }
  };

  return (
    <>
      {showInstallMessage&&<MsgInstalacao handleInstallClick={handleInstallClick} setShowInstallMessage={setShowInstallMessage}/>}
      {msgConteudo&&<MsgAviso msg={msg} setmsg={setmsg} msgConteudo={msgConteudo} setmsgConteudo={setmsgConteudo}/>}
      
      <div className="Container_Login">
        <div
        style={{
          width: '100%',
          height:'75px',
          position:'absolute',
          padding: '10px',
          boxSizing:'border-box',

          top:'35px',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
        <img className='imgLogoRtc' style={{  height:'100%' }} src="../.././192x192connecct.png" alt=""></img>
          <div> <h2 style={{marginLeft:'10px', color:'white'}}>Gestão Orisol Castanhal</h2></div>
         
        </div>
        <div className="Login_second">
          <div className="container_btnAdd">
            
          </div>
          <div className="container_iconUser">
            <div
              className={`Icon_User ${
                username === "2" || username === 2 ? "tst" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="100"
                viewBox="0 -960 960 960"
                width="100"
              >
                <path d="M400-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM80-160v-112q0-33 17-62t47-44q51-26 115-44t141-18h14q6 0 12 2-8 18-13.5 37.5T404-360h-4q-71 0-127.5 18T180-306q-9 5-14.5 14t-5.5 20v32h252q6 21 16 41.5t22 38.5H80Zm560 40-12-60q-12-5-22.5-10.5T584-204l-58 18-40-68 46-40q-2-14-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T628-460l12-60h80l12 60q12 5 22.5 11t21.5 15l58-20 40 70-46 40q2 12 2 25t-2 25l46 40-40 68-58-18q-11 8-21.5 13.5T732-180l-12 60h-80Zm40-120q33 0 56.5-23.5T760-320q0-33-23.5-56.5T680-400q-33 0-56.5 23.5T600-320q0 33 23.5 56.5T680-240ZM400-560q33 0 56.5-23.5T480-640q0-33-23.5-56.5T400-720q-33 0-56.5 23.5T320-640q0 33 23.5 56.5T400-560Zm0-80Zm12 400Z" />
              </svg>
            </div>
          </div>

          <form className="Container_Dados_User">
            <div
              className={`input-container ${username ? "input-filled" : ""}`}
            >
              <label className="input-label">Usuário</label>
              <input
                type="text"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div
              className={`input-container ${password ? "input-filled" : ""}`}
            >
              <label className="input-label">Senha</label>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>

            <div className="container_Button">
              <button
               className="btn btn_Login" 
               onClick={(e)=>serverLogin(e)}>
                {btnspn ? <Ld /> :<div>Entrar</div>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
