import './analisestyle.css';
//import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'; 
import axios from 'axios';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { DNA  } from 'react-loader-spinner';
axios.defaults.withCredentials = true;

const serverid = ''

const ComponenteLoader = ()=>{
  return(
    <>
    <div style={{width:'100%',position:'relative', height:'100%', display:'flex', justifyContent:'center',alignItems:'center'}}>
    <DNA 
        type="Puff"
        color="rgba(53,120,250)"
        height={50}
        width={50}
      />
</div>
    </>
  )
}
const ComponenteAnaliseMaquinas = ({ setmenu })=>{

   
    let [clickFiltro, setClickFiltro]=useState({
      ativado:false,
      maq:'TODAS',
      limparFiltro:false
    });
    let [arrayTurno, setArrayTurno]=useState([]);
    let [periodoIncio, setPeriodoIncio]=useState(false);
    let [periodoFim, setPeriodoFim]=useState(false);
    let [maquina, setMaquina]=useState('TODAS');
    const [openFiltro, setFiltro] = useState(false)
    const [closedFiltro, setclosedfiltro] = useState(false)

    let [dadosjson, setDadosJson]=useState(false)

    let [hrTrabalhado,setHrTrabalhado]=useState('00:00');
    let [hrprevisto,setHrPrevisto]=useState('00:00');
    let [hrttParada,setHrhrttParada]=useState('00:00'); 
    let [percentParada, setPercentParada]=useState(['100%','0%']);
    let [cor, setCor]=useState(100);
    useEffect(()=>{
      setmenu(true)

    },[setmenu])
    useEffect(()=>{
      
        if(dadosjson){
            setHrTrabalhado(dadosjson['dados01']['horasTrabalhado'])
            setHrPrevisto(dadosjson['dados01']['horasPrevista'])
            setHrhrttParada(dadosjson['dados01']['Horas_Parada']);
            const percent = parseFloat(dadosjson['dados01']['Percente_Parada'])
            let maximo = 100-percent
            setCor(maximo)
            maximo = maximo.toFixed(1)
            maximo = maximo+'%'
            let minimo = percent
            minimo = percent+'%'
            
            setPercentParada([maximo,minimo])
        }
    },[dadosjson]);
    const handleLimpaFiltro = () => {
      
      setClickFiltro({
        ativado:false,
        maq:'TODAS',
        limparFiltro:true
      })
    }


      const handleOpenMenu = () => {
            setFiltro(true)
      }
      const handleCloseMenu = () => {
        setclosedfiltro(true)
  }
    return(
    <>
   {openFiltro && <ComponenteFiltro
    openFiltro={openFiltro} 
    setFiltro={setFiltro} 
    closedFiltro={closedFiltro} 
    setclosedfiltro={setclosedfiltro} 
    setDadosJson={setDadosJson} 
    clickFiltro={clickFiltro} 
    setClickFiltro={setClickFiltro} 
    arrayTurno={arrayTurno} 
    setArrayTurno={setArrayTurno}
    periodoIncio={periodoIncio}
    setPeriodoIncio={setPeriodoIncio}
    periodoFim={periodoFim} 
    setPeriodoFim={setPeriodoFim}
    maquina={maquina} 
    setMaquina={setMaquina}
    
    />}
        <div className='containerAnalise'>

            <div style={{transition:'all 1s linear'}} className={`analise_CTN2 ${cor>=95?'verdeMeta':'vermelhoMeta'}`}>
            <div className='analise_CTN1'>
                <div style={{
                    fontSize:'clamp(18px,1.8vw,21px)',
                    fontWeight:'600',
                    width:'50%',
                    marginLeft:'10px',
                }}>Analytics
                
                </div>
                <div style={{
                    width:'35px', height:'35px', backgroundColor:'rgba(0,0,0,0.5)',
                    display:'flex', position:'absolute',right:'10px', borderRadius:'5px',
                    boxShadow:'0 2px 5px rgba(0,0,0,0.2)', justifyContent:'center',alignItems:'center',
                }}>
                   { !openFiltro &&  <svg onClick={()=>handleOpenMenu()} style={{fill:'white'}} xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="30"><path d="M120-240v-80h240v80H120Zm0-200v-80h480v80H120Zm0-200v-80h720v80H120Z"/></svg>}
                    { openFiltro && <svg style={{fill:'white'}} onClick={()=>handleCloseMenu()} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>}
                </div>
            </div>
                <div style={{
                    
                    fontSize:'clamp(15px,1.8vw,17px)',
                    fontWeight:'600',
                    marginTop:'10px',
                    width:'100%',
                    boxSizing:'border-box',
                    display:'flex',
                    justifyContent: 'center',
                }}>Horas trabalhado</div>

                <div style={{
                    fontSize:'clamp(30px,1.8vw,30px)',
                    fontWeight:'800',

                    width:'100%',
                    boxSizing:'border-box',
                    display:'flex',
                    justifyContent: 'center',
                    marginTop:'10px',
                    marginBottom:'7px'
                }}>{hrTrabalhado}<span style={{fontSize:'16px', marginLeft:'5px'}}>hr</span></div>

                <div style={{
                    fontSize:'clamp(15px,1.8vw,17px)',
                    fontWeight:'500',
                    marginTop:'-5px',
                    width:'100%',
                    boxSizing:'border-box',                    
                    display:'flex',
                    marginBottom:'45px',
                    justifyContent: 'center',
                }}>Disponível</div>
            </div>

            <div className='analise_CTN3'>
                <div style={{
                    fontSize:'clamp(13px,1.8vw,17px)',
                    fontWeight:'400',
                    width:'50%',
                    display:'block',
                    height:'100px',
                    boxSizing:'border-box',
                    margin:'5px',
                    marginTop:'-3px',
                    backgroundColor:'white',
                    boxShadow:'0 3px 10px rgba(0,0,0,0.3) ',
                    padding:'7px',
                    maxWidth:'300px',
                    position: 'relative',
                    paddingTop:'12px',
                    borderRadius:'5px',
                    color:'rgba(0,0,0,0.7)'

                }}>
                    <div className='ctn01'>
                        <div style={{fontWeight:'800'}}>Horas Previsto</div>
                    </div>
                    <div style={{position:'absolute', right:'10px', top:'10px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
                        </div>
                    <div style={{fontSize:'22px', marginTop:'8px', fontWeight:'600'}}>{hrprevisto}</div>
                </div>
                
                
                <div style={{
                    fontSize:'clamp(13px,1.8vw,17px)',
                    fontWeight:'400',
                    width:'50%',
                    display:'block',
                    height:'100px',
                    boxSizing:'border-box',
                    margin:'5px',
                    marginTop:'-3px',
                    backgroundColor:'white',
                    boxShadow:'0 3px 10px rgba(0,0,0,0.3) ',
                    padding:'7px',
                    maxWidth:'300px',
                    position: 'relative',
                    paddingTop:'12px',
                    borderRadius:'5px',
                    color:'rgba(0,0,0,0.7)'

                }}>
                    <div className='ctn01'>
                        <div style={{fontWeight:'800'}}>Total Parada</div>
                    </div>
                    <div style={{position:'absolute', right:'10px', top:'10px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
                        </div>
                    <div style={{fontSize:'22px', marginTop:'8px', fontWeight:'600'}}>{hrttParada}</div>
                </div>
            </div>

            <div className='analise_CTN4'>
                <div style={{marginBottom:"10px", fontWeight:'600'}}>Disponibilidade</div>
                <div style={{
                    width:'100%',
                    height:'20px',
                    borderRadius:'20px',
                    display:'flex',
                    overflow:'hidden',
                    boxSizing:'border-box,',
                    boxShadow:'0 0px 10px rgba(0, 0, 0, 0.3)',
                }}>
                    <div style={{
                        width:percentParada[0],
                        height:'100%', 
                        boxSizing:'border-box',
                        background: 'linear-gradient(to right, #00ff26d5, #00ff95ad)',
                        transition:'all 1.1s linear'
                        }}>
                    </div>
                    <div style={{
                        transition:'all 1.1s linear',
                        width:percentParada[1],
                        height:'100%', 
                        boxSizing:'border-box',
                        background: 'linear-gradient(to right, #ff8400cc, #ff0400b1)'
                        }}>
                    </div>
                </div>
                <div style={{
                    width:'100%',
                    padding:'3px',
                    boxSizing:'border-box',
                    display: 'flex',

                    alignItems: 'center',
                }}>
                    <div style={{
                    alignItems:'flex-start',
                    width:'50%',
                    fontWeight:'800',
                    color:'rgb(75, 220, 42)'
                    }}>{percentParada[0]}</div>

                    <div style={{
                    textAlign:'right',
                    borderLeft:'1px solid rgba(0,0,0,0.4)',
                    width:'50%',
                    fontWeight:'800',
                    color:'rgba(255, 4, 0, 0.753)'
                    }}>{percentParada[1]}</div>

                </div>
            
            </div>

            <div className='analise_CTN5'>
            <div className='grafico_Ctn01'>
                <div style={{marginBottom:"10px", fontWeight:'600'}}>Falhas com maior tempo de parada</div>
                {dadosjson && <ComponenteGraficoRecorrente dadosjson={dadosjson}/>}
            </div>
            <div className='grafico_Ctn01'>
                <div style={{marginBottom:"10px", fontWeight:'600'}}>Causas com maior tempo de parada</div>
                {dadosjson && <ComponenteGraficoCausas dadosjson={dadosjson}/>}
            </div>

            <div style={{position:'relative'}} className='grafico_Ctn01'>
                <div style={{marginBottom:"10px", fontWeight:'600'}}>Tempo parada por maquina
                </div>
                <div onClick={()=>{handleLimpaFiltro()}}  style={{cursor:'pointer',position:'absolute', top:'5px',right:'5px'}}>
                <svg style={{display:clickFiltro.ativado?'flex':'none'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z"/></svg>
                </div>
                {<ComponenteGraficoMaquinas clickFiltro={clickFiltro} dadosjson={dadosjson} setClickFiltro={setClickFiltro} maquina={maquina} setMaquina={setMaquina}/>}
            </div>

            <div className='grafico_Ctn01'>
                <div style={{marginBottom:"10px", fontWeight:'600'}}>Falhas recorrente</div>
                {dadosjson && <ComponenteGraficoCausasRecorrente dadosjson={dadosjson}/>}
            </div>
            {/* <div className='grafico_Ctn01'>
                <div style={{marginBottom:"10px", fontWeight:'600'}}>Grafico Teste Canvas!</div>
                
            </div> */}
               
            </div>
            
        </div>
    </>
    )
}



const ComponenteFiltro = ({ 
  openFiltro, 
  setFiltro,
  closedFiltro, 
  setclosedfiltro,
  setDadosJson,
  setClickFiltro,
  clickFiltro,
  arrayTurno, 
  setArrayTurno,
  periodoIncio, setPeriodoIncio,periodoFim, setPeriodoFim,maquina, setMaquina
})=>{
  

    if(closedFiltro){
        
        setTimeout(() => {
            setclosedfiltro(false)
            setFiltro(false)
        }, 400);
    }

    useEffect(() => {
      const handlePesquisar = async ()=>{
        const json = {
            Turno:arrayTurno,
            inicio:periodoIncio,
            Fim:periodoFim,
            Maq:maquina
        }
        const result = await axios.post(serverid+'/analise/consulta',json);
        const dados = await result.data

        setDadosJson(dados)
    
        
        return
    
    }
    
    if (clickFiltro.ativado){
      handlePesquisar()
      }
      if (!clickFiltro.ativado && clickFiltro.limparFiltro){
        setMaquina('TODAS')
       handlePesquisar()
        }
      
    },[arrayTurno, clickFiltro, maquina, periodoFim, periodoIncio, setClickFiltro, setDadosJson, setMaquina])

    const handlSlectMaquina = (e)=>{
        const maquina = e.target.value
        setMaquina(maquina)
    }

    const handlSlectPeriodo = (e)=>{
        const inicio = e.target.value
        setPeriodoIncio(inicio)
    }
    const handlSlectPeriodoFim = (e)=>{
        const fim = e.target.value
        setPeriodoFim(fim)
    }
    
    const handleClickTurno = (e) => {
        const turnoSlect = e.target.id;
        const vnArray = [...arrayTurno];
        const turnoIndex = vnArray.indexOf(turnoSlect);
        if (turnoIndex !== -1) {
          vnArray.splice(turnoIndex, 1);
        } else {
          vnArray.push(turnoSlect);
        }
        setArrayTurno(vnArray);
      };
let [load, setLoad] = useState(false)     
const handlePesquisar = async ()=>{
  try {
    setLoad(true)
    const json = {
        Turno:arrayTurno,
        inicio:periodoIncio,
        Fim:periodoFim,
        Maq:maquina
    }
    Object.keys(json).forEach(key => {
      if(json[key]==='' || !json[key]){
        throw new Error("Dados nao preenchido!")
      }
    })
    if(clickFiltro.ativado){
      const nvJson = {...clickFiltro}
      nvJson['ativado']=false
      nvJson['maq']='TODAS'
      setClickFiltro(nvJson)
      json['Maq']='Todas'
    }
    const result = await axios.post(serverid+'/analise/consulta',json);

    const dados = await result.data

    setDadosJson(dados)

    setTimeout(() => {
      setLoad(false)
      
    }, 5000);
    return
  } catch (error) {
    alert(error.message)
    setLoad(false)
  }
  

}
    return(
        <>
         <div className={`filtroMenu ${!closedFiltro?'filtroMenu_Open':'filtroMenu_Close'}`}>
            <div style={{display:'flex'
            ,flexWrap:'wrap', alignItems:'stretch',width:'100%', padding:'10px', boxSizing:'border-box'}}>
                <div style={{
                    width:'35%',
                    maxWidth:'130px',
                    boxSizing:'border-box',
                    border:'1px solid rgba(0,0,0,0.5)',
                    borderRadius:'5px',
                    display:'block',
                    fontSize:'13px', margin:'3px', position:'relative',
                }}><div style={{margin:'7px'}}>Turno</div>
                
                <div style={{
                    width:'100%',
                    boxSizing:'border-box',
                    fontSize:'15px',
                    display:'flex',
                    justifyContent:'center'
                }}>
                <div  onClick={(e)=>handleClickTurno(e)} id='TURNO 1' className={` turnoanalitics ${arrayTurno.includes('TURNO 1')&&'selectTurno'}`} 
                >01</div>
                <div  onClick={(e)=>handleClickTurno(e)} id='TURNO 2' className={` turnoanalitics ${arrayTurno.includes('TURNO 2')&&'selectTurno'}`} 
                >02</div>
                <div  onClick={(e)=>handleClickTurno(e)} id='TURNO 3' className={` turnoanalitics ${arrayTurno.includes('TURNO 3')&&'selectTurno'}`} 
                >03</div>
                </div>
                </div>

                <div style={{
                    width:'60%',
                    maxWidth:'250px',
                    boxSizing:'border-box',
                    border:'1px solid rgba(0,0,0,0.5)',
                    borderRadius:'5px',
                    display:'block',
                    overflow:'hidden',
                    fontSize:'13px',margin:'3px', position:'relative'
                }}><div style={{margin:'7px'}}>Periodo</div>
                <svg style={{position:'absolute', right:'6', top:'2', fill:'rgba(0,0,0,0.6)'}} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="M480-400q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z"/></svg>
                <div style={{
                    width:'100%',
                    boxSizing:'border-box',
                    fontSize:'15px',
                    display:'flex',
                    justifyContent:'center',position:'relative'
                }}>
                    <label style={{opacity:periodoIncio?'0':'1',position:'absolute', left:'3px'
                    , color:'rgba(0,0,0,0.5)', top:'4px',pointerEvents:'none'
                }}>Inicio</label>
                    <input
                    value={periodoIncio}
                    onChange={(e)=>handlSlectPeriodo(e)}
                    type='date' className='inputDate'></input>
                    <label style={{opacity:periodoFim?'0':'1',position:'absolute', right:'20px'
                    , color:'rgba(0,0,0,0.5)', top:'4px', pointerEvents:'none'
                }}>Fim</label>
                    <input
                    value={periodoFim}
                    onChange={(e)=>handlSlectPeriodoFim(e)}
                    type='date' className='inputDate'></input>
                </div>

                </div>

                <div style={{
                    overflow:'hidden',
                    width:'60%',
                    maxWidth:'250px',
                    boxSizing:'border-box',
                    border:'1px solid rgba(0,0,0,0.5)',
                    borderRadius:'5px',
                    display:'block',
                    fontSize:'13px',margin:'3px', position:'relative'
                }}><div style={{margin:'7px'}}>Maquinas</div>
                <svg style={{position:'absolute', right:'6', top:'2', fill:'rgba(0,0,0,0.6)'}} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="M480-400q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z"/></svg>
                <div style={{
                    width:'100%',
                    boxSizing:'border-box',
                    fontSize:'15px',
                    display:'flex',
                    position:'relative'
                }}>
                    <label style={{position:'absolute', left:'3px'
                    , color:'rgba(0,0,0,0.5)', top:'4px',pointerEvents:'none'
                }}></label>
                    <select
                    onChange={handlSlectMaquina}
                    style={{}} className='inputDate'>
                    <option value={clickFiltro.ativado?clickFiltro.maq:"Todas"}>{clickFiltro.ativado?clickFiltro.maq:'TODAS'}</option>
      {[...Array(25).keys()].map((_, index) => (
        <option key={`MAQ-${index + 1}`} value={`MAQ-${(index + 1).toString().padStart(2, '0')}`}>
          {`MAQ-${(index + 1).toString().padStart(2, '0')}`}
        </option>
      ))}
      
    </select>
                   
                </div>
                
                </div>
                <div style={{display:"flex", justifyContent:"center",
              alignItems:'center', padding:'0 10px',minWidth: '100px',
              width: '30%'}}>

                {load?<ComponenteLoader/>:<button className='buttonPesquisar'
                onClick={(e)=>{handlePesquisar(e)}}
                >Pesquisar</button>}

                </div>
                
            </div>
         </div>
        </>
    )
}



const ComponenteGraficoRecorrente = ({ dadosjson }) => {
  return (
    <>
      <div style={{
        width: '100%',
        boxSizing: 'border-box',
        display: 'block',
        maxHeight: '200px',
        overflow: 'auto',
        transition: 'all 0.5s linear'
      }}>
        {dadosjson && Object.keys(dadosjson['dados02']).map((obj, index) => (
          <div key={index} style={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'block',
            marginBottom: '10px',
          }}>
            <div style={{
              fontSize: 'clamp(10px, 1.8vw, 13px)',
              display: 'flex', position: 'relative'
            }}>{obj}
              <div style={{ fontWeight: '600', position: 'absolute', right: '10px' }}>{dadosjson['dados02'][obj]['tempoParada']}</div>
            </div>
            <div style={{
              boxSizing: 'border-box',
              margin: '3px 0',
              width: '100%',
              height: '11px',
              borderRadius: '30px',
              backgroundColor: 'rgba(0,0,0,0.05)',
              overflow: 'hidden'
            }}>
              <BarraAnimada percent={dadosjson['dados02'][obj]['percent']} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

const BarraAnimada = ({ percent }) => {
  const props = useSpring({
    width: `${percent}%`,
    from: { width: '0%' },
    config: { duration: 800 }
  });

  return <animated.div style={{
    boxSizing: 'border-box',
    height: '11px',
    borderRadius: '30px',
    background: 'linear-gradient(to right, rgb(0,120,250),rgb(0,120,250,0.8))',
    overflow: 'hidden',
    ...props
  }} />;
}


const ComponenteGraficoCausas = ({ dadosjson })=>{
    return(
        <>
           <div style={{
        width: '100%',
        boxSizing: 'border-box',
        display: 'block',
        maxHeight: '200px',
        overflow: 'auto',
        transition: 'all 0.5s linear'
      }}>
        {dadosjson && Object.keys(dadosjson['dados03']).map((obj, index) => (
          <div key={index} style={{
            width: '100%',
            boxSizing: 'border-box',
            display: 'block',
            marginBottom: '10px',
          }}>
            <div style={{
              fontSize: 'clamp(10px, 1.8vw, 13px)',
              display: 'flex', position: 'relative'
            }}>{obj}
              <div style={{ fontWeight: '600', position: 'absolute', right: '10px' }}>{dadosjson['dados03'][obj]['tempoParada']}</div>
            </div>
            <div style={{
              boxSizing: 'border-box',
              margin: '3px 0',
              width: '100%',
              height: '11px',
              borderRadius: '30px',
              backgroundColor: 'rgba(0,0,0,0.05)',
              overflow: 'hidden'
            }}>
              <BarraAnimada percent={dadosjson['dados03'][obj]['percent']} />
            </div>
          </div>
        ))}
      </div>
        </>
    )
}

const ComponenteGraficoCausasRecorrente = ({ dadosjson })=>{
  return(
      <>
         <div style={{
      width: '100%',
      boxSizing: 'border-box',
      display: 'block',
      maxHeight: '200px',
      overflow: 'auto',
      transition: 'all 0.5s linear',
    }}>
      {dadosjson && Object.keys(dadosjson['dados05']).map((obj, index) => (
        <div className='animationHoverGr' key={index} style={{
          width: '100%',
          boxSizing: 'border-box',
          display: 'block',
          marginBottom: '5px',
          borderBottom: '2px solid rgba(0,0,0,0.3)',borderRadius:'4px', 
        }}>
          <div style={{
            fontSize: 'clamp(11px, 1.8vw, 13px)',
            display: 'flex', position: 'relative'
          }}> <div style={{width:'40%',}}>{obj}</div>
            <div style={{width:'60%', display:'flex', fontWeight: '400', position: 'absolute', right: '10px' }}>Este problema aconteceu <div style={{margin:'0px 4px',fontSize: 'clamp(13px, 1.8vw, 15px)', color:'red', fontWeight:'500'}}>{dadosjson['dados05'][obj]['total_parada']} </div> {dadosjson['dados05'][obj]['total_parada']>1?'vezes':'vez'} </div>
          </div>
          <div style={{
            boxSizing: 'border-box',
            margin: '3px 0',
            width: '100%',
            height: '11px',
            borderRadius: '30px',
            backgroundColor: 'rgba(0,0,0,0.05)',
            overflow: 'hidden'
          }}>
            <BarraAnimada percent={dadosjson['dados05'][obj]['percent']} />
          </div>
        </div>
      ))}
    </div>
      </>
  )
}


const ComponenteGraficoMaquinas = ({ dadosjson, setClickFiltro,clickFiltro, setMaquina }) => {
  return (
    <>
      <div style={{
        width: '100%',
        boxSizing: 'border-box',
        display: 'block',
        maxHeight: '210px',
        overflowX: 'auto',
        overflowY: 'hidden',
        transition: 'all 0.5s linear'
      }}>

        <div style={{
          width: '100%',
          height: '200px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
        }}>
          {dadosjson && Object.keys(dadosjson['dados04']).map((obj, index) => (
            <BarraAnimada2 key={index} clickFiltro={clickFiltro} tempo={dadosjson['dados04'][obj]['tempoParada']} nomeMaquina={obj} percent={dadosjson['dados04'][obj]['percent']}  setClickFiltro={setClickFiltro} setMaquina={setMaquina}  />
          ))}
        </div>
      </div>
    </>
  );
}

const BarraAnimada2 = ({ tempo, nomeMaquina, percent, setClickFiltro, clickFiltro, setMaquina }) => {
    const props = useSpring({
      from: { height: '0%' },
      to: { height: `${percent}%` },
      config: { duration: 900 }
    });
  var handleClickMaq = (e) => {
    const numMaq = e.currentTarget.id
    setMaquina(numMaq)
    const nvJson = {...clickFiltro}
      nvJson['ativado']=true
      nvJson['maq']=numMaq
      setClickFiltro(nvJson)
  }
    return (
      <div style={{
        width: '15%',minWidth: '15%',
        height: '90%',
        paddingTop: '18px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',alignItems:'center',

      }}>
        <div className='clickBarra_Animation' id={nomeMaquina} onClick={(e)=>{handleClickMaq(e)}} style={{
          width: '75%',
          height: '100%',
          boxSizing: 'border-box',
          borderTopLeftRadius: '105px',
          borderTopRightRadius: '105px',
          position: 'relative',
          
        }}>
          <animated.div style={{
            bottom: '0',
            position: 'absolute',
            width: '100%',
            height: '45%',
            margin: '0 auto',
            background: 'linear-gradient(to bottom,rgba(255,20,0,0.7),rgba(255, 132, 0, 0.8),rgba(220,50,0,0.000))',
            boxSizing: 'border-box',
            borderTopLeftRadius: '105px',
            borderTopRightRadius: '105px',
            
            ...props
          }} className='clickBarra_Animation'>
            <div style={{
              color: 'rgba(0,0,0,0.8)',
              fontSize: 'clamp(12px,1.8vw,14px)',
              fontWeight: 'bold',
              marginTop: '-18px',
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {tempo}
            </div>
          </animated.div>
        </div>
        <div style={{
          width: '100%',
          margin: '0 auto',
          marginTop: '5px',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '800',
          color: 'rgba(0,0,0,0.7)',
          fontSize: 'clamp(11px,1.8vw,13px)',
        }}>{nomeMaquina}</div>
      </div>
    );
  }
  



















/* function backup(){

            <div
        style={{
            width: '100%',
            height: '100%',
         
            backgroundColor:'rgba(20,80,220)',
            boxSizing: 'border-box',
            margin:'0 auto',
            padding: '20px 20px',
            display:'block',
            maxWidth:'800px',
            marginTop:'30px',
            color:'white'
        }}
        >
        <h3>Olá { localStorage.getItem('usuario')} 🛠️🚀</h3>
        <p style={{fontSize:"14px",fontWeight:'300',lineHeight:'1.9'}}>🚧 <span style={{ fontWeight:'450'}}>**Nova Funcionalidade em Breve!**</span><br></br><br></br>

        Estamos trabalhando nesta funcionalidade. Neste espaço, você terá acesso a análises passadas, soluções para problemas recorrentes, mapas detalhados de cada máquina Orisol, principais causas de ocorrências, disponibilidade geral e por equipamento, e muito mais.

Agradecemos pela paciência enquanto continuamos a aprimorar a experiência. Fique ligado para mais atualizações e novidades em breve! 🛠️🚀
        </p>
        <div style={{
            width: '100%',
            marginTop: '25px',
            boxSizing: 'border-box',
            display:'flex',
            justifyContent: 'flex-end',
            alignItems:'center'
        }}>
        <button
        style={{
            width:'160px',
            height:'35px',
            color:'#212121',
            backgroundColor:'white',
            border:'none',
            cursor: 'pointer',
        }}
        onClick={() =>{handlNave_Home()}}
        >Voltar para as orisol</button>
        </div>
        </div>

} */
export default ComponenteAnaliseMaquinas