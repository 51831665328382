import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Menus.css";
import { useLocation } from 'react-router-dom';

export default function Menu() {
  const NaveOrisol = useNavigate();
  const location = useLocation();
  const [clickColor, setClick] = useState(true);
  const [clickColor2, setClick2] = useState(false);
  const [clickColor3, setClick3] = useState(false);
  const [clickColor4, setClick4] = useState(false);
  useEffect(()=>{
    if (location.pathname === "/Orisol-Castanhal"){
      setClick(true);
      setClick2(false);
      setClick3(false);
      setClick4(false);
    }else if (location.pathname === "/Apontamento"){
      setClick(false);
      setClick2(true);
      setClick3(false);
      setClick4(false);
    }else if (location.pathname === "/AnaliseOrisol"){
          setClick(false);
          setClick2(false);
          setClick3(true);
          setClick4(false);
    }else if (location.pathname === "/Preventivas"){
          setClick(false);
          setClick2(false);
          setClick3(false);
          setClick4(false);
          setClick4(true);
    }

  },[location.pathname])

 
  const handleClick = (params) => {
    const { e } = params;
    if (e === 1) {
      setClick(true);
      setClick2(false);
      setClick3(false);
      setClick4(false);
      NaveOrisol("/Orisol-Castanhal");
    } else if (e === 2) {
      setClick(false);
      setClick2(true);
      setClick3(false);
      setClick4(false);
      NaveOrisol("/Apontamento");
    } else if (e === 3) {
      setClick(false);
      setClick2(false);
      setClick4(false);
      setClick3(true);
      NaveOrisol("/AnaliseOrisol");
    }else if (e === 4) {
      setClick(false);
      setClick2(false);
      setClick3(false);
      setClick4(true);
      NaveOrisol("/Preventivas");
    }
  };
  return (
    <>
      <div className="Container_MenuPhone">
        <div
          className="container_icon_Menu"
          onClick={() => handleClick({ e: 1 })}
        >
          <div className={`select_Menu ${clickColor && "select_Menu_color"}`}>
          <svg style={{display:`${!clickColor?'flex':'none'}`}} className="noanimation" xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="M240-200h147.692v-235.385h184.616V-200H720v-360L480-741.538 240-560v360Zm-40 40v-420l280-211.539L760-580v420H532.308v-235.384H427.692V-160H200Zm280-310.769Z"/></svg>
          <svg style={{display:`${clickColor?'flex':'none'}`}} className="noanimation" xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="M200-160v-420l280-211.539L760-580v420H552.308v-255.385H407.692V-160H200Z"/></svg> 
          </div>
          <div className="ref_Menu">Início</div>
        </div>
{/* container das preventivas */}
        <div
          className="container_icon_Menu"
          onClick={() => handleClick({ e: 4 })}
        >
          <div className={`select_Menu ${clickColor4 && "select_Menu_color"}`}>
          <svg className="noanimation"  style={{display:`${!clickColor4?'flex':'none'}`}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z"/></svg>
          <svg className="noanimation" style={{display:`${clickColor4?'flex':'none'}`}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Z"/></svg>
         
          </div>
          <div className="ref_Menu">Preventiva</div>
        </div>

        <div
          className="container_icon_Menu"
          onClick={() => handleClick({ e: 2 })}
        >
          <div className={`select_Menu ${clickColor2 && "select_Menu_color"}`}>
          <svg style={{display:`${!clickColor2?'flex':'none'}`}} xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="m438-285.384 198.308-198.308-28.77-28.77-169.769 169.77-85.538-85.539-28.539 28.539L438-285.384ZM264.615-120Q237-120 218.5-138.5 200-157 200-184.615v-590.77Q200-803 218.5-821.5 237-840 264.615-840H580l180 180v475.385Q760-157 741.5-138.5 723-120 695.385-120h-430.77ZM560-640v-160H264.615q-9.23 0-16.923 7.692Q240-784.615 240-775.385v590.77q0 9.23 7.692 16.923Q255.385-160 264.615-160h430.77q9.23 0 16.923-7.692Q720-175.385 720-184.615V-640H560ZM240-800v160-160 640-640Z"/></svg>
          <svg style={{display:`${clickColor2?'flex':'none'}`}} xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="m438-285.384 198.308-198.308-28.77-28.77-169.769 169.77-85.538-85.539-28.539 28.539L438-285.384ZM264.615-120Q237-120 218.5-138.5 200-157 200-184.615v-590.77Q200-803 218.5-821.5 237-840 264.615-840H580l180 180v475.385Q760-157 741.5-138.5 723-120 695.385-120h-430.77ZM560-640h160L560-800v160Z"/></svg>
          </div>
          <div className="ref_Menu">Apontamento</div>
        </div>
       
        <div
          className="container_icon_Menu"
          onClick={() => handleClick({ e: 3 })}
        >
          <div className={`select_Menu ${clickColor3 && "select_Menu_color"}`}>
          <svg style={{display:`${!clickColor3?'flex':'none'}`}}  xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="M480-120q-74.7 0-140.4-28.339-65.7-28.34-114.3-76.922-48.6-48.582-76.95-114.257Q120-405.194 120-479.866q0-74.673 28.339-140.41 28.34-65.737 76.922-114.365 48.582-48.627 114.257-76.993Q405.194-840 479.866-840q74.673 0 140.41 28.35t114.365 76.95q48.627 48.6 76.993 114.3Q840-554.7 840-480v295.385q0 26.654-18.981 45.634Q802.039-120 775.385-120H480Zm0-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 28.385 4.538 55.769 4.539 27.385 14.385 53l141.846-141.846 132.769 111L631.461-580H540v-40h160v160h-40v-90.692L455.231-346.923l-131-112L197-331.692Q236.692-255 312.002-207.5 387.311-160 480-160Zm290.769 1.539q12.385 0 21.577-9.193 9.193-9.192 9.193-21.577 0-12.384-9.193-21.577Q783.154-220 770.769-220q-12.384 0-21.577 9.192Q740-201.615 740-189.231q0 12.385 9.192 21.577 9.193 9.193 21.577 9.193ZM474.231-480Z"/></svg>
          <svg style={{display:`${clickColor3?'flex':'none'}`}}  xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="M770.769-158.461q12.385 0 21.577-9.193 9.193-9.192 9.193-21.577 0-12.384-9.193-21.577Q783.154-220 770.769-220q-12.384 0-21.577 9.192Q740-201.615 740-189.231q0 12.385 9.192 21.577 9.193 9.193 21.577 9.193ZM480-120q-97.058 0-180.452-47.846-83.394-47.846-132.087-134.308l156.77-156.769 131 112L660-550.692V-460h40v-160H540v40h91.461L453.538-402.077l-132.769-111-172.538 172.539q-14-29.923-21.116-68.448Q120-447.51 120-480q0-74.7 28.339-140.4 28.34-65.7 76.922-114.3 48.582-48.6 114.257-76.95Q405.194-840 479.866-840q74.673 0 140.41 28.35t114.365 76.95q48.627 48.6 76.993 114.3Q840-554.7 840-480v295.385q0 26.654-18.981 45.634Q802.039-120 775.385-120H480Z"/></svg>
          </div>
          <div className="ref_Menu">Análise</div>
        </div>
      </div>
    </>
  );
}

