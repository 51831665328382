
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, Title, BarElement } from "chart.js";
Chart.register(CategoryScale, LinearScale, Title, BarElement);

const GraficoApontamento = ({ graficoTodoApont }) => {
// Valores fictícios para as barras horizontais
  const user = graficoTodoApont.MECANICOS

  const valoresFicticios = graficoTodoApont.APONTADOS
  const corVermelho = "rgb(247, 71, 71)";
  const corVerde = "rgb(13, 224, 112)";

  const data = {
    labels: user, // Rótulos fictícios
    datasets: [
      {
        label: '', // Rótulo do conjunto de dados (pode ser uma string vazia)
        data: valoresFicticios,
        backgroundColor: valoresFicticios.map((value) =>
          value < 95 ? corVermelho : corVerde
        ),
        borderWidth: 0, // Largura da borda das barras
      },
    ],
  };

  // Configuração das opções do gráfico
  const options = {
    indexAxis: "y", // Define a orientação para horizontal
    maintainAspectRatio: false, // Permitir que o gráfico ajuste seu tamanho automaticamente
    responsive: true, // Tornar o gráfico responsivo
    scales: {
      x: {
        beginAtZero: true, // Começar o eixo x no zero
        grid: {
          display: false, // Remover as linhas de grade no eixo x
        },
        ticks: {
          font: {
            size: 10, // Tamanho da fonte para os ticks do eixo x
          },
        },
      },
      y: {
        beginAtZero: true, // Começar o eixo y no zero
        grid: {
          display: false, // Remover as linhas de grade no eixo y
        },
        ticks: {
          font: {
            size: 9, // Tamanho da fonte para os ticks do eixo y
          },
          precision: 0, // Definir a precisão dos ticks para 0 para evitar valores fracionados
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 50, // Ajuste este valor para a curvatura desejada das barras
      },
    },
    plugins: {
      legend: {
        display: true, // Remover a legenda geral
        labels: {
          display: true, // Remover a legenda específica para este conjunto de dados
        },
      },
    },
  };

  return <Bar data={data} options={options}/>;
};


export default GraficoApontamento;



