import React from "react";
import { Doughnut } from "react-chartjs-2";

const GraficoTipoTarefa = ({graficoDadosApontamento}) => {
  let corretiva =parseInt(`${graficoDadosApontamento.CORRETIVA?graficoDadosApontamento.CORRETIVA:0}`)
  let preventiva = parseInt(`${graficoDadosApontamento.PREVENTIVA?graficoDadosApontamento.PREVENTIVA:0}`)
  let tarefa =  parseInt(`${graficoDadosApontamento.TAREFA?graficoDadosApontamento.TAREFA:0}`)
  let nulo = 0
  if(corretiva === 0 && preventiva === 0 && tarefa===0) {
    nulo = 1
  }
    const grGrafico = [preventiva,corretiva,tarefa,nulo]

  const data = {
    labels: [],
    datasets: [
      {
        label: "Tf",
        data: grGrafico,
        backgroundColor:['rgb(0, 120, 255)','rgb(247, 71, 71)','rgb(13, 224, 112)',"rgba(30,30,30,0.2)"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '85%', // Ajuste este valor para alterar a espessura do gráfico
  };

  return <Doughnut data={data} options={options} />;
};

export default GraficoTipoTarefa;