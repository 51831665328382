
import { useEffect, useState } from "react";
import "./Orisol.css";
import AbrirOdem from "../../Popups/AbrirOrdem/Popup_NovaOrdem";
import IniciarManutencao from "../../Popups/FinalizarManutencao/Popups_IniciarManutencao";
import MaquinaParadaOrs from './componentes/Manutencoes_inicadas'
import io from 'socket.io-client';
import MenuGeral from '../../menuGeral/MenuGeral';
import ChartTipoManutencao from "./chartJS/chartTipo_Manutencao";
import ChartComponent from "./chartJS/chartJs";
import ConfigTurno from '../../config/config_Turno';
/* import AnaliseDia from "./componentes/Analise_do_dia" */
const serverid = ''


async function limparCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var igualPos = cookie.indexOf("=");
    var nome = igualPos > -1 ? cookie.substr(0, igualPos) : cookie;
    document.cookie = nome + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

const handleSairApp = async (e) => {
  document.cookie = "usuario=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  await limparCookies()
  localStorage.clear();
  window.location.href = "/";
}


const ComponenteLoading = ()=>{
  return(
    <>
      <div className="loaderGeral">
        <div className="loadingSpiner">

        </div>
      </div>
    </>
  )
}

// Definição do componente Orisol_Castanhal
export default function Orisol_Castanhal({ setmenu }) {
  const [load, setLoad] = useState(true)
  
  useEffect(()=>{
    setmenu(true)
  },[setmenu])
  // Criação de um array com números de 1 a 23192.168.1.2
  const numeros = Array.from({ length: 23 }, (_, index) => index + 1);

  // Estado para controlar a exibição do popup de abrir ordem
  const [abriOrdem, setAbriOrdem] = useState(false);
  
  // Estado para controlar a exibição do popup de finalizar ordem
  const [Abrir_IN_Fo, setAbrir_IN_Fo] = useState(false);
  const [MaqParada, setMaqParada] = useState({});
  const [qtdOs, setQtdOs] = useState('');
  const [qtdParada, setQtdParada] = useState('');
  const [TpParada, setTpParada] = useState(0);
  const[grGrafico, setgrGrafico] = useState([100,0]);
  const [graficDis,setgraficoDis] = useState({ 
    percent:'0',
    previsto:'00:00',
    tpParada:'00:00'
  });



  let [dadosApontamento,setdadosApontamento] = useState(false)
  const [ToadsOs, setTodasOs] = useState({})
  const[graficoTodasManutencao,setgraficoTodasManutencao]=useState([0,0,0,1])
  const [turnoAtual, setTurnoAtual]=useState('Turno nao encontrado!')

  // Função para abrir o popup de finalizar ordem
  const [selectMaq, setselectMaq] = useState();
  const Abrir_Fo = (maquinaId) => {
    setselectMaq(maquinaId)
    setAbrir_IN_Fo(true);
    document.body.style.overflow = "hidden";
  };

  // Função para abrir o popup de abrir ordem
  const handleAbrirPopUp = () => {
    document.body.style.overflow = "hidden";
    setAbriOrdem(true);
  };

  // Estado para armazenar dados das máquinas
  const [maquinasData, setMaquinasData] = useState({});

if(!localStorage.getItem('usuario')){
  handleSairApp()
}
  // Efeito colateral que escuta eventos de atualização do socket para dados de máquinas
  useEffect(() => {
    const userSocket = localStorage.getItem('usuario')
    const socket = io(serverid,{query: {userSocket}});
    socket.disconnect();
   
       

    const handleAtualizacaoMaquinaOrisol = (data) => {
      setMaqParada(data);
    };
  
    const handleAtualizacaoMaquina = (data) => {
      setMaquinasData(data);
    };
  
    const handleQtdServico = (data) => {
      setQtdOs(data);
    };

    const handleQtdParada = (data) => {
      setQtdParada(data);
    };
  
    const handleTempoParadaGeral = (data) => {
      setTpParada(data);
    };
    const handleManutencoes_Andamento = (data) => {
      setdadosApontamento(data)
    }


  

    socket.on('todas_Os', (data) => {
      setTodasOs(data)
  
    });

  
    const handleGraficoDisponibilidadeIo = (data) => {
      setgraficoDis(data)
      let maximo = 100;
      var Disponibilidade_Io = parseInt(data.percent);
      maximo = maximo - Disponibilidade_Io;
      let arrayGr = [maximo, Disponibilidade_Io];
      setgrGrafico(arrayGr);
    };

    
    const handleTipo_Manutencao = (data) => {
      setgraficoTodasManutencao(data)
    }

    const handleBuscaTurno = (data) => {
      setTurnoAtual(data)
    }


    // Configuração dos ouvintes de eventos
    socket.connect();
    socket.on('atualizacaoMaquina', handleAtualizacaoMaquina);
    socket.on('qtdServico', handleQtdServico);
    socket.on('qtdParada', handleQtdParada);
    socket.on('tempoParada_Geral', handleTempoParadaGeral);
    socket.on('GraficoDisponibilidade_Io', handleGraficoDisponibilidadeIo);
    socket.on('atualizacaoMaquinaOrisol', handleAtualizacaoMaquinaOrisol);
    socket.on('Manutencoes_Andamento', handleManutencoes_Andamento);
    socket.on('GraficoTarefas_Executada', handleTipo_Manutencao);
    socket.on('TurnoAgora',handleBuscaTurno);


    setLoad(false);

    // Obtém dados iniciais e desconecta do socket ao desmontar o componente
/*     fetchData(); */
    return () => {
      setLoad(true)
      socket.disconnect();
    };

  }, []); // Dependência: socket
  

  useEffect(() => {

  }, [maquinasData]);



  // Função para renderizar as máquinas
  const renderizarMaq = (numeros) => {
    return (
      numeros.map((numero) => {
       
        const maquinaId = `MAQ-${numero.toString().padStart(2, "0")}`;
        const status = maquinasData[maquinaId] || 'OUTRO'; // Se não estiver no JSON, considera 'OUTRO'
  
        let cor;
        switch (status) {
          case 'PARADA':
            cor = 'vermelho_MP';
            break;
          case 'OBSERVACAO':
            cor = 'roxo_MP';
            break;
          case 'PREVENTIVA':
            cor = 'Azul_MP';
            break;
          default:
            cor = 'verde';
        }
  
        return (
          <div
          style={{display:load?'none':'flex'}}
            className={`ball ${cor}`}
            id={maquinaId}
            onClick={() => Abrir_Fo(maquinaId)}
          >
            <span>{numero.toString().padStart(2, "0")} </span>
          </div>
        );
      })
    );
  };
  const [clickMg, setclickMg]=useState(false)
  const [mGeral, setmnGeral]=useState(false)
  const handleMenuGeral =() => {
    
    if (mGeral){
      setmnGeral(false)
    }else{
      setclickMg(true)
      setmnGeral(true)
      setTimeout(() => {
        setclickMg(false)
      }, 280);
    }
  }

const [clickConfigTurno,setConfigTurno]=useState(false)
  return (
    <>
   
    {clickConfigTurno&&<ConfigTurno setConfigTurno={setConfigTurno}/>}
    {mGeral&&<MenuGeral mGeral={mGeral} setmnGeral={setmnGeral} setConfigTurno={setConfigTurno} clickConfigTurno={clickConfigTurno}/>}
      {/* <AddmesmaOs/> */}
      {Abrir_IN_Fo && <IniciarManutencao
        Abrir_IN_Fo={Abrir_IN_Fo}
        setAbrir_IN_Fo={setAbrir_IN_Fo}
        selectMaq={selectMaq} 
        dadosApontamento={dadosApontamento} 

       
      />}
      {abriOrdem && <AbrirOdem abriOrdem={abriOrdem} setAbriOrdem={setAbriOrdem} 
      ToadsOs={ToadsOs} setTodasOs={setTodasOs} />}
      
      
      
      <div className="Container_Back_Pallet">
        <div className="Super_Container_home">
          <div style={{position:'relative'}} className="Bemvindo_user">
            <div className="svg_user">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M440-360h80l6-50q8-3 14.5-7t11.5-9l46 20 40-68-40-30q2-8 2-16t-2-16l40-30-40-68-46 20q-5-5-11.5-9t-14.5-7l-6-50h-80l-6 50q-8 3-14.5 7t-11.5 9l-46-20-40 68 40 30q-2 8-2 16t2 16l-40 30 40 68 46-20q5 5 11.5 9t14.5 7l6 50Zm40-100q-25 0-42.5-17.5T420-520q0-25 17.5-42.5T480-580q25 0 42.5 17.5T540-520q0 25-17.5 42.5T480-460ZM240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80H240Z" />
              </svg>
            </div>
            <div className="Dados_user">
              <div className="bm_Txt">Usuário: {localStorage.getItem('usuario')}</div>
              <div id="Userdados">{localStorage.getItem('SETOR')}</div>
            </div>

            <div className='Mg_menu_Click_style' style={{position:'absolute', right:'1px'}}
            onClick={()=>handleMenuGeral()}
            >
              <div className={`${clickMg?'Mg_menu_Click_style_open':''}`}></div>
              <svg  xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="40"><path d="M480.571-122q-29.571 0-50.57-20.884-21-20.884-21-50.21 0-28.839 20.835-50.372t50.093-21.533q30.071 0 50.571 21.503 20.499 21.503 20.499 50.499 0 28.997-20.429 49.997t-49.999 21Zm0-287.001q-29.571 0-50.57-20.835-21-20.835-21-50.093 0-30.071 20.835-50.571 20.835-20.499 50.093-20.499 30.071 0 50.571 20.429 20.499 20.429 20.499 49.999 0 29.571-20.429 50.57-20.429 21-49.999 21Zm0-286q-29.571 0-50.57-21.212-21-21.213-21-51t20.835-50.62q20.835-20.833 50.093-20.833 30.071 0 50.571 20.927 20.499 20.928 20.499 50.715 0 29.787-20.429 50.905-20.429 21.118-49.999 21.118Z"/></svg>
            </div>
          </div>

          <div  key={1} className="container_00_Maquinas">
            <div key={2}  className="container_01_Maquinas">
              <div key={5} className="ContaierMaq">
                <div key={4} className="Maquinas">
                  <div className="dados_info">
                    <span>Orisol em tempo real! </span>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M280-120 80-320l200-200 57 56-104 104h607v80H233l104 104-57 56Zm400-320-57-56 104-104H120v-80h607L623-784l57-56 200 200-200 200Z" />
                      </svg>
                    </div>
                    <div className="TurnoAnimation" style={{right:'25px',position:'absolute',fontSize:'15px',fontWeight:'800',color:'#212121'}}>  {turnoAtual}</div>
                  </div>
                  {load&&<ComponenteLoading/>}

                  {renderizarMaq(numeros)}

                </div>
                <div className="Legenda">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="ball_lEGEND"
                      style={{ backgroundColor: "green" }}
                    ></div>
                    <div>Liberada |</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="ball_lEGEND roxo_MP"></div>
                    <div>Observação |</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="ball_lEGEND Azul_MP"></div>
                    <div>Preventiva |</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="ball_lEGEND vermelho_MP"></div>
                    <div>Parada </div>
                  </div>
                </div>
              </div>

              <div className="Quadros_Dados">
                <div className="Quadro01">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M200-200h360v-200h200v-360H200v560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v400L600-120H200Zm80-280v-80h200v80H280Zm0-160v-80h400v80H280Zm-80 360v-560 560Z" />
                  </svg>
                  <span>Qtd Serviço</span>
                  <h2>{qtdOs?qtdOs:'0'}</h2>
                </div>

                <div className="Quadro01">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M120-560v-240h80v94q51-64 124.5-99T480-840q150 0 255 105t105 255h-80q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120Zm2 120h82q12 93 76.5 157.5T435-204l48 84q-138 0-242-91.5T122-440Zm412 70-94-94v-216h80v184l56 56-42 70ZM719 0l-12-60q-12-5-22.5-10.5T663-84l-58 18-40-68 46-40q-2-13-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T707-340l12-60h80l12 60q12 5 23 11.5t21 14.5l58-20 40 70-46 40q2 13 2 25t-2 25l46 40-40 68-58-18q-11 8-21.5 13.5T811-60L799 0h-80Zm40-120q33 0 56.5-23.5T839-200q0-33-23.5-56.5T759-280q-33 0-56.5 23.5T679-200q0 33 23.5 56.5T759-120Z" />
                  </svg>
                  <span>Tempo Parada</span>
                  <h2>{`${TpParada?TpParada:'00:00'}`}</h2>
                </div>

                <div className="Quadro01">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v13.5H622q0-58-41-99t-99-41q-58 0-99 41t-41 99q0 48 27 84t71 50v266h-70Zm210 0q-25 0-42.5-17.5T520-140v-200q0-18 9-32t24-22l27 54h60l-30-60h60l30 60h60l-30-60h60l30 60h60l-30-60h10q25 0 42.5 17.5T920-340v200q0 25-17.5 42.5T860-80H580Zm0-60h280v-140H580v140Z" />
                  </svg>
                  <span>Qtd. Parada</span>
                  <h2>{qtdParada?qtdParada:'0'}</h2>
                </div>
              </div>
            </div>

            <div className="container_02_Maquinas">
              <div className="infoGrafico">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z" />
                </svg>
                <span>Disponibilidade</span>
              </div>
              <div className="graficoDisponibilidade">
                <div className="Percent_Disponibilidade">
                  <div
                  style={{
                    display:"flex",
                    justifyContent: "center",
                    alignContent: "center",
                    fontSize:'clamp(22px,2.5vw,45px)',
                    fontWeight: '800',
                  }}
                  >{`${grGrafico[0].toFixed(0) + '%'}`}</div>
                  <div
                  style={{
                    display:"flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop:'5px',
                    fontSize:'clamp(12px,1.8vw,15px)',
                    fontWeight:'400',
                  }}
                  >Ativo</div>
                  <div
                   style={{
                    display:"flex",
                    justifyContent: "center",
                    alignContent: "center",
                    marginTop:'5px',
                    fontSize:'clamp(12px,1.8vw,15px)',
                    fontWeight:'400'
                  }}
                  > {graficDis.tpParada} De {graficDis.previsto} </div>
                </div>
                {<ChartComponent grGrafico={grGrafico} />}
              </div>
            </div>

          

            <div className="container_02_Maquinas">
              <div className="infoGrafico">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M159-120v-120h124L181-574q-27-15-44.5-44T119-680q0-50 35-85t85-35q39 0 69.5 22.5T351-720h128v-40q0-17 11.5-28.5T519-800q9 0 17.5 4t14.5 12l68-64q9-9 21.5-11.5T665-856l156 72q12 6 16.5 17.5T837-744q-6 12-17.5 15.5T797-730l-144-66-94 88v56l94 86 144-66q11-5 23-1t17 15q6 12 1 23t-17 17l-156 74q-12 6-24.5 3.5T619-512l-68-64q-6 6-14.5 11t-17.5 5q-17 0-28.5-11.5T479-600v-40H351q-3 8-6.5 15t-9.5 15l200 370h144v120H159Zm80-520q17 0 28.5-11.5T279-680q0-17-11.5-28.5T239-720q-17 0-28.5 11.5T199-680q0 17 11.5 28.5T239-640Zm126 400h78L271-560h-4l98 320Zm78 0Z" />
                </svg>
                <span>Tipo Manutenção</span>
              </div>
              <div className="graficoDisponibilidade">
                {graficoTodasManutencao&&<ChartTipoManutencao graficoTodasManutencao={graficoTodasManutencao}/>}
              </div>
              <div style={{margin:'2px'}} className="Label_grafico_TP">
                <div className="ballTp">
                  <div className="BallTpBlue"></div>
                  PREVENTIVA
                </div>
                <div className="ballTp">
                  CORRETIVA
                  <div className="BallTpred"></div>
                </div>
              </div>
            </div>

            {/*  */}
                  

            {/*  */}

           < MaquinaParadaOrs MaqParada={MaqParada} setMaqParada={setMaqParada}/>
            
            {/* <AnaliseDia/> */}
          
          </div>
          <div
      className="navMenu_Espelho"
      style={{
        width: "100%",
        height: "60px",
        backgroundColor: "transparent",
      }}
    >
      {/* Conteúdo da barra de navegação */}
    </div>
        </div>
        
      </div>

      
      <div className="Ordem_add_mais" onClick={handleAbrirPopUp}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
        >
          <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
        </svg>
      </div>
    </>
  );
}


