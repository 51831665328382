import '../loading.css'

export default function spinerBtn (){
    return (
        <>
            <div className='spn_contianer'>
                <div className='spn_circulo'></div>
            </div>
        </>
    )
}