import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);


const DoughnutChart = ({ grGrafico }) => {
  const corVermelho = ["rgb(247, 71, 71)", "rgba(30,30,30,0.2)"];
  const corVerde = ["rgb(13, 224, 112)", "rgba(30,30,30,0.2)"];

  const data = {
    labels: [],
    datasets: [
      {
        label: "Disponibilidade",
        data: grGrafico,
        backgroundColor: grGrafico[0] < 90 ? corVermelho : corVerde,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: "88%", // Ajuste este valor para alterar a espessura do gráfico
  };

  return <Doughnut data={data} options={options} />;
};

export default DoughnutChart;
