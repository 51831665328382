import './config_Turno.css';
import { useState, useEffect } from 'react';
import axios from "axios";
const serverid = ''

const ConfigTurno = ( {setConfigTurno} )=>{
const [vlTurnoSelect, setvlTurnoSelect] = useState('TURNO 2')
const [inicioTr, setIniciotr] = useState('')
const [fimTr, setfimtr] = useState('');
const [sbdinicioTr, setsbdiniciotr] = useState('')
const [sbdiFimTr, setsbdiFimTr] = useState('');
const [hrProducao, sethrProducao] = useState('');
const [hrProducaosbd, sethrProducaosbd] = useState('');
const [maqDisponi, setmaqDisponi] = useState('');
const [nescessMaq, setnescessMaq] = useState('');
const [dbDescanso,setDbdescanso] = useState('');
const [selctActive, setSelectActive] = useState(true)
useEffect(()=>{
    const handleBuscarTurno = async ()=>{
        try {
            const buscaDados = await axios.post(serverid+'/Sc_loading/buscarTurno/');
            const result = await buscaDados.data;
            console.log(result)
            setvlTurnoSelect(result.TURNO);
            setIniciotr(result.HORARIOS_TURNO.INICIO);
            setfimtr(result.HORARIOS_TURNO.FIM);
            setsbdiniciotr(result.HORARIOS_TURNO.SABADO_INICIO)
            setsbdiFimTr(result.HORARIOS_TURNO.SABADO_FIM);
            
            sethrProducao(result.DADOS_PRODUCAO.GERAL.HORAS_PRODUCAO)
            sethrProducaosbd(result.DADOS_PRODUCAO.GERAL.HORAS_PRODUCAO_SABADO)
            setmaqDisponi(result.DADOS_PRODUCAO.GERAL.MAQUINAS_DISPONIVEL);
            setnescessMaq(result.DADOS_PRODUCAO.GERAL.NECESSIDADE_MAQUINAS);
            setDbdescanso(result.DADOS_PRODUCAO.GERAL.DESCANSO);
        } catch (error) {
            console.log(error);
        }
    }
    handleBuscarTurno()
},[])

const handleAtualizaDadosTurno = async ()=>{
    
    try {
        const json = {
            DADOS_PRODUCAO: {
                GERAL: {
                  HORAS_PRODUCAO: hrProducao,
                  HORAS_PRODUCAO_SABADO: hrProducaosbd,
                  MAQUINAS_DISPONIVEL: maqDisponi,
                  NECESSIDADE_MAQUINAS: nescessMaq,
                  DESCANSO:dbDescanso,
                }
              },
              HORARIOS_TURNO: {
                FIM: fimTr,
                INICIO: inicioTr,
                SABADO_FIM: sbdiFimTr,
                SABADO_INICIO: sbdinicioTr
              },
              TURNO: vlTurnoSelect,
            }
            const envioDados = await axios.post(serverid+'/Sc_loading/RegistrarAtualizacao',json);
            const result = await envioDados.data;
            alert(result);
    } catch (error) {
        alert(error.response.data)
    }
}

const handleChangeInput = async (e,value) => {
    if(value === 1 ){
        setSelectActive(false)
        setvlTurnoSelect(e.target.value);

    }else if(value === 2 ){
        setIniciotr(e.target.value);
    }else if(value === 3 ){
        setfimtr(e.target.value);
    }else if(value === 4 ){
        setsbdiniciotr(e.target.value);
    }else if(value === 5 ){
        setsbdiFimTr(e.target.value);
    }else if(value === 6 ){
        sethrProducao(e.target.value);
    }else if(value === 7 ){
        sethrProducaosbd(e.target.value);
    }else if(value === 8 ){
        setmaqDisponi(e.target.value);
    }else if(value === 9 ){
        setnescessMaq(e.target.value);
    }else if(value === 10 ){
        setDbdescanso(e.target.value);
    }
    
}

useEffect(()=>{
    const handleBuscarTurno = async ()=>{
        try {
            const json = {
                DADOS_PRODUCAO: {
                    GERAL: {
                      HORAS_PRODUCAO: hrProducao,
                      HORAS_PRODUCAO_SABADO: hrProducaosbd,
                      MAQUINAS_DISPONIVEL: maqDisponi,
                      NECESSIDADE_MAQUINAS: nescessMaq
                    }
                  },
                  HORARIOS_TURNO: {
                    FIM: fimTr,
                    INICIO: inicioTr,
                    SABADO_FIM: sbdiFimTr,
                    SABADO_INICIO: sbdinicioTr
                  },
                  TURNO: vlTurnoSelect,
                }
            const buscaDados = await axios.post(serverid+'/Sc_loading/changeBuscarTurno/',json);
            const result = await buscaDados.data;
            setIniciotr(result.HORARIOS_TURNO.INICIO);
            setfimtr(result.HORARIOS_TURNO.FIM);
            setsbdiniciotr(result.HORARIOS_TURNO.SABADO_INICIO)
            setsbdiFimTr(result.HORARIOS_TURNO.SABADO_FIM);
            
            sethrProducao(result.DADOS_PRODUCAO.GERAL.HORAS_PRODUCAO)
            sethrProducaosbd(result.DADOS_PRODUCAO.GERAL.HORAS_PRODUCAO_SABADO)
            setmaqDisponi(result.DADOS_PRODUCAO.GERAL.MAQUINAS_DISPONIVEL);
            setnescessMaq(result.DADOS_PRODUCAO.GERAL.NECESSIDADE_MAQUINAS);
            setDbdescanso(result.DADOS_PRODUCAO.GERAL.DESCANSO);
            
        } catch (error) {
            console.log(error);
            setSelectActive(true);
        }
    }
    
    if(!selctActive){
        handleBuscarTurno()
        setSelectActive(true);
    }

},[fimTr, hrProducao, hrProducaosbd, inicioTr, maqDisponi, nescessMaq, sbdiFimTr, sbdinicioTr, selctActive, vlTurnoSelect])

const [pauseAtive, setPauseAtive] = useState(false);

const handleAtivarPauseAuto = () => {
    if(pauseAtive){
        setPauseAtive(false)
    }else{
        setPauseAtive(true)
    }
}
const handleFecharTurno = (e) => {
    setConfigTurno(false)
}

    return(
        <>
            <div className='configBlack'>
                <div className='configWhite'>
                <div
            className="Fechar_Novaordem"
            style={{
              position: "absolute",
              width: "25px",
              height: "25px",
              right: "5px",
              top: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleFecharTurno}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
                    <h3>Alterar dado turnos</h3>
                    <select
                    onChange={(e)=>{handleChangeInput(e,1)}}
                    className='congif_select'
                    value={vlTurnoSelect}
                    >
                        <option>TURNO 1</option>
                        <option>TURNO 2</option>
                        <option>TURNO 3</option>
                    </select>
                   
                    <div 
                    style={{
                        marginTop:'20px',
                        border:'1px solid',
                        position:'relative',
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'stretch',
                        alignItems:'stretch',
                        flexWrap:'wrap',
                        }}
                        >

                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            minWidth:'30%',
                            boxSizing:'border-box',
                            margin:'5px',

                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Inicio Turno
                        </label> 

                        <input
                          onChange={(e)=>{selctActive&&handleChangeInput(e,2)}}
                        value={inicioTr}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px',
                            minWidth:'30%',
                            flexWrap:'wrap',
                        }}
                        type='time'
                        ></input>
                        
                        </div>

                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            boxSizing:'border-box',
                            margin:'5px',
                            minWidth:'30%',
                            flex:'1',
                           
                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Fim Turno
                        </label> 

                        <input
                        onChange={(e)=>{handleChangeInput(e,3)}}
                        value={fimTr}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px'
                        }}
                        type='time'
                        ></input>
                        
                        </div>


                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            boxSizing:'border-box',
                            margin:'5px',
                            minWidth:'30%',
                            flex:'1',
                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Sabado Inicio
                        </label> 

                        <input 
                        onChange={(e)=>{handleChangeInput(e,4)}}
                        value={sbdinicioTr}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px'
                        }}
                        type='time'
                        ></input>
                        
                        </div>

                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            boxSizing:'border-box',
                            margin:'5px',
                            minWidth:'30%',
                            flex:'1',
                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Descanso
                        </label> 

                        <input 
                        onChange={(e)=>{handleChangeInput(e,10)}}
                        value={dbDescanso}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px'
                        }}
                        type='time'
                        ></input>
                        
                        </div>


                        
                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            boxSizing:'border-box',
                            margin:'5px',
                            minWidth:'30%',
                            flex:'1',
                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Sabado Fim
                        </label> 

                        <input
                        onChange={(e)=>{handleChangeInput(e,5)}}
                        value={sbdiFimTr}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px'
                        }}
                        type='time'
                        ></input>
                        
                        </div>
                    </div>

                    <div style={{
                        width:'100%',
                        boxSizing:'border-box',
                        display:'flex',
                        justifyContent:'flex-end',
                        marginTop: '25px',
                        }}
                        >
                    </div>
                    <hr></hr>

                    <h3>Alterar dados produção</h3>
                    
                    
                   
                    <div 
                    style={{
                        marginTop:'20px',
                        border:'1px solid',
                        position:'relative',
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'stretch',
                        alignItems:'stretch',
                        flexWrap:'wrap',
                        }}
                        >

                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            minWidth:'30%',
                            boxSizing:'border-box',
                            margin:'5px',

                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Horario produção
                        </label> 

                        <input
                        onChange={(e)=>{handleChangeInput(e,6)}}
                        value={hrProducao}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px',
                            minWidth:'30%',
                            flexWrap:'wrap',
                        }}
                        type='time'
                        ></input>
                        
                        </div>

                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            boxSizing:'border-box',
                            margin:'5px',
                            minWidth:'30%',
                            flex:'1',
                           
                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       
                        Horas Produção sábado
                        </label> 

                        <input 
                        onChange={(e)=>{handleChangeInput(e,7)}}
                        value={hrProducaosbd}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px'
                        }}
                        type='time'
                        ></input>
                        
                        </div>


                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            boxSizing:'border-box',
                            margin:'5px',
                            minWidth:'30%',
                            flex:'1',
                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Maq. disponivel
                        </label> 

                        <input 
                        onChange={(e)=>{handleChangeInput(e,8)}}
                        value={maqDisponi}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px'
                        }}
                        type='number'
                        ></input>
                        
                        </div>


                        
                        <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            boxSizing:'border-box',
                            margin:'5px',
                            minWidth:'30%',
                            flex:'1',
                        }}
                        >

                        <label
                        style={{
                            fontSize:'13px'
                        }}
                        >
                       Necess. maquinas
                        </label> 

                        <input 
                        onChange={(e)=>{handleChangeInput(e,9)}}
                        value={nescessMaq}
                        style={{
                            width:'100%',
                            boxSizing:'border-box',
                            border:'1px solid black',
                            backgroundColor:'transparent',
                            borderBottom: '1px solid',
                            height:'25px'
                        }}
                        type='number'
                        ></input>
                        
                        </div>
                    </div>

                    <div style={{
                        width:'100%',
                        boxSizing:'border-box',
                        display:'flex',
                        justifyContent:'flex-end',
                        marginTop: '25px',
                        }}
                        >
                        <button 
                        onClick={()=>{handleAtualizaDadosTurno()}}
                        className='configBtn'>
                            Atualizar
                        </button>
                    </div>
                    <hr></hr>
                <h3>Seu horario de descanso!</h3>
                <div style={{boxSizing:'border-box',border:'1px solid',width:'100%',padding:'5px 5px'}}>
                    <input type='time'></input>
                </div>
                <p>Deseja que tentamos  pausar automáticamente sua 
                    manutenção proxímo deste horario?
                </p>
                <div 
                onClick={(e)=>{handleAtivarPauseAuto(e)}}
                style={{
                    padding:'2px 2px',
                    width:'37px',
                    height:'18px',
                    borderRadius:'20px',
                    position:'relative',
                    display:'flex',
                    alignItems: 'center',
                    backgroundColor:pauseAtive?'rgba(80,220,0)':'rgba(220,40,10)',
                    boxShadow:'0 0 5px rgba(0,0,0,0.2)',
                    transition:'all 0.15s ease-in-out',
                    }}>
                        <div
                        style={{
                            transition:'all 0.15s linear',
                            position:'absolute',
                            width:'17px',
                            height:'17px',
                            borderRadius:'100%',
                            right:pauseAtive?'2px':'22px',
                            backgroundColor:'white',
                            display:'flex',
                            justifyContent:'center',
                            alignItems: 'center',
                        }}
                        >

                        </div>
                </div>
                        <p >{pauseAtive?'Ativado':'Desativado'}</p>
                </div>
                
            </div>
        </>
    )
}

export default ConfigTurno