import "./App.css";
import Home from "./homeScren/Home";
import Adduser from "./Add_user/Adduser";
import Login from "./LoginApp/LoginApp";
import Apontamento from "./Menu_Apontamento/Menuapontamento";
import Orisol from "./Setor_Mq/Orisol/Orisol";
import AddPreventiva from './Add_Preventiva/AddPreventiva';
import ComponenteAnaliseMaquinas from './AnaliseMaquinas/Analise';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MenusPhone from "./header_componets/Menus";
import Preventivas from "./Menu_Preventivas/Preventiva"
import { useState } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;


function App() {
  const [menu, setmenu]=useState(false)
  return (
    <div className="routerClass">
      <Router>
        <Routes>
          <Route path="/" element={<Login  />} />
          <Route path="/AddUser" element={<Adduser />} />
          <Route path="/AnaliseOrisol" element={<ComponenteAnaliseMaquinas setmenu={setmenu}/>} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Adicionar_Preventivas" element={<AddPreventiva setmenu={setmenu}/>} />
          <Route path="/Orisol-Castanhal" element={<Orisol setmenu={setmenu}/>} />
          <Route path="/Apontamento" element={<Apontamento  setmenu={setmenu}/>} />
          <Route path="/Preventivas" element={<Preventivas  setmenu={setmenu}/>} />
        </Routes>
        {menu && (
  <>
    
    <div className="Nave_phone">
      <MenusPhone />
    </div>
  </>
)}

      </Router>
      
    </div>
  );
}

export default App;
