
import { useEffect, useState } from "react";



export default function AmostraMaquinaManut({ MaqParada, setMaqParada }) {




  const Cronometro = ({ horaParada, situacao }) => {
    const [tempoDecorrido, setTempoDecorrido] = useState(0);
  
    useEffect(() => {
      if (situacao === 'PARADA') {
        const intervalId = setInterval(() => {
          const agora = new Date();
          const [data, hora] = horaParada.split(' ');
          const [dia, mes, ano] = data.split('-');
          const [horaStr, minuto] = hora.split(':');
          const horaParadaDate = new Date(ano, mes - 1, dia, horaStr, minuto);
          const diferenca = agora - horaParadaDate;
          setTempoDecorrido(diferenca);
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }, [horaParada, situacao]);
  
    const formatarTempo = (milissegundos) => {
      const segundosTotais = Math.floor(milissegundos / 1000);
      const segundos = segundosTotais % 60;
      const minutos = Math.floor(segundosTotais / 60) % 60;
      const horas = Math.floor(segundosTotais / 3600);
  
      const formatoDuasCasas = (valor) => (valor < 10 ? `0${valor}` : valor);
  
      return `${formatoDuasCasas(horas)}:${formatoDuasCasas(minutos)}:${formatoDuasCasas(segundos)}`;
    };
  
    return <span>{formatarTempo(tempoDecorrido)}</span>;
  };
  



  const maquisParada = (data) => {
    if (!MaqParada) {
      return <div
        style={{width:'80%', margin:'0 auto', fontSize:'12px'
      , 
      }}
      ><p>"Nenhuma manutenção em andamento! 😊 Não deixe de executar as preventivas!" </p></div>;
    }
    const eX = Object.keys(data);

    return (
      <div style={{ maxHeight:'90%',overflow:'auto',boxSizing:'border-box', display:'flex',
      flexWrap:'wrap',flexDirection:'row', alignItems:'stretch', padding:'5px'
      }}>
        {eX.map((key) => {
          const maquina = data[key];
          if (!maquina) {
            // Se maquina for null ou undefined, pula para a próxima iteração
            return null;
          }
          const status = maquina['SITUACAO'] || 'OUTRO'
          let cor;
          switch (status) {
            case 'PARADA':
              cor = 'vermelho_MP';
              break;
            case 'OBSERVACAO':
              cor = 'roxo_MP';
              break;
            case 'PREVENTIVA':
              cor = 'Azul_MP';
              break;
            default:
              cor = 'verde';
          }

          return (
            <div key={maquina['NUMERO_OS']} className={`container_IniciouManutencao ${maquina['MECANICO_INICIOU']!=='Em espera...'?'IniciouManutencao':''}`}>
              <div className={`Maq_Parou ${cor}`}>{key}</div>
              <div className="In_NomUser ">
              <svg style={{marginRight:'5px',minWidth:'20px'}} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z"/></svg>
                <span>{maquina['MECANICO_INICIOU']}</span>
              </div>
              <div className="container_dados_MaquinaParada">
                <div>
                  <div style={{marginBottom:'5px',display:'flex', alignItems:'center'}}>
                  <svg style={{marginRight:'5px',minWidth:'20px'}} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M320-320h320v-320H320v320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                  <span>{maquina['HORA_PAROU']}</span>
                  </div>

                  <div style={{marginBottom:'5px',display:'flex', alignItems:'center'}}>
                 
                  <svg className={`${status==='PARADA'&&'rotateTempo'}`}  style={{marginRight:'5px',minWidth:'20px'}} xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16"><path d="M320-160h320v-120q0-66-47-113t-113-47q-66 0-113 47t-47 113v120ZM160-80v-80h80v-120q0-61 28.5-114.5T348-480q-51-32-79.5-85.5T240-680v-120h-80v-80h640v80h-80v120q0 61-28.5 114.5T612-480q51 32 79.5 85.5T720-280v120h80v80H160Z"/></svg>
                  <span>
                    <Cronometro
                      horaParada={maquina['HORA_PAROU']}
                      situacao={maquina['SITUACAO']}
                      />
                  </span>
                  </div>
                  
                </div>
                <div style={{marginBottom:'2px',display:'flex', alignItems:'center'}}>
                <svg style={{marginRight:'5px',minWidth:'20px'}}  xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                  {maquina['PROBLEMA']}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="container_02_Maquinas">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 -960 960 960"
          width="20"
        >
          <path d="M320-240h60v-200h100l40 80h200v-240H600l-40-80H320v440Zm237-180-40-80H380v-120h143l40 80h97v120H557ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
        <span
          style={{ fontWeight:'600',fontSize: "clamp(15px,1.8vw,18px)", margin: "5px" }}
        >
          Maquinas Pendente
        </span>
      </div>
      <hr style={{ margin: "2px" }}></hr> {/*  */}
      {maquisParada(MaqParada)}
    </div>
  );
}
