
import { useState, useEffect } from 'react';
import React from 'react';
import GraficoTipoTarefa from './componentes_apontamento/componentesch/chartTipo_tarefa'
import GraficoApontamento from './componentes_apontamento/componentesch/chartApontamento'
import './MenuapontamentoStyle.css';
import axios from 'axios';
import MsgAviso from '../tela_Msg/msg';
import  io  from 'socket.io-client';
import MenuGeral from '../menuGeral/MenuGeral'
import GraficoTodosApont from './componentes_apontamento/componentesch/chartTodosApontamento'
import GraficoApontamentoMes from './componentes_apontamento/componentesch/chartApontMes';
import ConfigTurno from '../config/config_Turno'
axios.defaults.withCredentials = true;

const serverid = '';


async function limparCookies() {
    var cookies = document.cookie.split(";");
  
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var igualPos = cookie.indexOf("=");
      var nome = igualPos > -1 ? cookie.substr(0, igualPos) : cookie;
      document.cookie = nome + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

const handleSairApp = async (e) => {
    document.cookie = "usuario=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    await limparCookies()
    localStorage.clear();
    window.location.href = "/";
}

export default function Menu_Apontamento({ setmenu }){
    setmenu(true)

let [click, setclick] = useState(false)

const handleclick_geral = () => {

    if (!click){
        setclick(true)
    }else{
        setclick(false)
    }
    
}

/* componente para dados individuais */


<ComponentePreventiva/>

const [clickMg, setclickMg]=useState(false)
const [mGeral, setmnGeral]=useState(false)



const handleMenuGeral =() => {
  if (mGeral){
    setmnGeral(false)
  }else{
    setclickMg(true)
    setmnGeral(true)
    setTimeout(() => {
      setclickMg(false)
    }, 280);
  }
}

const [clickConfigTurno,setConfigTurno]=useState(false)
return (
  <>

  {mGeral&&<MenuGeral setmnGeral={setmnGeral} setConfigTurno={setConfigTurno} clickConfigTurno={clickConfigTurno}/>}
  {clickConfigTurno&&<ConfigTurno setConfigTurno={setConfigTurno}/>}
  
            <div className='apont_body2'>
            <div className='blueContainer'></div> {/* aqui é o fim do c */}
                <div className='apont_container01'>
                
                    <div className="Bemvindo_user">
                        <div className="svg_user">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                        >
                        <path d="M440-360h80l6-50q8-3 14.5-7t11.5-9l46 20 40-68-40-30q2-8 2-16t-2-16l40-30-40-68-46 20q-5-5-11.5-9t-14.5-7l-6-50h-80l-6 50q-8 3-14.5 7t-11.5 9l-46-20-40 68 40 30q-2 8-2 16t2 16l-40 30 40 68 46-20q5 5 11.5 9t14.5 7l6 50Zm40-100q-25 0-42.5-17.5T420-520q0-25 17.5-42.5T480-580q25 0 42.5 17.5T540-520q0 25-17.5 42.5T480-460ZM240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80H240Z" />
                        </svg>
                        </div>
                        <div className="Dados_user">
                        <div style={{color:'white'}} className="bm_Txt">{localStorage.getItem('usuario')} seja bem vindo!</div>
                        <div style={{color:'white'}} id="Userdados">{localStorage.getItem('SETOR')}</div>
                        </div>

                        <div className='Mg_menu_Click_style' style={{position:'absolute', right:'1px'}}
            onClick={handleMenuGeral}
            >
              <div className={`${clickMg?'Mg_menu_Click_style_open':''}`}></div>
              <svg style={{fill:'white'}}  xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="40"><path d="M480.571-122q-29.571 0-50.57-20.884-21-20.884-21-50.21 0-28.839 20.835-50.372t50.093-21.533q30.071 0 50.571 21.503 20.499 21.503 20.499 50.499 0 28.997-20.429 49.997t-49.999 21Zm0-287.001q-29.571 0-50.57-20.835-21-20.835-21-50.093 0-30.071 20.835-50.571 20.835-20.499 50.093-20.499 30.071 0 50.571 20.429 20.499 20.429 20.499 49.999 0 29.571-20.429 50.57-20.429 21-49.999 21Zm0-286q-29.571 0-50.57-21.212-21-21.213-21-51t20.835-50.62q20.835-20.833 50.093-20.833 30.071 0 50.571 20.927 20.499 20.928 20.499 50.715 0 29.787-20.429 50.905-20.429 21.118-49.999 21.118Z"/></svg>
            </div>
                    
                    </div>

                    <div style={{position:'relative', zIndex:'2'}} className='apont_select_container'>
                        <div className='apont_selectinfo'>
                        <div className={`apont_select ${!click?"apont_individual":""}`}
                        onClick={()=>handleclick_geral()}
                        >
                            Seu Usuário
                        </div>

                        <div 
                        className={`apont_select ${click?"apont_geral":""}`}
                        onClick={()=>handleclick_geral()}
                        >
                            Geral
                        </div>
                        </div>
                    </div>

                    {!click?<DadosIndividual/>:<DadosGeral/>}

                    {/* Aqui ié o final dos dados  */}
                   
                </div>

                
                <div style={{height:'55px', width:'100%'}}></div>
                
            </div>
           
     
        
    </>
 )
};


const ComponenteTf= ()=>{

    return(
        <>
            <div className='ApontContainer_Preventiva'>
            

                {/* aDD DADOS OS PARA AS PREVENTIVAS */}
                
                    <div className='TodasPrev_e_Tarefas1'>
                        <div>Estamos desenvolvendo esta funcionalidade em breve ficará disponivel!</div>
                        {/* <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/></svg>
                        <span style={{fontWeight:'800',fontSize:'12px'}}>MAQ-01</span>
                        <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M187.523-205.538q-25.768 0-43.569-17.801-17.8-17.8-17.8-43.586v-426.15q0-25.786 17.8-43.586 17.801-17.801 43.569-17.801h584.954q25.768 0 43.569 17.801 17.8 17.8 17.8 43.586v426.15q0 25.786-17.8 43.586-17.801 17.801-43.569 17.801H187.523Zm81.092-160.308h30.924v-228.308h-26.143l-64.165 46.308 17.231 24.615 42.153-29.077v186.462Zm121.231 0h144.923V-396H434.615l.154-3.538q18.539-17.27 32.577-31.616 14.039-14.346 28.962-30.538 16.577-18.462 23.981-34.517 7.403-16.055 7.403-35.176 0-27.625-19.692-45.197-19.692-17.572-50-17.572-21.423 0-40.577 12.635-19.154 12.634-28.038 33.057l30.153 12.308q5.654-12.5 16.155-19.942 10.501-7.442 22.307-7.442 17.308 0 27.962 9.153 10.653 9.154 10.653 25 0 12.154-4.884 21.847-4.885 9.692-18.654 24.615-21.346 23.654-39.615 41.654-18.27 18-43.616 42.155v33.268Zm288.443 0q31.365 0 51.153-18.677 19.789-18.678 19.789-48.1 0-21.694-11.154-35.274-11.154-13.579-31.462-18.183V-488q17.077-3.538 25.847-15.269 8.769-11.731 8.769-31.039 0-26.564-17.985-43.205t-47.185-16.641q-27.369 0-43.214 14.322-15.845 14.321-23.309 27.37l28 12.308q4.77-11.154 15.308-17.5Q663.385-564 676.154-564q15.308 0 24.961 8.654 9.654 8.654 9.654 21.961 0 15.539-11.538 25.077-11.539 9.539-28.827 9.539h-13.019v29.538h14.68q22.397 0 34.55 9.154 12.154 9.154 12.154 25.462 0 15.307-12.154 26.73-12.153 11.423-28.461 11.423-17 0-27.923-7.471-10.923-7.472-18.693-24.375l-28 10.77q8.654 24.269 28.13 37.98 19.476 13.712 46.621 13.712Z"/></svg>
                        <span style={{fontWeight:'400', fontSize:'12px'}}>998558 </span>
                        <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M480.053-394.462q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm320 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 153.847q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm-160 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm320 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943ZM226.895-126.154q-25.756 0-43.556-17.8-17.801-17.801-17.801-43.556v-506.21q0-25.756 17.801-43.557 17.8-17.8 43.738-17.8h73.846v-89.231h40v89.231h281.231v-89.231h36.923v89.231h73.846q25.938 0 43.738 17.8 17.801 17.801 17.801 43.557v506.21q0 25.755-17.801 43.556-17.8 17.8-43.556 17.8h-506.21Zm.182-36.923h505.846q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-346.462H202.462v346.462q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692Z"/></svg>
                        <span style={{fontWeight:'400', fontSize:'12px'}}>04-01-2024</span>
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                        <span style={{fontWeight:'400',fontSize:'10px'}}>VERIFICAR - PREDITIVA</span>
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                        <span style={{fontWeight:'400',fontSize:'10px'}}>1-CONJUNTO DO CABEÇOTE,2-MESA XY, 3- CONJUNTO PNEUMATICO, 4</span>
                        </div>   */}
                    </div>

            </div>
        </>
    )
}



const DadosIndividual = ()=>{
    let [graficoDadosApontamento, setgraficoDadosApontamento] = useState({
                APONTADO:'00:00',
                PERCENT_APONTADO:'0',
                PREVISTO:'00:00',
    })
    
    
    useEffect(()=>{
        const userSocket = localStorage.getItem('usuario')
        const socket = io(serverid,{query: {userSocket}});
        socket.disconnect();
    
    
        const handleAtualizacaoGrafico = (data) => {
            setgraficoDadosApontamento(data)
        };
    
        socket.connect();
        socket.on('GraficoApontamento', handleAtualizacaoGrafico);
        
                return () => {
                    socket.disconnect();
                  };  
        
    },[])
    
    let [clickTf, setclickTf01] = useState(true)
        
        const clickSelectTf = ()=> {
            if(clickTf){
                setclickTf01(false)
            }else{
                setclickTf01(true) 
            }
        }
    
    
        return(
            <>
            
            <div className='Apont_container_chart01'>
                <div className='Apont_chart01'>
                    <div className='apont_dash01'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m600.462-313.551 22.705-22.987-126.59-126.729v-179.464h-32.436v192.859l136.321 136.321ZM464.141-723.359h32.436v-81.667h-32.436v81.667Zm259.218 259.218h81.667v-32.436h-81.667v32.436ZM464.141-154.974h32.436v-81.667h-32.436v81.667ZM154.974-464.141h81.667v-32.436h-81.667v32.436ZM480.403-122.82q-74.442 0-139.366-28.22-64.924-28.219-113.429-76.69-48.504-48.472-76.646-113.216-28.142-64.744-28.142-139.162 0-73.986 28.22-139.216 28.219-65.23 76.69-113.568 48.472-48.338 113.216-76.313t139.162-27.975q73.986 0 139.22 28.132 65.234 28.131 113.568 76.357t76.309 113.333q27.975 65.107 27.975 138.955 0 74.442-28.053 139.366-28.053 64.924-76.357 113.306-48.305 48.382-113.412 76.646-65.107 28.265-138.955 28.265Z"/></svg>
                            <h4>Apontamento</h4>
                    </div> 
                    <div className='comportGraficoApontameto'>
                        <div className='dadosApont_hoje'>
                            <div style={{fontSize:'20px',marginBottom:'5px', fontWeight:'800'}}>{`${graficoDadosApontamento.PERCENT_APONTADO}%`}</div>
                            <div>Apontado</div>
                            <div>{`${graficoDadosApontamento.APONTADO} De ${graficoDadosApontamento.PREVISTO}`}</div>
                        </div>
                    <GraficoApontamento graficoDadosApontamento={graficoDadosApontamento}/> 
                    
                    </div>
                    <span style={{fontSize:'10px', position:'absolute',bottom:'2px',right:'10px'}}>03-01-2023 21:17</span>  
                </div>
    
    
                <div className='Apont_chart01'>
                    <div className='apont_dash01'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m438-240 226-226-58-58-169 169-84-84-57 57 142 142ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                            <h4>Tf. realizada</h4>
                    </div> 
                    <div className='comportGraficoApontameto'>
                        <div className='Label_tarefa'>
                            <div style={{fontSize:'11px',marginBottom:'2px', fontWeight:'800'}}>PREVENTIVA</div>
                            <div style={{fontSize:'11px',marginBottom:'2px', fontWeight:'800'}}>CORRETIVA</div>
                            <div style={{fontSize:'11px',marginBottom:'2px', fontWeight:'800'}}>TAREFAS</div>
                        </div>
                      
                    <GraficoTipoTarefa graficoDadosApontamento={graficoDadosApontamento}/>
                    
                    </div>
                        <span style={{fontSize:'10px', position:'absolute',bottom:'2px',right:'10px'}}>03-01-2023 21:17</span>  
                    </div> 
    
                    <div className='infor_Apont'>
                            <h3>{localStorage.getItem('usuario')} </h3>
                            <p style={{fontSize:'12px'}}>"Prevenir é o segredo do sucesso. Mantenha-se à frente com a manutenção preventiva."</p>
                    </div>
    
                    <div className='select_Apont_PreTf'>
                            <div 
                            className={`apont_select_Tf ${clickTf?'select_Preventiva':''}`}
                            onClick={(e)=>clickSelectTf(e)}
                            >Preventiva</div>
                            <div 
                            className={`apont_select_Tf ${!clickTf?'select_Tarefa':''}`}
                            onClick={(e)=>clickSelectTf(e)}
                            >Tarefas</div>
                    </div>    
    
                    {clickTf&&<ComponentePreventiva/>}   
                    {!clickTf&&<ComponenteTf/>}     
            </div>
        </>
      )
    }


const ComponenteFinalizaPreventiva=({obs,setObs,handlePausar_Preventiva,idFinaliza,setIdFinaliza,msg, setmsg, msgConteudo, setmsgConteudo})=>{
    const [fecharText, setFecharText] = useState(false)
        const handleTextAreaFinaliza = (e) => {
        let valueObs = e.target.value;
        valueObs = valueObs.toUpperCase()
        setObs(valueObs)
        
    }

    const handleFinalizaPrev = async ()  =>{
        try{
        const [Os,Mec] = idFinaliza.split('-')
        const json = {
            Os,
            Mec,
            obs
        }
        alert(Os + ' - ' +  Mec)
     
        setIdFinaliza(false)
        const dbConsulta = await axios.post(serverid + '/secaoApontamento/pause_Preventivas',json)
        const resulta = await dbConsulta.data
        setmsgConteudo(resulta)
        setmsg(true)  
        setTimeout(() => {
            setIdFinaliza(false)
            
        }, 400);
        setFecharText(true)
        } catch (error) {

            if(error.response.status === 408){
                setmsgConteudo(error.response.data)
                setmsg(false)
                    setTimeout( async () => {
                        await handleSairApp()
                      
                    }, 3700);
                    
                  }else{
                    setmsgConteudo(error.response.data)
                    setmsg(false)
                  }
        }


    }
    
    const handleClickFechar_Ordem = () => {
        setFecharText(true)
        setTimeout(() => {
            setIdFinaliza(false);
        }, 300);
       
      };
    return(
        <>
            <div className={`FinalizaPrev_black ${fecharText?'closeBlack_Finaliza':'openBlack_Finaliza'}`}>
                <div className={`FinalizaPrev_white ${fecharText?'FinalizaPrev_whiteClose':'FinalizaPrev_whiteOpen'}`}>
                    
                <div
            className="Fechar_Novaordem"
            style={{
              position: "absolute",
              width: "25px",
              height: "25px",
              right: "5px",
              top: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleClickFechar_Ordem}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>

                    <h4>Olá, {`${localStorage.getItem('usuario')}`}</h4>
                    <div><p style={{fontSize:'11px'}}>Você esta preste a finalizar uma preventiva
                    você precisa adicionar observações resumida do que foi realizado
                    , essas informações são úteis para análise caso a caso!</p></div>
                    <h3>observação</h3>
                    <textarea
                    value={obs}
                    onChange={handleTextAreaFinaliza}
                    style={{borderRadius:'10px',padding:'10px 10px',maxWidth:'100%',minWidth:'100%',maxHeight:'80px', minHeight:'80px',boxSizing:'border-box'}}></textarea>
                    <div style={{marginBottom:'15px',width:'100%', marginTop:'15px',position:'relative',
                                display:'flex',justifyContent:'flex-end'}}>
                        <button 
                        onClick={()=>{handleFinalizaPrev()}}
                        className='btnFinalizPrev'>Finalizar</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const ComponentePreventiva = ()=>{
    let [ClickPrev,setClickPrev] = useState('');
    let [ClickAtrasa,setClickAtrasa] = useState('');
    let [cliquePaus,setclickPaus] = useState();
    var [msg, setmsg] = useState(false)
    var [msgConteudo, setmsgConteudo] = useState(false)

    const handleclickPV_Pendente = ()=>{
        if(ClickPrev){
            setClickPrev(false)
        }else{
            setClickPrev(true) 
        }
    }
    const handleclickPV_Atrasada = ()=>{
        if(ClickAtrasa){
            setClickAtrasa(false)
        }else{
            setClickAtrasa(true) 
        }
    }
let[preventivaPEndente, setPendente] = useState();
let[qtdOsPendente,seQtdOsPendente] = useState('0')
let[qtdOsaAtrasado,seQtdOsAtrasado] = useState('0')
let [diclick,setidClick] = useState()
let [clickPub,setclickPub] = useState()
let [clickLoad,setclickLoad] = useState(false)
let [obs, setObs]=useState('')
let [idFinaliza,setIdFinaliza]=useState(false)
    useEffect(()=>{
        const handleBuscarUserPreventivas = async ()=>{
           
            try {
            const dbConsulta = await axios.post(serverid + '/secaoApontamento/buscarPreventiva_user')
            const resulta = await dbConsulta.data
            
            seQtdOsPendente(resulta['PENDENTE']['QTD_OS'])
            seQtdOsAtrasado(resulta['ATRASADO']['QTD_OS'])
            setPendente(resulta)
                
            } catch (error) {
                if(error.response.status === 408){
                    setmsgConteudo(error.response.data)
                    setmsg(false)
                    setTimeout( async () => {
                        await handleSairApp()
                      
                    }, 3700);
                        
                      }else{
                        setmsgConteudo(error.response.data)
                        setmsg(false)
                      }
                
            }
            
        }
        handleBuscarUserPreventivas()
    },[cliquePaus])


    const handleIniciar_Preventiva = async (e) => {
        try {
            setclickPub(true)
            setTimeout(() => {
                setclickPub(false)
            }, 270);
            setclickLoad(true)
        const dados = e.currentTarget.id
        const [Os,Mec] = dados.split('-');
        setidClick(Os)
        const json = {
            Os,
            Mec
        }
        const dbConsulta = await axios.post(serverid + '/secaoApontamento/IniciarManutencao_Preventiva',json)
        const resulta = await dbConsulta.data
        setclickPaus(true)
        setmsgConteudo(resulta)
        setmsg(true)
        setclickLoad(false)
      
        } catch (error) {
            setclickLoad(false)
            setclickPaus(false)
            if(error.response.status === 408){
                setmsgConteudo(error.response.data)
                setmsg(false)
                setTimeout( async () => {
                    await handleSairApp()
                  
                }, 3700);
                    
                  }else{
                    setmsgConteudo(error.response.data)
                    setmsg(false)
                  }
        }
        

    }

const handleFinalizar_Preventiva = async (e) => {
    const resulta = e.currentTarget.id
    setIdFinaliza(resulta)
}
    const handlePausar_Preventiva = async (e) => {
        try {
            setclickPub(true)
            setTimeout(() => {
                setclickPub(false)
            }, 300);
            setclickLoad(true)
            const dados = e.currentTarget.id
            const [Os,Mec] = dados.split('-')
            setidClick(Os)
            const json = {
                Os,
                Mec,
                obs
    }
            const dbConsulta = await axios.post(serverid + '/secaoApontamento/pause_Preventivas',json)
            const resulta = await dbConsulta.data
            setclickPaus(false)
            setmsgConteudo(resulta)
            setmsg(true)
            setclickLoad(false)
           
            

            } catch (error) {
                setclickLoad(false)
                setclickPaus(false)
                if(error.response.status === 408){
                    setmsgConteudo(error.response.data)
                    setmsg(false)
                    setTimeout( async () => {
                        await handleSairApp()
                      
                    }, 3700);
                        
                      }else{
                        setmsgConteudo(error.response.data)
                        setmsg(false)
                      }
            }
    }


    return(
        <>
        {idFinaliza&&<ComponenteFinalizaPreventiva obs={obs} setObs={setObs} handlePausar_Preventiva={handlePausar_Preventiva} idFinaliza={idFinaliza} setIdFinaliza={setIdFinaliza} msg={msg} setmsg={setmsg} msgConteudo={msgConteudo} setmsgConteudo={setmsgConteudo}/>}
         {msgConteudo&&<MsgAviso msg={msg} setmsg={setmsg} msgConteudo={msgConteudo} setmsgConteudo={setmsgConteudo}/>}
            <div className='ApontContainer_Preventiva'>
                <div 
                className='apont_item01'
                onClick={(e)=>handleclickPV_Pendente()}
                >
                    
                    <div style={{padding:'10px 18px',width:'60%'}}>
                        <div style={{display:'flex'}}>
                        <svg style={{fill:'rgb(3, 26, 38)', marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m726.385-158.385 27.23-27.23-62.846-62.074v-81.849h-38.154v98.009l73.77 73.144ZM408.615-680h142.77v-44.308q0-10.769-6.923-17.692t-17.693-6.923h-93.538q-10.77 0-17.693 6.923-6.923 6.923-6.923 17.692V-680Zm263.2 587.692q-61.815 0-104.661-43.031-42.847-43.031-42.847-104.846 0-61.815 43.032-104.661 43.031-42.847 104.846-42.847 61.815 0 104.661 43.032 42.847 43.031 42.847 104.846 0 61.815-43.032 104.661-43.031 42.846-104.846 42.846Zm-476.123-97.846q-22.637 0-38.088-15.45-15.45-15.451-15.45-38.088v-382.77q0-22.637 15.45-38.088Q173.055-680 195.692-680h184v-44.565q0-22.743 15.476-38.012 15.477-15.27 37.991-15.27h94.034q22.514 0 37.814 15.451 15.301 15.45 15.301 38.088V-680h184q22.637 0 38.088 15.45 15.45 15.451 15.45 38.088v231.423q-30.961-29.807-66.596-45.692-35.635-15.885-79.25-15.885-90.787 0-153.701 62.915Q455.384-330.787 455.384-240q0 8.684.894 25.278.893 16.594 2.183 24.568H195.692Z"/></svg>
                            <span style={{fontWeight:'700',fontSize:'16px', marginBottom:'10px'}}>Pendente!</span>
                        </div>
                        
                        <span style={{fontSize:'12px'}}>Suas Ordem preventiva pendente</span>
                    </div>

                    <div style={{
                        /* backgroundColor:'red', */
                        display:'flex',
                        alignItems:'center',
                        width:'50%',
                        height:'calc()'}}
                        >

                        <div style={{
                            alignItems:'center',
                            justifyContent:'center',
                            display:'flex',
                            borderRadius:'100%',
                            backgroundColor:'rgba(0,0,0,0.08)',
                            boxShadow:'0 2px 5px rgba(0,0,0,0.1)',
                            width:'40px',
                            height:'40px'}}
                            >
                            <span style={{
                                fontWeight:'700',
                                fontSize:'17px',
                            }}
                            >{qtdOsPendente}</span>
                        </div>

                        <div style={{width:'50%',
                            
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'right',
                            }}>
                        <svg style={{transition:'all 0.25s linear',transform: ClickPrev?'':'rotate(-90deg)',}}  xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32"><path d="M480-362.384 271.384-571l21.462-21.462L480-405.308l187.154-187.154L688.616-571 480-362.384Z"/></svg>
                        </div>
                        
                    </div>
                </div>

                {/* aDD DADOS OS PARA AS PREVENTIVAS */}
                <div className={`PrevPendente_item ${ClickPrev?'PrevPendente_item_open':ClickPrev!==''&&'PrevPendente_item_close'}`}>
                    {preventivaPEndente&&Object.keys(preventivaPEndente).map((obj, index)=> 
                    
                    <div>
                    {Object.keys(preventivaPEndente[obj]).map((Os, index)=>
                    (obj === 'PENDENTE'&& Os !== 'QTD_OS')&&(
                    <div className='TodasPrev_e_Tarefas'>
                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/></svg>
                        <span style={{fontWeight:'800',fontSize:'12px'}}>{preventivaPEndente[obj][Os]['MAQUINA']}</span>
                        <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M187.523-205.538q-25.768 0-43.569-17.801-17.8-17.8-17.8-43.586v-426.15q0-25.786 17.8-43.586 17.801-17.801 43.569-17.801h584.954q25.768 0 43.569 17.801 17.8 17.8 17.8 43.586v426.15q0 25.786-17.8 43.586-17.801 17.801-43.569 17.801H187.523Zm81.092-160.308h30.924v-228.308h-26.143l-64.165 46.308 17.231 24.615 42.153-29.077v186.462Zm121.231 0h144.923V-396H434.615l.154-3.538q18.539-17.27 32.577-31.616 14.039-14.346 28.962-30.538 16.577-18.462 23.981-34.517 7.403-16.055 7.403-35.176 0-27.625-19.692-45.197-19.692-17.572-50-17.572-21.423 0-40.577 12.635-19.154 12.634-28.038 33.057l30.153 12.308q5.654-12.5 16.155-19.942 10.501-7.442 22.307-7.442 17.308 0 27.962 9.153 10.653 9.154 10.653 25 0 12.154-4.884 21.847-4.885 9.692-18.654 24.615-21.346 23.654-39.615 41.654-18.27 18-43.616 42.155v33.268Zm288.443 0q31.365 0 51.153-18.677 19.789-18.678 19.789-48.1 0-21.694-11.154-35.274-11.154-13.579-31.462-18.183V-488q17.077-3.538 25.847-15.269 8.769-11.731 8.769-31.039 0-26.564-17.985-43.205t-47.185-16.641q-27.369 0-43.214 14.322-15.845 14.321-23.309 27.37l28 12.308q4.77-11.154 15.308-17.5Q663.385-564 676.154-564q15.308 0 24.961 8.654 9.654 8.654 9.654 21.961 0 15.539-11.538 25.077-11.539 9.539-28.827 9.539h-13.019v29.538h14.68q22.397 0 34.55 9.154 12.154 9.154 12.154 25.462 0 15.307-12.154 26.73-12.153 11.423-28.461 11.423-17 0-27.923-7.471-10.923-7.472-18.693-24.375l-28 10.77q8.654 24.269 28.13 37.98 19.476 13.712 46.621 13.712Z"/></svg>
                        <span style={{fontWeight:'400', fontSize:'12px'}}>{preventivaPEndente[obj][Os]['ORDEM_SERVICO']}</span>
                        <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M480.053-394.462q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm320 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 153.847q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm-160 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm320 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943ZM226.895-126.154q-25.756 0-43.556-17.8-17.801-17.801-17.801-43.556v-506.21q0-25.756 17.801-43.557 17.8-17.8 43.738-17.8h73.846v-89.231h40v89.231h281.231v-89.231h36.923v89.231h73.846q25.938 0 43.738 17.8 17.801 17.801 17.801 43.557v506.21q0 25.755-17.801 43.556-17.8 17.8-43.556 17.8h-506.21Zm.182-36.923h505.846q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-346.462H202.462v346.462q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692Z"/></svg>
                        <span style={{fontWeight:'400', fontSize:'12px'}}>{preventivaPEndente[obj][Os]['DATA_OS']}</span>
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                        <span style={{fontWeight:'400',fontSize:'10px'}}>{preventivaPEndente[obj][Os]['DESCRICAO']}</span>
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                        <span style={{fontWeight:'400',fontSize:'10px'}}>{preventivaPEndente[obj][Os]['DESCRICAO_TAREFA']}</span>
                        </div>

                        <hr></hr>

                        <div style={{display:'flex', justifyContent:'flex-end',margin:'15px, 10px'}}>
                        
                        <div style={{justifyContent:'center',display:'block',width:'25%'}}>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(20,100,255)',minWidth:'30px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="35"><path d="M240-40q-33 0-56.5-23.5T160-120v-440q0-33 23.5-56.5T240-640h120v80H240v440h480v-440H600v-80h120q33 0 56.5 23.5T800-560v440q0 33-23.5 56.5T720-40H240Zm200-280v-447l-64 64-56-57 160-160 160 160-56 57-64-64v447h-80Z"/></svg>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>Share</div>
                        </div>

                        <div style={{justifyContent:'center',display:'block',width:'25%'}}>
                            <div onClick={(e)=>{handleFinalizar_Preventiva(e)}} id={`${Os}-${preventivaPEndente[obj][Os]['MECANICO']}`} style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(20,100,255)',minWidth:'30px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="35"><path d="M435-438v-429h91v429h-91Zm44.246 342Q400-96 331.185-125.779q-68.816-29.778-120.796-81.977-51.98-52.199-81.684-121.205Q99-397.966 99-477.132q0-84.06 34.405-158.752Q167.81-710.577 232-764l71 70q-55.176 36.798-84.088 93.781Q190-543.235 190-477.95q0 120.643 84.489 205.796Q358.977-187 480.014-187q121.343 0 205.665-85.032Q770-357.064 770-477.766q0-67.638-28.5-124.436T658-697l71-71q63 54 98 130.443 35 76.442 35 160.808 0 78.563-29.9 147.824-29.9 69.261-81.879 121.39-51.979 52.129-121.866 81.832Q558.468-96 479.246-96Z"/></svg>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>Finalizar</div>
                        </div>
                        
                        <div style={{position:'relative',justifyContent:'center',display:'block',width:'25%'}}>
                        {preventivaPEndente[obj][Os]['MANUTENCAO_INICIADA']?
                            <div onClick={(e)=>{handlePausar_Preventiva(e)}} id={`${Os}-${preventivaPEndente[obj][Os]['MECANICO']}`} style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(225,20,80)',minWidth:'35px'}} xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="35"><path d="M360-320h80v-320h-80v320Zm160 0h80v-320h-80v320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
                            </div>:
                            <div onClick={(e)=>{handleIniciar_Preventiva(e)}} id={`${Os}-${preventivaPEndente[obj][Os]['MECANICO']}`} style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(50,200,20)',minWidth:'35px'}} xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="35"><path d="M378.087-296.652 663.348-480 378.087-663.348v366.696ZM480-71.869q-84.913 0-159.345-32.118t-129.491-87.177q-55.059-55.059-87.177-129.491Q71.869-395.087 71.869-480t32.118-159.345q32.118-74.432 87.177-129.491 55.059-55.059 129.491-87.177Q395.087-888.131 480-888.131t159.345 32.118q74.432 32.118 129.491 87.177 55.059 55.059 87.177 129.491Q888.131-564.913 888.131-480t-32.118 159.345q-32.118 74.432-87.177 129.491-55.059 55.059-129.491 87.177Q564.913-71.869 480-71.869Z"/></svg>
                            </div>
                            }
                            
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>{preventivaPEndente[obj][Os]['MANUTENCAO_INICIADA']?'Pausar':'Iniciar'}</div>
                        
                            <div style={{display:clickPub && diclick === Os?'flex':'none',top:'0',alignItems:'center',justifyContent:'center',width:'100%',height:'100%',position:'absolute'}}>
                                 <div className={`loadPrevPub ${clickLoad&&'loadtruePrevPub'}`}></div>
                            </div>
                            
                        <div style={{display:clickLoad && diclick === Os?'flex':'none',top:'0',alignItems:'center',justifyContent:'center',width:'100%',height:'100%',position:'absolute'}}>
                            <div className={`loadPrev ${clickLoad&&'loadtruePrev'}`}></div>
                        </div>
                        
                        </div>
                        </div>

                    </div>
                    )
                    )}
                    </div>
                    )}
                    
                </div>
                
                <div 
                className='apont_item01'
                onClick={()=>handleclickPV_Atrasada()}
                >
                    
                    <div style={{padding:'10px 18px',width:'60%'}}>
                        <div style={{display:'flex'}}>
                       
                        <svg style={{fill:'rgb(255, 26, 38)', marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm34-80h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"/></svg>
                            <span style={{fontWeight:'700',fontSize:'16px', marginBottom:'10px'}}>Atrasada!</span>
                        </div>
                        
                        <span style={{fontSize:'12px'}}>Suas Ordem preventiva Atrasada</span>
                            
                    </div>

                    <div style={{
                        /* backgroundColor:'red', */
                        display:'flex',
                        alignItems:'center',
                        width:'50%',
                        height:'calc()'}}
                        >

                        <div style={{
                            alignItems:'center',
                            justifyContent:'center',
                            display:'flex',
                            borderRadius:'100%',
                            backgroundColor:'rgba(0,0,0,0.08)',
                            boxShadow:'0 2px 5px rgba(0,0,0,0.1)',
                            width:'40px',
                            height:'40px'}}
                            >
                            <span style={{
                                fontWeight:'700',
                                fontSize:'17px',
                            }}
                            >{qtdOsaAtrasado}</span>
                        </div>

                        <div style={{width:'50%',
                            
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'right',
                            }}>
                        <svg style={{transition:'all 0.25s linear',transform: ClickAtrasa?'':'rotate(-90deg)',}}  xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32"><path d="M480-362.384 271.384-571l21.462-21.462L480-405.308l187.154-187.154L688.616-571 480-362.384Z"/></svg>
                        </div>
                        
                    </div>
                </div>

                <div className={`PrevPendente_item ${ClickAtrasa?'PrevPendente_item_open':ClickAtrasa!==''&&'PrevPendente_item_close'}`}>
                    
                {preventivaPEndente&&Object.keys(preventivaPEndente).map((obj, index)=> 
                    
                    <div>
                    {Object.keys(preventivaPEndente[obj]).map((Os, index)=>
                    (obj === 'ATRASADO'&& Os !== 'QTD_OS')&&(
                        <div className='TodasPrev_e_Tarefas'>
                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/></svg>
                        <span style={{fontWeight:'800',fontSize:'12px'}}>{preventivaPEndente[obj][Os]['MAQUINA']}</span>
                        <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M187.523-205.538q-25.768 0-43.569-17.801-17.8-17.8-17.8-43.586v-426.15q0-25.786 17.8-43.586 17.801-17.801 43.569-17.801h584.954q25.768 0 43.569 17.801 17.8 17.8 17.8 43.586v426.15q0 25.786-17.8 43.586-17.801 17.801-43.569 17.801H187.523Zm81.092-160.308h30.924v-228.308h-26.143l-64.165 46.308 17.231 24.615 42.153-29.077v186.462Zm121.231 0h144.923V-396H434.615l.154-3.538q18.539-17.27 32.577-31.616 14.039-14.346 28.962-30.538 16.577-18.462 23.981-34.517 7.403-16.055 7.403-35.176 0-27.625-19.692-45.197-19.692-17.572-50-17.572-21.423 0-40.577 12.635-19.154 12.634-28.038 33.057l30.153 12.308q5.654-12.5 16.155-19.942 10.501-7.442 22.307-7.442 17.308 0 27.962 9.153 10.653 9.154 10.653 25 0 12.154-4.884 21.847-4.885 9.692-18.654 24.615-21.346 23.654-39.615 41.654-18.27 18-43.616 42.155v33.268Zm288.443 0q31.365 0 51.153-18.677 19.789-18.678 19.789-48.1 0-21.694-11.154-35.274-11.154-13.579-31.462-18.183V-488q17.077-3.538 25.847-15.269 8.769-11.731 8.769-31.039 0-26.564-17.985-43.205t-47.185-16.641q-27.369 0-43.214 14.322-15.845 14.321-23.309 27.37l28 12.308q4.77-11.154 15.308-17.5Q663.385-564 676.154-564q15.308 0 24.961 8.654 9.654 8.654 9.654 21.961 0 15.539-11.538 25.077-11.539 9.539-28.827 9.539h-13.019v29.538h14.68q22.397 0 34.55 9.154 12.154 9.154 12.154 25.462 0 15.307-12.154 26.73-12.153 11.423-28.461 11.423-17 0-27.923-7.471-10.923-7.472-18.693-24.375l-28 10.77q8.654 24.269 28.13 37.98 19.476 13.712 46.621 13.712Z"/></svg>
                        <span style={{fontWeight:'400', fontSize:'12px'}}>{preventivaPEndente[obj][Os]['ORDEM_SERVICO']}</span>
                        <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M480.053-394.462q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm320 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 153.847q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm-160 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm320 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943ZM226.895-126.154q-25.756 0-43.556-17.8-17.801-17.801-17.801-43.556v-506.21q0-25.756 17.801-43.557 17.8-17.8 43.738-17.8h73.846v-89.231h40v89.231h281.231v-89.231h36.923v89.231h73.846q25.938 0 43.738 17.8 17.801 17.801 17.801 43.557v506.21q0 25.755-17.801 43.556-17.8 17.8-43.556 17.8h-506.21Zm.182-36.923h505.846q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-346.462H202.462v346.462q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692Z"/></svg>
                        <span style={{fontWeight:'400', fontSize:'12px'}}>{preventivaPEndente[obj][Os]['DATA_OS']}</span>
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                        <span style={{fontWeight:'400',fontSize:'10px'}}>{preventivaPEndente[obj][Os]['DESCRICAO']}</span>
                        </div>

                        <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
                        <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                        <span style={{fontWeight:'400',fontSize:'10px'}}>{preventivaPEndente[obj][Os]['DESCRICAO_TAREFA']}</span>
                        </div>

                        <hr></hr>

                        <div style={{display:'flex', justifyContent:'flex-end',margin:'15px, 10px'}}>
                        
                        <div style={{justifyContent:'center',display:'block',width:'25%'}}>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(20,100,255)',minWidth:'30px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="35"><path d="M240-40q-33 0-56.5-23.5T160-120v-440q0-33 23.5-56.5T240-640h120v80H240v440h480v-440H600v-80h120q33 0 56.5 23.5T800-560v440q0 33-23.5 56.5T720-40H240Zm200-280v-447l-64 64-56-57 160-160 160 160-56 57-64-64v447h-80Z"/></svg>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>Share</div>
                        </div>

                        <div style={{justifyContent:'center',display:'block',width:'25%'}}>
                            <div onClick={(e)=>{handleFinalizar_Preventiva(e)}} id={`${Os}-${preventivaPEndente[obj][Os]['MECANICO']}`} style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(20,100,255)',minWidth:'30px'}} xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="35"><path d="M435-438v-429h91v429h-91Zm44.246 342Q400-96 331.185-125.779q-68.816-29.778-120.796-81.977-51.98-52.199-81.684-121.205Q99-397.966 99-477.132q0-84.06 34.405-158.752Q167.81-710.577 232-764l71 70q-55.176 36.798-84.088 93.781Q190-543.235 190-477.95q0 120.643 84.489 205.796Q358.977-187 480.014-187q121.343 0 205.665-85.032Q770-357.064 770-477.766q0-67.638-28.5-124.436T658-697l71-71q63 54 98 130.443 35 76.442 35 160.808 0 78.563-29.9 147.824-29.9 69.261-81.879 121.39-51.979 52.129-121.866 81.832Q558.468-96 479.246-96Z"/></svg>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>Finalizar</div>
                        </div>
                        
                        <div style={{position:'relative',justifyContent:'center',display:'block',width:'25%'}}>
                            {preventivaPEndente[obj][Os]['MANUTENCAO_INICIADA']?   
                        <div onClick={(e)=>{handlePausar_Preventiva(e)}} id={`${Os}-${preventivaPEndente[obj][Os]['MECANICO']}`} style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(225,20,80)',minWidth:'35px'}} xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="35"><path d="M360-320h80v-320h-80v320Zm160 0h80v-320h-80v320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
                            </div>:
                            <div onClick={(e)=>{handleIniciar_Preventiva(e)}} id={`${Os}-${preventivaPEndente[obj][Os]['MECANICO']}`} style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>
                            <svg style={{fill:'rgba(50,200,20)',minWidth:'35px'}} xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="35"><path d="M378.087-296.652 663.348-480 378.087-663.348v366.696ZM480-71.869q-84.913 0-159.345-32.118t-129.491-87.177q-55.059-55.059-87.177-129.491Q71.869-395.087 71.869-480t32.118-159.345q32.118-74.432 87.177-129.491 55.059-55.059 129.491-87.177Q395.087-888.131 480-888.131t159.345 32.118q74.432 32.118 129.491 87.177 55.059 55.059 87.177 129.491Q888.131-564.913 888.131-480t-32.118 159.345q-32.118 74.432-87.177 129.491-55.059 55.059-129.491 87.177Q564.913-71.869 480-71.869Z"/></svg>
                            </div>
                            }
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',fontSize:'11px', margin:'0 auto',width:'100%',boxSizing:'border-box'}}>{preventivaPEndente[obj][Os]['MANUTENCAO_INICIADA']?'Pausar':'Iniciar'}</div>
                            
                            <div style={{display:clickPub && diclick === Os?'flex':'none',top:'0',alignItems:'center',justifyContent:'center',width:'100%',height:'100%',position:'absolute'}}>
                                 <div className={`loadPrevPub ${clickLoad&&'loadtruePrevPub'}`}></div>
                            </div>
                            
                        <div style={{display:clickLoad && diclick === Os?'flex':'none',top:'0',alignItems:'center',justifyContent:'center',width:'100%',height:'100%',position:'absolute'}}>
                            <div className={`loadPrev ${clickLoad&&'loadtruePrev'}`}></div>
                        </div>
                        
                        </div>
                        </div>

                    </div>

)
)}
</div>
)}
                </div>
                

            </div>
        </>
    )
}




const  DadosGeral = ()=>{
    let [graficoTodoApont,setgraficoTodoApont]=useState()
    let [atdTodoApont,setqtdTodoApont]=useState(1)
    let [graficoTodoApontMes,setgraficoTodoApontMes]=useState()
    let [arrayPercent, setArraypercent]=useState(['00:00','00:00'])
    const [hconlta,setclickConcultacion]=useState(false)
    const handleClickConsulta =()=>{
    setclickConcultacion(true)
    }

    useEffect(()=>{
        const userSocket = localStorage.getItem('usuario')
        const socket = io(serverid,{query: {userSocket}});
        socket.disconnect();
    
    
        const handleAtualizacaoGrafico = (data) => {
           
            setgraficoTodoApont(data)
            let arraymec = data.MECANICOS
            setqtdTodoApont(parseInt(arraymec.length))
 
        };
        const handleAtualizacaoGrafico_Mes = (data) => {
           
            setArraypercent(data.ARRAY_HORAS)
            setgraficoTodoApontMes(data)
  
        };
    
        socket.connect();
        socket.on('GraficoTodoApont', handleAtualizacaoGrafico);
        socket.on('GraficoMesAponta', handleAtualizacaoGrafico_Mes);
        
                return () => {
                    socket.disconnect();
                  };  
        
    },[])

    return(
        <>
        <div className='Geral_Container01'>
                <div style={{fontSize:'18px', fontWeight:'600'}}>{`Olá, ${localStorage.getItem('usuario')}`}</div>
                <p style={{fontSize:'clamp(12px,1.8vw,14px)'}}>Aqui você pode analisar e encontrar padrões no apontamento,<br></br>
                pode filtrar entre colaboradores e datas especificas para melhor analise
                </p>
        </div>

        <div className='Geral_Container02'>
                <div style={{fontSize:'20px', fontWeight:'600', color:'#212121'}}>Apontamento Hoje</div>
                <div style={{position:'absolute',right:'5px'}}><span style={{fontWeight:'800'}}>Orisol</span> - 2024</div>
        </div>

        <div className='Container_Geral03'>
            <div style={{flex:'1',minWidth:'45%',margin:'5px',minHeight:'200px',overflow:'auto',maxHeight:'200px',borderRadius:'8px',width:'50%',maxWidth:'500px', padding:'5PX 8PX',boxSizing:'border-box', boxShadow:'0 3px 5px rgba(0,0,0,0.3)'}}>
            <div style={{ borderRadius: '8px', width: '100%', maxWidth: '500px', boxSizing: 'border-box', minHeight: parseInt(atdTodoApont) < 9 ? '100%' : '300px' }}>
                    {graficoTodoApont&&<GraficoTodosApont graficoTodoApont={graficoTodoApont}/>}
                </div>
            </div>

            <div style={{flex:'1',minWidth:'45%',margin:'5px',overflow:'auto',borderRadius:'8px',width:'50%',maxHeight:'200px',maxWidth:'500px', padding:'5PX 8PX',boxSizing:'border-box', boxShadow:'0 3px 5px rgba(0,0,0,0.3)'}}>
                <div style={{boxSizing:'border-box',position:'relative',display:'flex',justifyContent:'center',alignItems:'center',color:'#212121', fontWeight:'600'}}>
                <svg style={{position:'absolute', left:'0'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M212.309-100.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h55.385v-84.615h61.537v84.615h303.076v-84.615h59.999v84.615h55.385q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-375.382H200v375.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM200-607.69h560v-100.001q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v100.001Zm0 0V-720v112.31Zm280 210.767q-14.692 0-25.038-10.346t-10.346-25.038q0-14.692 10.346-25.038T480-467.691q14.692 0 25.038 10.346t10.346 25.038q0 14.692-10.346 25.038T480-396.923Zm-160 0q-14.692 0-25.038-10.346t-10.346-25.038q0-14.692 10.346-25.038T320-467.691q14.692 0 25.038 10.346t10.346 25.038q0 14.692-10.346 25.038T320-396.923Zm320 0q-14.692 0-25.038-10.346t-10.346-25.038q0-14.692 10.346-25.038T640-467.691q14.692 0 25.038 10.346t10.346 25.038q0 14.692-10.346 25.038T640-396.923ZM480-240q-14.692 0-25.038-10.346t-10.346-25.038q0-14.692 10.346-25.038 10.346-10.347 25.038-10.347t25.038 10.347q10.346 10.346 10.346 25.038t-10.346 25.038Q494.692-240 480-240Zm-160 0q-14.692 0-25.038-10.346t-10.346-25.038q0-14.692 10.346-25.038 10.346-10.347 25.038-10.347t25.038 10.347q10.346 10.346 10.346 25.038t-10.346 25.038Q334.692-240 320-240Zm320 0q-14.692 0-25.038-10.346t-10.346-25.038q0-14.692 10.346-25.038 10.346-10.347 25.038-10.347t25.038 10.347q10.346 10.346 10.346 25.038t-10.346 25.038Q654.692-240 640-240Z"/></svg>
                    Mês {graficoTodoApontMes&&graficoTodoApontMes.MES}
            </div>

            <div style={{maxWidth:'100%',overflow:'hidden',height:'90%',display:'flex',justifyContent:'center',alignItems:'center',maxHeight:'100%',borderRadius:'8px',width:'100%',boxSizing:'border-box'}}>
                    <div style={{position:'relative',width:'100%',display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
                    <div style={{boxSizing:'border-box',padding:'5px 5px',color:'#212121',display:'block',position:'absolute',margin:'0 auto',width:'80%', height:'80%'}}>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center', width:'100%',boxSizing:'border-box',height:'100%'}}>
                            <div style={{marginBottom:'10px',fontSize:'28px', fontWeight:'800'}}>{graficoTodoApontMes&&`${graficoTodoApontMes.PERCENT}%`}</div>
                            <div style={{fontSize:'clamp(12px,1.8vw,15px)'}}>{arrayPercent[0]} de {arrayPercent[1]}</div>
                        </div>
                    </div>
                    {graficoTodoApontMes&&<GraficoApontamentoMes graficoTodoApontMes={graficoTodoApontMes}/>}
                    </div>
                </div>
            </div>
        </div>

        <div style={{borderLeft:'5px solid rgba(20,210,20)',backgroundColor:'white', color:'#212121', marginBottom:'25px'}} className='Geral_Container01'>
                <div style={{fontSize:'18px', fontWeight:'600'}}>{`Consulta, `}</div>
                <p style={{fontSize:'clamp(12px,1.8vw,14px)'}}>Realize aqui consulta dos apontamento por mecânico!, Essa tela não estará disponivel nesse menu, ficará disponivel no menu principal do app para analise mais rápida!
                </p>
                <div style={{width:'100%',display:'flex', justifyContent:'flex-end'}}>
                    <button className='BtnConsultar'
                    onClick={(e)=>{handleClickConsulta(e)}}
                    >Consultar</button>
                </div>
        </div>

        {hconlta&&<ComponenteConsulta/>}

        <div style={{borderLeft:'5px solid rgba(240,100,0)',backgroundColor:'white', color:'#212121', marginBottom:'25px'}} className='Geral_Container01'>
                <div style={{fontSize:'18px', fontWeight:'600'}}>{`Info, ${localStorage.getItem('usuario')}`}</div>
                <p style={{fontSize:'clamp(12px,1.8vw,14px)'}}>Outros dados serão adicionado aqui em breve, assim que houver dados suficiente para análise!
                </p>
        </div>
        </>
    )
}

const ComponenteConsulta = ()=>{
    const [selectMEc, setSElectMec]=useState('');
    const [selectDate, setselectDate]=useState('');
    let [dadosApontamento, setDadosApontamento]=useState('');
    const [percent,setpercent]=useState('0%');
    const [previsto,setprevisto]=useState('00:00');
    const [horasApontado,sethorasApontado]=useState('00:00');
    var [msg, setmsg] = useState(false)
    var [msgConteudo, setmsgConteudo] = useState(false)
    const consultarClick =(e)=>{
        setSElectMec(e.target.value)
    }
    const changeDate =(e)=>{
        setselectDate(e.target.value)
    }
    const clickConsultaApontamento = async (e)=>{
        try {
            const json = {
                data:selectDate,
                Mec:selectMEc
            }
            const envio = await axios.post(serverid + '/secaoApontamento/ConsultaApontamento',json)
            setDadosApontamento(await envio.data)
            const result = envio.data
            setpercent(result.DADOS_FIXO.PERCENTUAL);
            setprevisto(result.DADOS_FIXO.PERVISTO);
            sethorasApontado(result.DADOS_FIXO.HORAS_APONTADO)
        } catch (error) {
            if(error.response.status === 408){
                setmsgConteudo(error.response.data)
                setmsg(false)
                setTimeout( async () => {
                    await handleSairApp()
                  
                }, 3700);
                    
                  }else{
                    setmsgConteudo(error.response.data)
                    setmsg(false)
                  }
            
        }
    }
    let [mecanicosDados2, setmecanicosDados2] = useState(false) 

  useEffect(() => {
    const handleuser = async (e) => {
      if(!mecanicosDados2){
        const dados = await axios.post(serverid+"/secaoPreventiva/userSelect_Mecanico_Preventivas")
   

         setmecanicosDados2(dados.data)
      }
    }
    handleuser()
  },[mecanicosDados2])
    
    return(

<>  
{msgConteudo&&<MsgAviso msg={msg} setmsg={setmsg} msgConteudo={msgConteudo} setmsgConteudo={setmsgConteudo}/>}
    <div style={{width:'100%', boxSizing:'border-box',display:'flex', alignItems:'center', justifyContent:'center'}}>

        <div className='editInputsConsulta'>

            <input style={{outline: 'none',}} name='1' id='1' type='date' placeholder="" 
            value={selectDate}
            onChange={(e)=>{changeDate(e)}}
            ></input>
        </div>
        <div className='editInputsConsulta'>
            <label style={{pointerEvents:'none',position: 'absolute',fontSize:selectMEc&&'12px', top:selectMEc?'-15px':'11px', marginLeft:'5px', transition:'all 0.2s linear'}}>Mecânico</label>
            <select style={{outline: 'none',}}
            value={selectMEc}
            onChange={(e)=>{consultarClick(e)}}
            >
                <option></option>
                 {mecanicosDados2&&mecanicosDados2.map((value,index)=>(
                    <option>{value}</option>
                 ))}
                

            </select>
        </div>

        <div style={{marginLeft:'5px'}}>
            <button className='btnEnviarConsulta'
            onClick={(e)=>{clickConsultaApontamento(e)}}
            >Enviar</button>
        </div>

    </div>

       

    <div className='Impresso' style={{padding:'8px',width:'100%', boxSizing:'border-box'}}>
    <div style={{fontWeight:'300',color:'#212121', margin:'15px 0px 0px 0px',display:'inline-flex'}}>Apontamento do {selectMEc} do dia {}</div>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',color:'#212121',padding:'10px',margin:'5px 0px 5px 0px',width:'100%', boxSizing:'border-box'}}>
           
        <div style={{minHeight:'80px',minWidth:'110px',position:'relative',margin:'5px',display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center',width:'50%', borderRadius:'8px', boxSizing:'border-box',boxShadow:'0 3px 5px rgba(0,0,0,0.3)'}}> 
            <svg style={{position:'absolute',top:'5px',left:'2px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m438-240 226-226-58-58-169 169-84-84-57 57 142 142ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                <div style={{color:'rgba(0,0,0,0.7)',fontSize:'clamp(17px, 1.8vw, 28px)',fontWeight:'800'}}>{horasApontado}</div>
                <div style={{position:'absolute',bottom:'5px',right:'8px',fontSize:'clamp(13px,1.8vw,18px)'}}>Apontado</div>
            </div>

            <div style={{minHeight:'80px',minWidth:'110px',position:'relative',margin:'5px',display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center',width:'50%', borderRadius:'8px', boxSizing:'border-box',boxShadow:'0 3px 5px rgba(0,0,0,0.3)'}}> 
            <svg  style={{position:'absolute',top:'5px',left:'2px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M203-480h117q11 0 21 5.5t15 16.5l44 88 124-248q11-23 36-23t36 23l69 138h92q-15-102-93-171t-184-69q-106 0-184 69t-93 171Zm277 320q106 0 184-69t93-171H640q-11 0-21-5.5T604-422l-44-88-124 248q-11 23-36 23t-36-23l-69-138h-92q15 102 93 171t184 69Zm0 80q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440h80q0 116 82 198t198 82q116 0 198-82t82-198h80q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80ZM120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119h-80q0-116-82-198t-198-82q-116 0-198 82t-82 198h-80Zm240-400v-80h240v80H360Zm120 680q-116 0-198-82t-82-198q0-116 82-198t198-82q116 0 198 82t82 198q0 116-82 198t-198 82Zm0-280Z"/></svg>
                <div style={{color:'rgba(0,0,0,0.7)',fontSize:'clamp(17px, 1.8vw, 28px)',fontWeight:'800'}}>{previsto}</div>
                <div style={{position:'absolute',bottom:'5px',right:'8px',fontSize:'clamp(13px,1.8vw,18px)'}}>Previsto</div>
            </div>

            <div style={{minHeight:'80px',minWidth:'110px',position:'relative',margin:'5px',display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center',width:'50%', borderRadius:'8px', boxSizing:'border-box',boxShadow:'0 3px 5px rgba(0,0,0,0.3)'}}> 
            <svg style={{position:'absolute',top:'5px',left:'2px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m438-240 226-226-58-58-169 169-84-84-57 57 142 142ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                <div style={{color:'rgba(0,0,0,0.7)',fontSize:'clamp(17px, 1.8vw, 28px)',fontWeight:'800'}}>{percent}</div>
                <div style={{position:'absolute',bottom:'5px',right:'8px',fontSize:'clamp(13px,1.8vw,18px)'}}>% Apontado</div>
            </div>
        </div>

        {Object.keys(dadosApontamento).map((obj, index) => (
            obj !=='DADOS_FIXO'&&(
    <div key={index} style={{ margin: '0 auto', color: 'black', fontSize: 'clamp(12px, 1.8vw,16px)', marginBottom: '12px', boxShadow: '0 0 3px rgba(0,0,0,0.3)', width: '100%', boxSizing: 'border-box', padding: '10px 10px' }}>
        <div style={{ fontWeight: '600', display: 'flex', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm100-200h46v-240h-36l-70 50 24 36 36-26v180Zm124 0h156v-40h-94l-2-2q21-20 34.5-34t21.5-22q18-18 27-36t9-38q0-29-22-48.5T458-600q-26 0-47 15t-29 39l40 16q5-13 14.5-20.5T458-558q15 0 24.5 8t9.5 20q0 11-4 20.5T470-486l-32 32-54 54v40Zm296 0q36 0 58-20t22-52q0-18-10-32t-28-22v-2q14-8 22-20.5t8-29.5q0-27-21-44.5T678-600q-25 0-46.5 14.5T604-550l40 16q4-12 13-19t21-7q13 0 21.5 7.5T708-534q0 14-10 22t-26 8h-18v40h20q20 0 31 8t11 22q0 13-11 22.5t-25 9.5q-17 0-26-7.5T638-436l-40 16q7 29 28.5 44.5T680-360ZM160-240h640v-480H160v480Zm0 0v-480 480Z" />
            </svg>
            <div style={{ margin: '6px' }}>{obj}</div>
            <div>{dadosApontamento[obj]['MAQUINA']}</div>
        </div>
        <div>
            {Object.keys(dadosApontamento[obj]).map((value, innerIndex) => (
                value !=='MAQUINA'&&(
                <ul key={innerIndex}>
                    <li><div style={{ fontSize: 'clamp(10px, 1.8vw,14px)', margin: '0 0 5px 0' }}>{dadosApontamento[obj][value]['PROBLEMA']}</div></li>
                    <li><div style={{ fontSize: 'clamp(10px, 1.8vw,14px)', margin: '0 0 5px 0' }}>{dadosApontamento[obj][value]['OBSERVACAO']}</div></li>
                    <li>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '5px' }}>Inicio: {dadosApontamento[obj][value]['HORA_INICOU']}</div>|
                            <div style={{ marginRight: '5px' }}>Término: {dadosApontamento[obj][value]['PAUSOU']}</div>|
                            <div style={{}}>Apontado: {dadosApontamento[obj][value]['APONTADO']}</div>
                        </div>
                    </li>
                    <hr />
                </ul>
                )
            ))}
        </div>
    </div>
            )
))}

       


    </div>
    </>

    )
    
}