import "./Home.css";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

export default function Homes() {

  const NaveOrisol = useNavigate();
  const handlNave_Orisol = () => {
    NaveOrisol("/Orisol-Castanhal");
  };
  const [prOpen, setprOepn]=useState(false);
  const handleClickOpenParametors = () => {
    setprOepn(true)
  }
  return (
    <>
    {prOpen&&< ComponentePrmaquinas prOpen={prOpen} setprOepn={setprOepn}/>}
      <div className="back_Container_home">
        <div className="Super_Container_home">
          <div className="Bemvindo_user">
            <div className="svg_user">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M440-360h80l6-50q8-3 14.5-7t11.5-9l46 20 40-68-40-30q2-8 2-16t-2-16l40-30-40-68-46 20q-5-5-11.5-9t-14.5-7l-6-50h-80l-6 50q-8 3-14.5 7t-11.5 9l-46-20-40 68 40 30q-2 8-2 16t2 16l-40 30 40 68 46-20q5 5 11.5 9t14.5 7l6 50Zm40-100q-25 0-42.5-17.5T420-520q0-25 17.5-42.5T480-580q25 0 42.5 17.5T540-520q0 25-17.5 42.5T480-460ZM240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80H240Z" />
              </svg>
            </div>
            <div className="Dados_user">
              <div className="bm_Txt">Olá {localStorage.getItem('usuario')} seja bem vindo!</div>
              <div id="Userdados">{localStorage.getItem('SETOR')}</div>
            </div>
          </div>

          <div className="containerSelectMEnu">
            <div className="select_ct" onClick={handlNave_Orisol}>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M40-120v-80h880v80H40Zm120-120q-33 0-56.5-23.5T80-320v-440q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v440q0 33-23.5 56.5T800-240H160Zm0-80h640v-440H160v440Zm0 0v-440 440Z" />
                </svg>
              </div>
              <div className="NameDeficao">
                <h2>ORISOL</h2>
                <span>Castanhal 4.0</span>
              </div>
            </div>
            <div className="select_ct">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="m740-333-56-56 179-179 57 56-180 179Zm-84-85-57-56 123-123-170-170-123 123-57-57 180-179 283 283-179 179ZM791-56 553-293 420-160H40v-105q0-26 14.5-46T92-340q17-7 37.5-17t41.5-24l55 55q6 6 14 6t14-6q6-6 6-14t-6-14l-51-52 15.5-15.5Q226-429 234-438l51 51q6 6 14 6t14-6q6-6 6-14t-6-14l-56-56q6-11 10.5-22t7.5-23l68 69q6 6 14 6t14-6q6-6 6.5-14t-5.5-14L56-791l56-57 735 736-56 56ZM122-240h265l110-110-67-67-133 133q-23 23-55 22.5T184-284l-8-7q-14 6-27 12.5T122-266v26Zm308-177Zm107-119Z" />
                </svg>
              </div>
              <div className="NameDeficao">
                <h2>PESPONTO</h2>
                <span>Corte Pesponto 4.0</span>
              </div>
            </div>
            <div className="select_ct">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm200-500 54-18 16-54q-32-48-77-82.5T574-786l-54 38v56l160 112Zm-400 0 160-112v-56l-54-38q-54 17-99 51.5T210-652l16 54 54 18Zm-42 308 46-4 30-54-58-174-56-20-40 30q0 65 18 118.5T238-272Zm242 112q26 0 51-4t49-12l28-60-26-44H378l-26 44 28 60q24 8 49 12t51 4Zm-90-200h180l56-160-146-102-144 102 54 160Zm332 88q42-50 60-103.5T800-494l-40-28-56 18-58 174 30 54 46 4Z" />
                </svg>
              </div>
              <div className="NameDeficao">
                <h2>INJETORA</h2>
                <span>INJEÇÃO 4.0</span>
              </div>
            </div>
          </div>
       
          <div style={{
            width:'100%', boxSizing:'border-box',display:'flex',justifyContent: 'center',
          }}>
          <div  onClick={()=>handleClickOpenParametors()} style={{
            width:'50%',
            margin:'5px',
            fontSize:'15px',
            padding:'15px',
            backgroundColor:'white',
            borderRadius:'5px',
            height:'100px',
            display:'flex',flexDirection:'column',position:'relative',
            maxWidth:'250px',
            cursor:'pointer',boxSizing:'border-box'
          }}>
            <div>
            <svg style={{fill:'rgb(100,250,80)'}} xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 -960 960 960" width="35"><path d="m234-480-12-60q-12-5-22.5-10.5T178-564l-58 18-40-68 46-40q-2-13-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T222-820l12-60h80l12 60q12 5 22.5 10.5T370-796l58-18 40 68-46 40q2 13 2 26t-2 26l46 40-40 68-58-18q-11 8-21.5 13.5T326-540l-12 60h-80Zm40-120q33 0 56.5-23.5T354-680q0-33-23.5-56.5T274-760q-33 0-56.5 23.5T194-680q0 33 23.5 56.5T274-600ZM592-40l-18-84q-17-6-31.5-14.5T514-158l-80 26-56-96 64-56q-2-18-2-36t2-36l-64-56 56-96 80 26q14-11 28.5-19.5T574-516l18-84h112l18 84q17 6 31.5 14.5T782-482l80-26 56 96-64 56q2 18 2 36t-2 36l64 56-56 96-80-26q-14 11-28.5 19.5T722-124l-18 84H592Zm56-160q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"/></svg>
            </div>
            <div style={{position:'absolute',bottom:'15px'}}> Parametros maquinas</div>
          </div>

          <div style={{
            margin:'5px',
            width:'50%',boxSizing:'border-box',
            padding:'15px',
            backgroundColor:'white',
            borderRadius:'5px',
            height:'100px',
            display:'flex',flexDirection:'column',position:'relative',
            maxWidth:'250px',
            cursor:'pointer',
          }}>
            <div>
            <svg style={{fill:'rgb(100,250,80)'}} xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M520-120q-74 0-138.5-27.5T268-223l57-57q38 37 88 58.5T520-200q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198v7l73-73 57 56-170 170L30-490l57-56 73 74v-8q0-75 28.5-140.5t77-114q48.5-48.5 114-77T520-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T880-480q0 150-105 255T520-120Zm-80-200q-17 0-28.5-11.5T400-360v-120q0-17 11.5-28.5T440-520v-40q0-33 23.5-56.5T520-640q33 0 56.5 23.5T600-560v40q17 0 28.5 11.5T640-480v120q0 17-11.5 28.5T600-320H440Zm40-200h80v-40q0-17-11.5-28.5T520-600q-17 0-28.5 11.5T480-560v40Z"/></svg>
            </div>
            <div style={{position:'absolute',bottom:'15px'}}> Desenvolvimento</div>
          </div>

          </div>
          
        </div>
      </div>
    </>
  );
}

var ComponentePrmaquinas = ({prOpen, setprOepn})=>{
  const [clickClose ,setClickclosed] = useState(true)
  const handleClickcloseParametors = () => {
    setClickclosed(false)
    setTimeout(() => {
      setprOepn(false)
    }, 200);
    
  }

  return(
    <>
      <div className={`preMaquinas ${clickClose?'prOpen':'prClosed'}`}>
        <div className={`dadosPr ${clickClose?'dadosPrOpen':'dadosPrClosed'}`}>

        <div style={{width:'100%', boxSizing:'border-box',
          border:'1px solid',position:'relative', }}>
          <div style={{fontSize:'20px', fontWeight:'700'}}>  Parametros Orisol</div>
          <div onClick={()=>handleClickcloseParametors()} style={{position:'absolute',top:'0',right:'5px' }}>
          <svg 
          xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
          </div >
        
        </div>
        
        </div>
      </div>
    </>
  )
}