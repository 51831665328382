import  './Preventiva.css';
import React from 'react';
import { useEffect } from 'react';




export default function MenuPreventiva ({ setmenu }){
useEffect(()=>{
    setmenu(true);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);


    return(
        <>
        <div  style={{width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.85)', 
    position:'fixed', zIndex:'1', boxSizing:'border-box',
    display:'flex', justifyContent:'center', alignItems:'center'}}>

<div style={{ width: '100%', margin: '15px', padding: '15px', borderRadius: '15px', backgroundColor: 'white' }}>
    <div style={{ fontSize: "18px", fontWeight: '700' }}>Olá {localStorage.getItem('usuario')} 👋</div>
    <br></br>
    <span>Esta será uma área específica para preventivas, onde você terá uma visão geral de todas as preventivas pendentes, podendo também ter acesso a todas as ações de cada mecânico. 🛠️

        <br></br><br></br>Em breve estará disponível. 🕒
    </span>
</div>

    </div>
            <div className='prevContainer01'>
            <div  className='prevContainer02'></div>

            <div className='prevContainerPrincipal01'>

               <section className='Prev_Sesion01'>
                 <div style={{
                    width:'50%',
                    display:'flex',
                    flexDirection:'column',
                 }}>
                    <div>
                        Manutenção
                    </div>
                    <div style={{
                        fontWeight:'800',
                        fontSize:'20px',
                    }}>
                       Desenvolvimento
                    </div>
                 </div>

                 <div style={{
                    width:'50%',
                    display:'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                 }}></div>
               </section>
               <section style={{
                width:'100%', 
                fontWeight:'600',
                marginBottom: '20px',
                display:'flex',
                justifyContent: 'center',
                alignItems: 'center',
                }}>
               <div style={{
                    width:'100%',
                    display:'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                 }}>
                    <div style={{
                   width:'30%',
                    display:'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderBottom:'5px solid red',
                    padding:'5px',boxSizing:'border-box'
                    }}>
                       Usuário
                    </div >
                    <div style={{
                    width:'50%',
                    display:'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding:'5px',boxSizing:'border-box'
                    }}>
                       Geral
                    </div>
                 </div>
               </section>

               <ComponenteUser/>



     

            </div>
            </div>
        
        </>
    )
}

const ComponenteUser = ()=>{
    
      
    return(
        <>
         <section className='ResumoPrev'>
                <div className='containerResumoPrev'>
                    <div><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m40-120 440-760 440 760H40Zm440-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Z"/></svg></div>
                    <div>08</div>
                    <div>Total Pendente</div>
                </div>
                <div className='containerResumoPrev'>
                    <div><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m40-120 440-760 440 760H40Zm440-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Z"/></svg></div>
                    <div>10</div>
                    <div>Total Atrasada</div>
                </div>
                <div className='containerResumoPrev'>
                    <div><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m40-120 440-760 440 760H40Zm440-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Z"/></svg></div>
                    <div>08</div>
                    <div>Total Concluido</div>
                </div>
           </section>

        <section  style={{
                width:'100%', 
                fontWeight:'600',
                marginBottom:'15px',
                }} className='Prev_Sesion01'>
                 <div>Preventivas para executar hoje</div>

               </section>
               
               
             
                 <section style={{margin:'0px'}} className='Prev_sesionCartao'>
         
                 <div className='circuloDesigner'></div>
  
                  <div style={{
                  zIndex:'0',
                  fontWeight:'700',
                  fontSize:'20px',
                  }}>MAQ-01</div>
  
                  <div style={{
                      zIndex:'0',
                      fontSize:'clamp(15px, 1.8vw, 18px)',
                      marginTop:'5px',
                  }}>Limpeza de lubrificação</div>
                  
                  <div style={{
                      zIndex:'0',
                      fontWeight:'300',
                      fontSize:'clamp(10px, 1.8vw, 13px)',
                      marginTop:'5px',
                  }}>LUBRIFICAR GUIAS LINEARES, FUSOS E ROLAMENTOS DO EIXO X E Y, ROLAMENTO INFERIOR, GUIAS E ROLAMENTOS LATERAIS DO CARREGADOR.  REF. AO PLANO: 73272</div>
                 
                 <div style={{

                      fontSize:'clamp(15px, 1.8vw, 18px)',
                      marginTop:'10px',
                  }}>Progresso desta preventiva</div>
                  <div style={{
                      zIndex:'0',
                      marginTop:'5px',
                      width:'100%',
                      height:'15px',
                      borderRadius:'15px',
                      backgroundColor:'rgb(200,200,200)',
                      position:'relative',overflow:'hidden',
                      display:'flex',
                  }}>
                      <div style={{
                      zIndex:'1',
                      width:'50%',
                      height:'15px',
                      borderRadius:'15px',
                      backgroundColor:'rgb(255,0,0)',
                      position:'relative',
                  }} >
  
                      </div>
                  </div>
  
                  <div style={{
                      marginTop:'15px',
                      zIndex:'0', display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      marginBottom:'0px',
                  }} className='Prev_Sesion01'>
                      <div style={{width:'50%'}}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg></div>
                      <div style={{display:'flex',width:'100%', justifyContent:'end'}}>
                          <button className='btnIniciarPrevs'>Iniciar inspeção</button>
                          
                      </div>
                  </div>
                 
                 </section>
                             

               <section>
                
               </section>
        </>
    )
}
