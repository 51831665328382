
  import React from "react";
  import { Doughnut } from "react-chartjs-2";
  import { Chart, ArcElement } from "chart.js";
  
  Chart.register(ArcElement);
  
  
  const DoughnutChart = ({ graficoTodasManutencao }) => {
    const corVerde = ["rgb(13, 224, 112)", "rgba(30,30,30,0.2)"];
 
    const data = {
      labels: [],
      datasets: [
        {
          label: "TipoOs",
          data: graficoTodasManutencao,
          backgroundColor: ["rgb(10, 140, 240)","rgb(247, 71, 71)",corVerde,'rgba(0,0,0,0.15']
        },
      ],
    };
  
    const options = {
      cutout: "85%", // Ajuste este valor para alterar a espessura do gráfico
    };
  
    return <Doughnut data={data} options={options} />;
  };
  
  export default DoughnutChart;