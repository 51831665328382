import "./Adduser.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"
axios.defaults.withCredentials = true;
const serverid = ''
export default function AddUser() {
  const NaveGate = useNavigate();
  const VoltarLogin = () => {
    NaveGate("/");
  };

  var [user, setUser] = useState("");
  var [psw, setPsw] = useState("");
  var [Turno, setTurno] = useState("");
  var [Setor, setSetor] = useState("");
  var [cracha, setCracha] = useState("");
  
  const [checkboxes, setCheckboxes] = useState({
    iniciarManutencao: false,
    modificarProducao: false,
    acessoAdm:false,
    modificarGabarito:false,
    modificarOrdem:false,
  });

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: !prevCheckboxes[checkboxName],
    }));
  };

  const dadosUser={
    user,
    psw,
    Turno,
    cracha,
    checkboxes,
    Setor
  }

  const handleTurno = (e) => {
    setTurno(e.target.value);
  };

  const handleSetor = (e) => {
    setSetor(e.target.value);
  };

  const handleCracha = (e) => {
    setCracha(e.target.value);
  };

  const handlePsw = (e) => {
    setPsw(e.target.value);
  };

  const handleHover = (e) => {
    let userTop = e.target.value;
    userTop = userTop.toUpperCase();
    setUser(userTop);
  };
  return (
    <>
      <div className="Container_Black">
        <div className="container_dadosUser">
          <div className="container_btnAdd">
            <button className="exit_addUser" onClick={VoltarLogin}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </button>
          </div>
          <div className="Container_Icon">
            <div className="icon_Circulo_container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="50"
                viewBox="0 -960 960 960"
                width="50"
              >
                <path d="M720-400v-120H600v-80h120v-120h80v120h120v80H800v120h-80Zm-360-80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm80-80h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0-80Zm0 400Z" />
              </svg>
            </div>
          </div>

          <div className="ContainerForm">
{/*             <div className="Select_Type_Container">
              <div className="Selct_Type">
                <div className="select_container">
                  <button className="adduser_select">Adicionar</button>
                </div>
                <div className="select_container">
                  <button className="Editar_User" onClick={clickEdit}>
                    Editar Usuario
                  </button>
                </div>
              </div>
            </div> */}

            <form className="Form_Conainer">
              <fieldset className="fildeset_User">
                <legend>
                  <h3>Dados do Usuário</h3>
                </legend>
                <div className="inputs_class">
                  <label className={`${user ? "topHover" : "topNormal"}`}>
                    Usuario
                  </label>
                  <input
                    value={user}
                    type="text"
                    onChange={handleHover}
                  ></input>
                </div>

                <div className="inputs_class">
                  <label className={`${psw ? "Pswtop" : "Pswnormal"}`}>
                    Senha
                  </label>
                  <input
                    value={psw}
                    type="password"
                    onChange={handlePsw}
                  ></input>
                </div>

                <div className="inputs_class">
                  <label className={`${cracha ? "Crachatop" : "crachaNormal"}`}>
                    Crachá
                  </label>
                  <input
                    value={cracha}
                    type="text"
                    onChange={handleCracha}
                  ></input>
                </div>

                <div className="inputs_class">
                  <label className={`${Turno ? "TrTop" : "TrNormal"}`}>
                    Turno
                  </label>
                  <select value={Turno} onChange={handleTurno}>
                    <option> </option>
                    <option>TURNO 1</option>
                    <option>TURNO 2</option>
                    <option>TURNO 3</option>
                    <option>Normal</option>
                  </select>
                </div>

                <div className="inputs_class">
                  <label className={`${Setor ? "TrTop" : "TrNormal"}`}>
                    Cargo
                  </label>
                  <select value={Setor} onChange={handleSetor}>
                    <option> </option>
                    <option> Mecanico </option>
                    <option> Aux. manutencao </option>
                    <option> Lider Psponto </option>
                    <option> Supervisor </option>
                    <option> Gerencia </option>
                    <option> Senior </option>
                    <option> Operador </option>
                    <option> Pcm </option>
                    <option> Administrativo </option>
                  </select>
                </div>

          
              </fieldset>

              <fieldset style={{fontSize:'clamp(12px,1.8vw,15px)'}} className="fildeset_User second_Fildset">
                <legend>
                  <h3>Acesso User</h3>
                </legend>

                <div className="inputs_class2">
                  <div className="Pai_chekbox">
                    <label for="admin">Administrador</label>
                    <input type="checkbox" id="admin"
                    checked={checkboxes.acessoAdm}
                    onChange={() => handleCheckboxChange('acessoAdm')}
                    ></input>
                  </div>
                </div>

                <div className="inputs_class2">
                  <div className="Pai_chekbox">
                    <label for="Gb">Modificar Gabarito</label>
                    <input type="checkbox" id="Gb"
                    checked={checkboxes.modificarGabarito}
                    onChange={() => handleCheckboxChange('modificarGabarito')}
                    ></input>
                  </div>
                </div>

                <div className="inputs_class2">
                  <div className="Pai_chekbox">
                    <label for="Iniciar_Manut">Iniciar Manutencao</label>
                    <input type="checkbox" id="Iniciar_Manut"
                     checked={checkboxes.iniciarManutencao}
                     onChange={() => handleCheckboxChange('iniciarManutencao')}
                    ></input>
                  </div>
                </div>

                <div className="inputs_class2">
                  <div className="Pai_chekbox">
                    <label for="Mod_Producao">Modificar Produção</label>
                    <input type="checkbox" id="Mod_Producao"
                    checked={checkboxes.modificarProducao}
                    onChange={() => handleCheckboxChange('modificarProducao')}
                    ></input>
                  </div>
                </div>

                <div className="inputs_class2">
                  <div className="Pai_chekbox">
                    <label for="Mod_AddOrdem">Registrar Ordem</label>
                    <input type="checkbox" id="Mod_AddOrdem"
                    checked={checkboxes.modificarOrdem}
                    onChange={() => handleCheckboxChange('modificarOrdem')}
                    ></input>
                  </div>
                </div>

              </fieldset>

              <div className=" container_Button">
                <button id="RegistroUSer" className="button_Registro"
                onClick={(e) => addleAdduser(e,dadosUser)}
                >
                  Registrar Agora
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

async function addleAdduser(e,dadosUser) {
  e.preventDefault()
  
  try {
    const startBanco = await axios.post(serverid+'/auth/registroUSer',dadosUser)
    const resuLts = await startBanco.data
    alert(resuLts)
  } catch (error) {
    alert(error.response.data)
  }
}