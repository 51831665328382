import './AddPreventiva.css'
import { useEffect, useState } from 'react'
import MenuGeral from '../menuGeral/MenuGeral';
import axios from 'axios'
const serverid = ''

export default function ComponenteAddPreventiva({setmenu}){
    setmenu(true)


    const [clickMg, setclickMg]=useState(false)
    const [mGeral, setmnGeral]=useState(false)
    const handleMenuGeral =() => {
      
      if (mGeral){
        setmnGeral(false)
      }else{
        setclickMg(true)
        setmnGeral(true)
        setTimeout(() => {
          setclickMg(false)
        }, 280);
      }
    }
    

    let [clickTf, setclickTf01] = useState(true)
    
    const clickSelectTf = ()=> {
        if(clickTf){
            setclickTf01(false)
        }else{
            setclickTf01(true) 
        }
    }  
    

    return(
        <>
        {mGeral&&<MenuGeral mGeral={mGeral} setmnGeral={setmnGeral}/>}
          <div className="addPrev_container">
                <div className="addPrev_container3">
                <div style={{position:'relative'}} className="Bemvindo_user">
            <div className="svg_user">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M440-360h80l6-50q8-3 14.5-7t11.5-9l46 20 40-68-40-30q2-8 2-16t-2-16l40-30-40-68-46 20q-5-5-11.5-9t-14.5-7l-6-50h-80l-6 50q-8 3-14.5 7t-11.5 9l-46-20-40 68 40 30q-2 8-2 16t2 16l-40 30 40 68 46-20q5 5 11.5 9t14.5 7l6 50Zm40-100q-25 0-42.5-17.5T420-520q0-25 17.5-42.5T480-580q25 0 42.5 17.5T540-520q0 25-17.5 42.5T480-460ZM240-80v-172q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v80H240Z" />
              </svg>
            </div>
            <div style={{color:'white'}} className="Dados_user">
              <div style={{color:'white'}} className="bm_Txt">Usuário: {localStorage.getItem('usuario')}</div>
              <div id="Userdados">{localStorage.getItem('SETOR')}</div>
            </div>

            <div className='Mg_menu_Click_style' style={{position:'absolute', right:'1px'}}
            onClick={()=>handleMenuGeral()}
            >
              <div className={`${clickMg?'Mg_menu_Click_style_open':''}`}></div>
              <svg style={{fill:'white'}}  xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="40"><path d="M480.571-122q-29.571 0-50.57-20.884-21-20.884-21-50.21 0-28.839 20.835-50.372t50.093-21.533q30.071 0 50.571 21.503 20.499 21.503 20.499 50.499 0 28.997-20.429 49.997t-49.999 21Zm0-287.001q-29.571 0-50.57-20.835-21-20.835-21-50.093 0-30.071 20.835-50.571 20.835-20.499 50.093-20.499 30.071 0 50.571 20.429 20.499 20.429 20.499 49.999 0 29.571-20.429 50.57-20.429 21-49.999 21Zm0-286q-29.571 0-50.57-21.212-21-21.213-21-51t20.835-50.62q20.835-20.833 50.093-20.833 30.071 0 50.571 20.927 20.499 20.928 20.499 50.715 0 29.787-20.429 50.905-20.429 21.118-49.999 21.118Z"/></svg>
            </div>
          </div>

          <div className='Add_arquivoPrev'>
            
            <div style={{background:'white', padding:'10px 8px', 
              borderRadius:'5px'}}>
              <h3 style={{margin:'0'}}>Info</h3>
              <span style={{color:'black', fontSize:'11px'}}className=''>Aqui você pode 
              selecionar um arquivo para distribuir as preventivas 
              de modo organizado.<br></br> O  arquivo suportado
              é .csv ou .xlsx </span>
            </div>
            
            <div className='select_Apont_PreTf'>
                        <div 
                        className={`apont_select_Tf ${clickTf?'select_Preventiva':''}`}
                        onClick={(e)=>clickSelectTf(e)}
                        >Distribuida</div>
                        <div 
                        className={`apont_select_Tf ${!clickTf?'select_Tarefa':''}`}
                        onClick={(e)=>clickSelectTf(e)}
                        >A distribuir</div>
                </div>

              {!clickTf&&<ComponenteDistribuir/>}
              {clickTf&&<ComponenteOrdensDistribuidas/>}

          </div> 
      </div>
      <div className='addPrev_container2'></div>
</div>
</>
)
}


const ComponenteDistribuir= ()=>{
  let [arquivoSelected, setArquivoSelected] = useState();
  let [jsonResult, setsonResult] = useState(false)
  const [nomeSelectArquivo, setNomeSelectArquivo] = useState('');
  let [nomeEnvio, setNomeenvio] = useState('Nenhum usuario');
  const [selectuser, setSelectuser]= useState(false)
  const [animationselcted, setanimationselcted]=useState(false)
  let[arrayPendente, setarrayPendente]=useState()
  const handleAbrirSelect= ()=>{
    setanimationselcted(true)
      setSelectuser(true)  
  }
  const handleFecharSelect= ()=>{
        setanimationselcted(false)
      
    setTimeout(() => {
      setSelectuser(false) 
    }, 600);
    
  }

    const handleSelecionarArquivo = async (e) => {
      setNomeSelectArquivo(e.target.files[0].name);
      setArquivoSelected(e.target.files[0]);
    };
    
    const [arq, setarq] = useState(false)
    useEffect(()=>{
      if(arq){

     
      const handleEnviarArquivo = async (e) => {
        try {
          const formData = new FormData();
          formData.append('arquivo', arquivoSelected);
        const dados = await axios.post(serverid+"/secaoPreventiva/AddPreventivas",formData);
        setarrayPendente(dados.data.PENDENTE);
        setsonResult(dados.data.TODASPREV);
          
        } catch (error) {
          console.log(error)
          alert(error.response.data)
        }
      };
      
       handleEnviarArquivo()
       
    }
    setarq(false)
    },[arq, arquivoSelected, arrayPendente])
    
    const handleEnviarArquivo = async (e) => {
      e.preventDefault();
      setarq(true)
    }
    
    useEffect(() => {
      if (arquivoSelected) {
  
        const formData = new FormData();
        formData.append('arquivo', arquivoSelected);
      } else {
        console.log('Nenhum arquivo selecionado.');
      }
    }, [arquivoSelected]);

    let [mecanicosDados2, setmecanicosDados2] = useState(false) 
let [idOS, setdOS] = useState(false)

useEffect(() => {
  if(idOS) {
    setdOS(false)
    const enviarMecanico = async () => {
      try {
        const json={MECANICO:nomeEnvio,ORDEM_SERVICO:idOS,DADOS_OS:jsonResult[idOS]}
        await axios.post(serverid+"/secaoPreventiva/RegistrarMecanico",json)

        const updatedJsonResult = { ...jsonResult };
        delete updatedJsonResult[idOS];
        setsonResult(updatedJsonResult);
       
      } catch (error) {
        console.log(error)
        alert(error.response.data)
       
      }
    }
    enviarMecanico()
    
  }
  
},[idOS, jsonResult, nomeEnvio])


const ComponenteMecanicosEnvio = () => { 
  let [mecanicosDados, setmecanicosDados] = useState(false)
  useEffect(() => {
    const handleuser = async (e) => {
      if(!mecanicosDados2){
        const dados = await axios.post(serverid+"/secaoPreventiva/userSelect_Mecanico_Preventivas")

         setmecanicosDados(dados.data)
         setmecanicosDados2(dados.data)
      }
    }
    handleuser()
  },[mecanicosDados])


const handleSelected_userPrev = (e) => {
  setNomeenvio(e.currentTarget.id)
}

  return (
  <>
    <div onClick={()=>{handleFecharSelect()}} className={`blackSelectUser ${animationselcted?'containerSelectuser_Prev_Open':'containerSelectuser_Prev_close'}`}>
      <div className='containerSelectuser_Prev'>
        <div style={{width:'100%', boxSizing:'border-box', fontSize:'15px', fontWeight:'600'}}>Selecione o mecanico para enviar as preventivas</div>
        {mecanicosDados2&&mecanicosDados2.map((value,index)=>(
          <div className='userHover_Select' onClick={(e)=>{handleSelected_userPrev(e)}} id={value} key={index} style={{backgroundColor:nomeEnvio === value?'rgba(12, 16, 39, 0.162)':'',position:'relative',fontSize:'14px', fontWeight:'600', width:'100%', margin:'0 auto', boxSizing:'border-box', borderBottom:'1px solid'
          ,padding:'10px 0', display:'flex', alignItems:'center', marginTop:'8px'}}>
            
            <svg style={{marginRight:'5px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400.808-491.501q-56.442 0-96.201-39.759-39.759-39.759-39.759-96.201 0-56.442 39.759-96.201 39.759-39.759 96.201-39.759 56.441 0 96.201 39.759 39.759 39.759 39.759 96.201 0 56.442-39.759 96.201-39.76 39.759-96.201 39.759Zm-292.73 293.307v-84.075q0-29.808 15.212-53.173 15.211-23.365 41.788-36.519 53.077-26.154 109.865-41.596 56.788-15.442 125.77-15.442h10.979q4.846 0 10.462 1.231-5.27 13.653-9.231 27.267-3.961 13.614-6.577 27.462h-5.538q-63.116 0-114.885 14.115-51.769 14.116-95.23 36.77-14.116 7.5-20.385 17.209-6.27 9.71-6.27 22.676v28.039H412.79q3.979 14.806 10.383 28.979 6.403 14.173 14.442 27.057H108.078Zm534.538 28.807-8.115-51.461q-14.238-4.231-27.293-11.327-13.054-7.096-24.439-17.712l-49.884 16.808-26.037-44.345 40.576-32.23q-4.615-15.539-4.615-31.424t4.615-31.423l-40.192-33.808 26.038-44.344 49.469 18q11.03-10.808 24.277-17.646 13.247-6.838 27.485-11.008l8.29-51.462h52.708l7.73 51.462q15.168 4.23 28.354 11.192 13.185 6.961 24.185 18.692l48.692-19.23 26.038 45.575-39.385 33.807q4.308 15.237 4.308 30.869t-4.308 30.748l39.769 32.23-26.037 44.345-49.077-16.808q-11.384 10.616-24.378 17.712-12.993 7.096-28.161 11.327l-7.906 51.461h-52.707Zm26.409-97.153q31.157 0 52.874-21.964 21.716-21.964 21.716-53.096 0-31.132-21.75-52.978-21.751-21.846-52.908-21.846-31.158 0-53.192 21.964-22.034 21.965-22.034 53.096 0 31.132 22.068 52.978t53.226 21.846ZM400.808-547.461q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm12 373.307Z"/></svg>
            <div onClick={(e,mecanicosDados2)=>{handleSelected_userPrev(e,mecanicosDados2)}} id={value}>{value}</div>
            {nomeEnvio === value&&<svg style={{position:'absolute', right:'2px', fill:'rgb(10,225,20)'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-305.655q72.807 0 123.576-50.769Q654.345-407.193 654.345-480q0-72.807-50.769-123.576Q552.807-654.345 480-654.345q-72.807 0-123.576 50.769Q305.655-552.807 305.655-480q0 72.807 50.769 123.576Q407.193-305.655 480-305.655Zm.092 197.577q-77.152 0-145.066-29.325-67.914-29.324-118.152-79.595-50.238-50.27-79.517-118.055-29.279-67.785-29.279-144.855 0-77.152 29.325-145.066 29.324-67.914 79.595-118.152 50.27-50.238 118.055-79.517 67.785-29.279 144.855-29.279 77.152 0 145.066 29.325 67.914 29.324 118.152 79.595 50.238 50.27 79.517 118.055 29.279 67.785 29.279 144.855 0 77.152-29.325 145.066-29.324 67.914-79.595 118.152-50.27 50.238-118.055 79.517-67.785 29.279-144.855 29.279Zm-.105-55.96q131.898 0 223.936-92.026 92.039-92.026 92.039-223.923 0-131.898-92.026-223.936-92.026-92.039-223.923-92.039-131.898 0-223.936 92.026-92.039 92.026-92.039 223.923 0 131.898 92.026 223.936 92.026 92.039 223.923 92.039ZM480-480Z"/></svg>}
            
            </div>
       
        ))}
        
      </div>
      
    </div>
  </>
)
}

return (
 
    <>
    {selectuser&&<ComponenteMecanicosEnvio/>}
<form className='Prev_AddArquivo' style={{width:'100%'
          , backgroundColor:'white',boxShadow:'0 0 5px rgba(0,0,0,0.2)',
          padding:'20px 15px', display:'block', borderRadius:'5px'
          ,boxSizing:'border-box', position:'relative', alignItems:'center', height:'',
          maxWidth:'70%', margin:'0 auto',marginTop:'20px'}}>
              <label for='exelFile'>Escolher arquivo
              </label>
              <input 
              style={{display:'none'}} 
              id='exelFile' 
              type='file'
              onChange={handleSelecionarArquivo}
              ></input>
              <div style={{marginTop:'10px', width:'100%'
            ,marginBottom:'10px'}}>
                <span id='ArquivoSelecionado'>
                  {`${nomeSelectArquivo?nomeSelectArquivo:'Nenhum arquivo!'}`}</span>
              </div>
              <div className='btnEnviar_File'>
              <button
              type='submit'
              onClick={handleEnviarArquivo}
              >Enviar</button>
              </div>
            </form>
          <div 
          style={{display:'flex',
          width:'100%',
          flexWrap:'wrap',
          boxSizing:'border-box',
          marginTop:'15px'
        }}
          >
        <div 
        style={{width:'100%', boxSizing:'border-box', padding:'5px 5px'
        ,margin:'5px 5px', display:'flex', alignItems:'center'
        }}>
          <svg onClick={()=>{handleAbrirSelect()}}  style={{cursor:'pointer',marginRight:'8px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path onClick={()=>{handleAbrirSelect()}} d="M442-285h312v-26q0-43.5-42.75-68.75T598-405q-70.5 0-113.25 25.25T442-311v26Zm156-160q31.763 0 54.381-22.619Q675-490.237 675-522t-22.619-54.381Q629.763-599 598-599t-54.381 22.619Q521-553.763 521-522t22.619 54.381Q566.237-445 598-445ZM165-170q-30.938 0-52.969-22.031Q90-214.062 90-245v-470q0-30.938 22.031-52.969Q134.062-790 165-790h240l75 75h315q30.938 0 52.969 22.031Q870-670.938 870-640v395q0 30.938-22.031 52.969Q825.938-170 795-170H165Zm0-75h630v-395H449l-75-75H165v470Zm0 0v-470 470Z"/></svg>
          <div style={{fontSize:'clamp(12px, 1.8vw,14px)'}}>Enviando para:</div> 
          <div style={{fontSize:'clamp(12px, 1.8vw,14px)',padding:'5px 30px', boxSizing:'border-box',display:'flex'
        , backgroundColor:nomeEnvio==='Nenhum usuario'?'rgba(255,0,0,0.2)':'rgba(0,255,0,0.2)',justifyContent:'center',alignItems:'center',borderRadius:'25px', marginLeft:'10px'}}>{nomeEnvio}</div>
        </div>

        {jsonResult&&<ComponentesTodasOSPreventivas jsonResult={jsonResult} arrayPendente={arrayPendente} setdOS={setdOS}/>}


</div>        
            
    
    </>
  )
}

const ComponentesTodasOSPreventivas = ({jsonResult,arrayPendente,setdOS})=>{
  const handleclick_District = (e) => {
  
    setdOS(e.target.id) 
  }
  
  return(
    
    <>
    {Object.entries(jsonResult).map(([id, obj], index) => (
    !arrayPendente.includes(id) && (    
      <div style={{background: 'white', minWidth:'250px', flex:'1', margin:'5px'}} key={index} className='TodasPrev_e_Tarefas1'>  
      <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
      <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/></svg>
      <span style={{fontWeight:'800',fontSize:'clamp(12px,1.8vw,14px'}}>{obj.MAQUINA}</span>
      <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M187.523-205.538q-25.768 0-43.569-17.801-17.8-17.8-17.8-43.586v-426.15q0-25.786 17.8-43.586 17.801-17.801 43.569-17.801h584.954q25.768 0 43.569 17.801 17.8 17.8 17.8 43.586v426.15q0 25.786-17.8 43.586-17.801 17.801-43.569 17.801H187.523Zm81.092-160.308h30.924v-228.308h-26.143l-64.165 46.308 17.231 24.615 42.153-29.077v186.462Zm121.231 0h144.923V-396H434.615l.154-3.538q18.539-17.27 32.577-31.616 14.039-14.346 28.962-30.538 16.577-18.462 23.981-34.517 7.403-16.055 7.403-35.176 0-27.625-19.692-45.197-19.692-17.572-50-17.572-21.423 0-40.577 12.635-19.154 12.634-28.038 33.057l30.153 12.308q5.654-12.5 16.155-19.942 10.501-7.442 22.307-7.442 17.308 0 27.962 9.153 10.653 9.154 10.653 25 0 12.154-4.884 21.847-4.885 9.692-18.654 24.615-21.346 23.654-39.615 41.654-18.27 18-43.616 42.155v33.268Zm288.443 0q31.365 0 51.153-18.677 19.789-18.678 19.789-48.1 0-21.694-11.154-35.274-11.154-13.579-31.462-18.183V-488q17.077-3.538 25.847-15.269 8.769-11.731 8.769-31.039 0-26.564-17.985-43.205t-47.185-16.641q-27.369 0-43.214 14.322-15.845 14.321-23.309 27.37l28 12.308q4.77-11.154 15.308-17.5Q663.385-564 676.154-564q15.308 0 24.961 8.654 9.654 8.654 9.654 21.961 0 15.539-11.538 25.077-11.539 9.539-28.827 9.539h-13.019v29.538h14.68q22.397 0 34.55 9.154 12.154 9.154 12.154 25.462 0 15.307-12.154 26.73-12.153 11.423-28.461 11.423-17 0-27.923-7.471-10.923-7.472-18.693-24.375l-28 10.77q8.654 24.269 28.13 37.98 19.476 13.712 46.621 13.712Z"/></svg>
      <span style={{fontWeight:'400', fontSize:'clamp(12px,1.8vw,14px'}}>{id} </span>
      <svg style={{marginRight:'5px', marginLeft:'8px', minWidth:'2%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M480.053-394.462q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm320 0q-12.322 0-21.265-8.889-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.942-21.211 8.942Zm-160 153.847q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm-160 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943Zm320 0q-12.322 0-21.265-8.89-8.942-8.89-8.942-21.212t8.89-21.264q8.889-8.942 21.211-8.942t21.265 8.889q8.942 8.89 8.942 21.212t-8.89 21.264q-8.889 8.943-21.211 8.943ZM226.895-126.154q-25.756 0-43.556-17.8-17.801-17.801-17.801-43.556v-506.21q0-25.756 17.801-43.557 17.8-17.8 43.738-17.8h73.846v-89.231h40v89.231h281.231v-89.231h36.923v89.231h73.846q25.938 0 43.738 17.8 17.801 17.801 17.801 43.557v506.21q0 25.755-17.801 43.556-17.8 17.8-43.556 17.8h-506.21Zm.182-36.923h505.846q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-346.462H202.462v346.462q0 9.231 7.692 16.923 7.692 7.692 16.923 7.692Z"/></svg>
      <span style={{fontWeight:'400', fontSize:'clamp(12px,1.8vw,14px'}}>{obj.DATA_OS}</span>
      </div>

      <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
      <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
      <span style={{fontWeight:'400',fontSize:'clamp(10px,1.8vw,12px'}}>{obj.DESCRICAO}</span>
      </div>

      <div style={{display:'flex', alignItems:'center', marginBottom:'10px'}}>
      <svg style={{marginRight:'5px', minWidth:'7%'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
      <span style={{fontWeight:'400',fontSize:'clamp(10px,1.8vw,12px'}}>{obj.DESCRICAO_TAREFA}</span>
      </div>

      <div style={{position:'relative',display:'flex', alignItems:'flex-end',justifyContent:'flex-end', marginBottom:'10px'}}>
      <svg  id={id} onClick={(e)=>{handleclick_District(e)}}   style={{cursor:'pointer',fill:'rgb(10,255,50)'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path id={id} onClick={(e)=>{handleclick_District(e)}}  d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"/></svg>           
      </div>

      </div>
    )
))}
    </>
  )
}


/* Componentes das preventivas distribuidas */

const ComponenteOrdensDistribuidas=() => {
  const [userClick,setUserClick] = useState('')
const handleclick =(e) => {
  const user = e.currentTarget.id
  if (user === userClick){
    setUserClick('')
  }else{
    setUserClick(user)
  }
}
let [distribuiDa, setdistribuida] = useState()
useEffect(()=>{
  const buscarDados = async () => {
    try {
      const dbdados = await axios.post(serverid+'/secaoPreventiva/buscarPreventivas')
      const resultado = dbdados.data
      setdistribuida(resultado)
    
    } catch (error) {
      console.error(error)
      alert(error.response.data)
    }
  }

  buscarDados()

},[])


  return(
    <>
      <div
      style={{
        width: '100%',
        margin:'0 auto',
        marginTop: '20px',
        boxSizing: 'border-box',
        color:'#212121'
      }}
      >
{distribuiDa && Object.keys(distribuiDa).map((usuario) => (
  <div key={usuario} style={{ marginBottom: '10px' }}>
        <div
         onClick={(e)=>{handleclick(e)}}
         id={usuario}
        style={{
          width: '100%',
          borderRadius:'5px',
          backgroundColor:`${usuario === userClick?'rgba(230,230,230)':'white'}`,
          border:`${usuario === userClick?'8px solid rgba(20,150,250,0.3)':'white'}`,
          boxShadow:'0 0 5px rgba(0,0,0,0.2)',
          display:'flex',
          position:'relative',
          padding:'10px 10px',
          boxSizing:'border-box',
          alignItems: 'center',
          transition: 'all 0.15s linear'
        }}
        >
          <div
          style={{
            width: '60%',
            display: 'block',
            alignItems:'center',
          }}
          >
            
          <div
          style={{
          display:"flex",
          width:"100%",
          alignItems:'center',
          marginBottom: '10px',
          }}
          
          >


            <svg style={{marginRight:'8px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-240q-56 0-107 17.5T280-170v10h400v-10q-42-35-93-52.5T480-240Zm-280 34q54-53 125.5-83.5T480-320q83 0 154.5 30.5T760-206v-514H200v514Zm280-194q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm0-80q25 0 42.5-17.5T540-540q0-25-17.5-42.5T480-600q-25 0-42.5 17.5T420-540q0 25 17.5 42.5T480-480ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm280-460Zm0 380h200-400 200Z"/></svg>
            <div
              style={{
                fontSize: "clamp(14px,1.8vw,17px)",
                fontWeight: "800",
              }}
            >{usuario}
            </div>

            </div>

            <div
            style={{
              fontSize: "clamp(13px,1.8vw,15px)",
            }}
            >
              Ordens de serviço preventiva não finalizadas! 
            </div>
            
          </div>

          <div
          style={{
            width: '25%',
            display: 'flex',
            alignItems:'center',
            justifyContent: 'center',

          }}
          >
            <div
            style={{
              width:'45px',
              height:'45px',
              borderRadius: '100%',
              backgroundColor:'rgba(0,0,0,0.15)',
              display: 'flex',
              justifyContent:'center',
              alignItems:'center',
              fontWeight:'600',
              fontSize:'clamp(19px, 1.8vw, 20px)'
            }}
            >
                {!distribuiDa[usuario]['QTD_OS']?0:distribuiDa[usuario]['QTD_OS']}
            </div>
          </div>

          <div
          style={{
            width: '25%',
            display: 'flex',
            alignContent: 'center',
            justifyContent:'center',
          }}
          >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>
          </div>

        </div>
        {userClick === usuario && (
              <ComponenteAmostraTodasOs
                userClick={userClick} distribuiDa={distribuiDa}
                
              />
            )}
        </div>
       ))}
        
        
      </div>
      
    </>
    
  )

}

const ComponenteAmostraTodasOs = ({userClick,distribuiDa})=>{
  return(
    <>
      <div
      className={`${userClick&&'dadosOsPendente'}`}
      style={{
        width: '100%',
        boxSizing:'border-box',
        display: 'inline-flex',
        alignItems: 'stretch',
        flexDirection: 'row',
        marginBottom: '20px',
        boxShadow:'0 5px 5px rgba(0,0,0,0.3)',
      }}
      >
      {distribuiDa && Object.keys(distribuiDa).map((usuario) => (
        usuario === userClick && (
        <div
        style={{
          boxSizing:'border-box',
          display: 'inline-flex',
          alignItems: 'stretch',
          flexDirection: 'row',
          borderRadius: '5px',
          width: '100%',
          flexWrap: 'wrap',
          
        }}
        >
        {Object.keys(distribuiDa[usuario]).map((Os) => (
          Os !== 'QTD_OS' && (
        <div
        style={{
          flex:'1',
          minWidth:'350px',
          margin:'10px 5px',
          padding:'10px 10px',
          boxShadow:'0 0 5px rgba(0,0,0,0.3)',
          boxSizing:'border-box',
          display: 'block',
          borderRadius: '5px',
          marginBottom: '10px',
          backgroundColor:`${distribuiDa[usuario][Os]['SITUACAO']==='ATRASADO'?'rgba(255,20,80,0.2)':'white'}`
        }}
        >
        <div
        style={{
        width: '100%',
        boxSizing:'border-box',
        display:'flex',
        alignItems:'center'
        }}
        >
          <div
          style={{
            width: '35%',
            display: 'flex',
            alignItems:'center',

          }}
          >
          <svg style={{marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/></svg>
          <div
          style={{
            fontWeight: "bold",
            fontSize: "13px"
          }}
          >{usuario === userClick && distribuiDa[usuario][Os]['MAQUINA']}</div>
         </div>

         <div
          style={{
            width: '35%',
            display: 'flex',
            alignItems:'center',

          }}
          >
         <svg style={{marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm100-200h46v-240h-36l-70 50 24 36 36-26v180Zm124 0h156v-40h-94l-2-2q21-20 34.5-34t21.5-22q18-18 27-36t9-38q0-29-22-48.5T458-600q-26 0-47 15t-29 39l40 16q5-13 14.5-20.5T458-558q15 0 24.5 8t9.5 20q0 11-4 20.5T470-486l-32 32-54 54v40Zm296 0q36 0 58-20t22-52q0-18-10-32t-28-22v-2q14-8 22-20.5t8-29.5q0-27-21-44.5T678-600q-25 0-46.5 14.5T604-550l40 16q4-12 13-19t21-7q13 0 21.5 7.5T708-534q0 14-10 22t-26 8h-18v40h20q20 0 31 8t11 22q0 13-11 22.5t-25 9.5q-17 0-26-7.5T638-436l-40 16q7 29 28.5 44.5T680-360ZM160-240h640v-480H160v480Zm0 0v-480 480Z"/></svg>
          <div
          style={{
            fontWeight: "400",
            fontSize: "13px"
          }}
          >{usuario === userClick && Os}</div>
        </div>

        <div
          style={{
            width: '40%',
            display: 'flex',
            alignItems:'center',

          }}
          >
        <svg style={{marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z"/></svg>
          <div
          style={{
            fontWeight: "400",
            fontSize: "13px"
          }}
          >{usuario === userClick && distribuiDa[usuario][Os]['DATA_OS']}</div>
        </div>
          

        </div>

        <div
        style={{
        width: '100%',
        boxSizing:'border-box',
        display:'flex',
        alignItems:'center',
        marginTop: '10px',
        }}
        >
          <div
          style={{
            width: '40%',
            display: 'flex',
            alignItems:'center',

          }}
          >
       <svg style={{marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M200-800v640-640 200-200Zm80 400h147q11-23 25.5-43t32.5-37H280v80Zm0 160h123q-3-20-3-40t3-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v92q-19-6-39-9t-41-3v-40H480v-200H200v640h227q11 23 25.5 43T485-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Zm0 320q11 0 18.5-7.5T706-186q0-11-7.5-18.5T680-212q-11 0-18.5 7.5T654-186q0 11 7.5 18.5T680-160Zm-18-76h36v-10q0-11 6-19.5t14-16.5q14-12 22-23t8-31q0-29-19-46.5T680-400q-23 0-41.5 13.5T612-350l32 14q3-12 12.5-21t23.5-9q15 0 23.5 7.5T712-336q0 11-6 18.5T692-302q-6 6-12.5 12T668-276q-3 6-4.5 12t-1.5 14v14Z"/></svg>
          <div
          style={{
            fontWeight: "400",
            fontSize: "13px"
          }}
          >{usuario === userClick && distribuiDa[usuario][Os]['SITUACAO']}</div>
        </div>


        

        </div>


        <div
        style={{
        width: '100%',
        boxSizing:'border-box',
        display:'flex',
        alignItems:'center',
        marginTop: '10px',
        }}
        >
          <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems:'center',

          }}
          >
       <svg style={{ minWidth:'20px',marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M200-800v640-640 200-200Zm80 400h147q11-23 25.5-43t32.5-37H280v80Zm0 160h123q-3-20-3-40t3-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v92q-19-6-39-9t-41-3v-40H480v-200H200v640h227q11 23 25.5 43T485-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Zm0 320q11 0 18.5-7.5T706-186q0-11-7.5-18.5T680-212q-11 0-18.5 7.5T654-186q0 11 7.5 18.5T680-160Zm-18-76h36v-10q0-11 6-19.5t14-16.5q14-12 22-23t8-31q0-29-19-46.5T680-400q-23 0-41.5 13.5T612-350l32 14q3-12 12.5-21t23.5-9q15 0 23.5 7.5T712-336q0 11-6 18.5T692-302q-6 6-12.5 12T668-276q-3 6-4.5 12t-1.5 14v14Z"/></svg>
          <div
          style={{
            fontWeight: "400",
            fontSize: "11px"
          }}
          >{usuario === userClick && distribuiDa[usuario][Os]['DESCRICAO']}</div>
        </div>

        </div>

        <div
        style={{
        width: '100%',
        boxSizing:'border-box',
        display:'flex',
        alignItems:'center',
        marginTop: '10px',
        }}
        >
          <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems:'center',

          }}
          >
       <svg style={{ minWidth:'20px',marginRight:'7px'}} xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18"><path d="M200-800v640-640 200-200Zm80 400h147q11-23 25.5-43t32.5-37H280v80Zm0 160h123q-3-20-3-40t3-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v92q-19-6-39-9t-41-3v-40H480v-200H200v640h227q11 23 25.5 43T485-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Zm0 320q11 0 18.5-7.5T706-186q0-11-7.5-18.5T680-212q-11 0-18.5 7.5T654-186q0 11 7.5 18.5T680-160Zm-18-76h36v-10q0-11 6-19.5t14-16.5q14-12 22-23t8-31q0-29-19-46.5T680-400q-23 0-41.5 13.5T612-350l32 14q3-12 12.5-21t23.5-9q15 0 23.5 7.5T712-336q0 11-6 18.5T692-302q-6 6-12.5 12T668-276q-3 6-4.5 12t-1.5 14v14Z"/></svg>
          <div
          style={{
            fontWeight: "400",
            fontSize: "10px"
          }}
          >{usuario === userClick && distribuiDa[usuario][Os]['DESCRICAO_TAREFA']}</div>
        </div>

        </div>

        <div
        style={{
        width: '100%',
        boxSizing:'border-box',
        display:'flex',
        alignItems:'center',
        marginTop: '10px',
        }}
        >
          <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',

          }}
          >
       <svg style={{fill:'rgba(20,120,255)'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-280 80-520l240-240 57 56-184 184 184 184-57 56Zm480 80v-160q0-50-35-85t-85-35H433l144 144-57 56-240-240 240-240 57 56-144 144h247q83 0 141.5 58.5T880-360v160h-80Z"/></svg>
          
        </div>

        </div>

        </div>
          )
        ))}
      </div>
        )
      ))}
      </div>
    </>
  )
}