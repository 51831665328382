import { useState } from 'react'
import './MenuGeral.css';
import { useNavigate } from "react-router-dom";
/* import { useNavigate } from "react-router-dom";
import { useState } from "react"; */


export default function MenuGeral ({mGeral, setmnGeral,setConfigTurno, clickConfigTurno}){
    const NaveOrisol = useNavigate();
    const handlNave_Home = () => {
        NaveOrisol("/Home");
      };
      const handlNave_Apontamento = () => {
        NaveOrisol("/Apontamento");
      };
    const handlNave_AddPreventiva = () => {
        NaveOrisol("/Adicionar_Preventivas");
      };
      const AnaliseOrisol = () => {
        NaveOrisol("/AnaliseOrisol");
      };
      const handleLogin = () => {
        NaveOrisol("/Adduser");
      };
    

    const [closeMenu, setCloseMenu] = useState(true)
const handleCloseClick = () => {
    setCloseMenu(false)
    setTimeout(() => {
        setmnGeral(false)
    }, 400);
    
}

function limparCookies() {
    var cookies = document.cookie.split(";");
  
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var igualPos = cookie.indexOf("=");
      var nome = igualPos > -1 ? cookie.substr(0, igualPos) : cookie;
      document.cookie = nome + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

const handleSairApp = (e) => {
    document.cookie = "usuario=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    limparCookies()
    localStorage.clear();
    window.location.href = "/";
}
const handleAbrirTurno = (e) => {
    setConfigTurno(true)
}

//

    return(
        <>
            <div className={`MG_black ${closeMenu?'MG_blackopen':'MG_blackclose'}`}
            onClick={()=>{handleCloseClick()}}
            >
                <div className={`MG_container01 ${closeMenu?'MG_container01_open':'MG_container01_close'}`}>
                    <div className="MG_container02">
                        <div onClick={()=>{handleAbrirTurno()}}
                        style={{
                            position: 'absolute',
                            top:'20px',
                            right:'20px',
                        }}
                        ><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Z"/></svg></div>
                        <div className="MgUser">{`Olá, ${localStorage.getItem('usuario')}`}</div>
                        <div className="MG_menulista">
                            <div 
                            onClick={()=>{handlNave_Home()}}
                            className='Geral_items_Style'
                            
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 -960 960 960" width="26"><path d="M242.87-202.87H354.5V-445.5h251v242.63h111.63v-355.695L480-736.413 242.87-558.565v355.695Zm-91.001 91.001v-492.196L480-850.283l328.131 246.138v492.276H518.087v-246.218h-76.174v246.218H151.869ZM480-469.522Z"/></svg>
                            Ir para o home
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M304-68.434 234.434-139l343-343-343-343L304-895.566 717.566-482 304-68.434Z"/></svg>
                            </div>

                            <div 
                            onClick={()=>{handlNave_Apontamento()}}
                            className='Geral_items_Style'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M281.435-280h80v-200h-80v200Zm317.13 0h80v-400h-80v400ZM440-280h80v-120h-80v120Zm0-200h80v-80h-80v80ZM202.87-111.869q-37.783 0-64.392-26.609-26.609-26.609-26.609-64.392v-554.26q0-37.783 26.609-64.392 26.609-26.609 64.392-26.609h554.26q37.783 0 64.392 26.609 26.609 26.609 26.609 64.392v554.26q0 37.783-26.609 64.392-26.609 26.609-64.392 26.609H202.87Zm0-91.001h554.26v-554.26H202.87v554.26Zm0-554.26v554.26-554.26Z"/></svg>
                            Apontamento
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M304-68.434 234.434-139l343-343-343-343L304-895.566 717.566-482 304-68.434Z"/></svg>
                            </div>

                            <div className='Geral_items_Style'
                            onClick={()=>{handlNave_AddPreventiva()}}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M451.565-278.565h62.87v-166h167v-62.87h-167v-174h-62.87v174h-173v62.87h173v166Zm28.734 204.543q-84.202 0-158.041-31.878-73.839-31.879-129.159-87.199-55.32-55.32-87.199-129.201-31.878-73.88-31.878-158.167t31.878-158.2q31.879-73.914 87.161-128.747 55.283-54.832 129.181-86.818 73.899-31.986 158.205-31.986 84.307 0 158.249 31.968 73.942 31.967 128.756 86.768 54.815 54.801 86.79 128.883 31.976 74.083 31.976 158.333 0 84.235-31.986 158.07t-86.818 128.942q-54.833 55.107-128.873 87.169-74.04 32.063-158.242 32.063Zm.201-68.13q140.543 0 238.946-98.752 98.402-98.752 98.402-239.596 0-140.543-98.215-238.946-98.215-98.402-239.753-98.402-140.163 0-238.945 98.215-98.783 98.215-98.783 239.753 0 140.163 98.752 238.945 98.752 98.783 239.596 98.783ZM480-480Z"/></svg>
                            Adicionar preventiva
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M304-68.434 234.434-139l343-343-343-343L304-895.566 717.566-482 304-68.434Z"/></svg>
                            </div>

                            <div className='Geral_items_Style'
                            onClick={()=>{AnaliseOrisol()}}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M520.761-884.218q145.63 14.24 247.532 116.365 101.902 102.125 115.925 247.092H590.174q-8-24.326-26.424-41.25t-42.989-24.445v-297.762Zm60 84.37v166.587q16.152 9.717 30.467 22.652 14.316 12.935 23.272 29.848h165.109q-24.761-79.282-82.163-137.304-57.403-58.022-136.685-81.783Zm-142-84.37v297.762q-33.326 13.521-53.87 43.616-20.543 30.095-20.543 66.181 0 34.544 20.543 62.667 20.544 28.122 53.87 40.731v297.479q-155.659-14.28-259.699-128.934Q75.022-319.37 75.022-476.789q0-157.419 103.674-274.804Q282.37-868.978 438.761-884.218Zm-60 84.37Q270.957-769.37 206.935-679.123q-64.022 90.247-64.022 202.326 0 110.08 65.283 197.982 65.282 87.902 170.565 119.663v-165.87q-39.152-25.478-60.848-65.12-21.696-39.643-21.696-86.543 0-46.899 21.316-88.499 21.315-41.599 61.228-68.077v-166.587Zm211.413 361.087h294.044q-14.24 145.391-116.345 247.189T520.761-75.782v-297.566q24.565-7.522 43.228-24.185 18.663-16.663 26.185-41.228Zm44.493 60q-11.123 16.152-23.905 30.571-12.782 14.418-30.001 23.168v165.109q79.282-23 136.924-81.163 57.641-58.163 82.402-137.685h-165.42ZM297.174-479ZM634.5-580.761Zm-1.435 202Z"/></svg>
                            Analise maquinas
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M304-68.434 234.434-139l343-343-343-343L304-895.566 717.566-482 304-68.434Z"/></svg>
                            </div>

                            <div className='Geral_items_Style'
                            onClick={()=>{handleLogin()}}
                            >
                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v58q0 59-40.5 100.5T740-280q-35 0-66-15t-52-43q-29 29-65.5 43.5T480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480v58q0 26 17 44t43 18q26 0 43-18t17-44v-58q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h200v80H480Zm0-280q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"/></svg>
                            Add.usuario
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M304-68.434 234.434-139l343-343-343-343L304-895.566 717.566-482 304-68.434Z"/></svg>
                            </div>

                            <div onClick={(e)=>{handleSairApp(e)}} style={{color:'rgba(250,50,20)'}} className='Geral_items_Style'>
                            <svg style={{fill:'rgba(250,50,20)'}} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M190-99q-37.175 0-64.088-26.912Q99-152.825 99-190v-580q0-37.588 26.912-64.794Q152.825-862 190-862h295v92H190v580h295v91H190Zm467-175-64-63 96-97H355v-91h332l-96-97 64-64 207 207-205 205Z"/></svg>
                            Sair do App
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M304-68.434 234.434-139l343-343-343-343L304-895.566 717.566-482 304-68.434Z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}