import { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import "./msg.css"

export default function MsgAviso({ msg, setmsg, msgConteudo, setmsgConteudo,fecharForm }){
   const [svg, setsvg] = useState('')  
   const [closeMsg, setcloseMsg] = useState(false)
   //msgConteudo = 'Manutencao iniciada com sucesso! você ja pode inciar uma nova manutenção'
useEffect(()=>{
    
if (msg){
    setsvg (<svg className="Msg_true" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>)
}else{
    setsvg(<svg className="Msg_false" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m40-120 440-760 440 760H40Zm440-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Z"/></svg>)
}
},[msg])

const handleclickOk = () => {
    setcloseMsg(true)
    setTimeout(() => {
        setmsgConteudo(false)
        if(msg){
            if (typeof fecharForm === 'function') {
                fecharForm()
            }
            
        }
        
    }, 400);
    
  }
    return(
        <>
            <div className={`${!closeMsg?'Msg_tel_escura':'Msg_tel_escura_close'}`}>
                <div className={`${!closeMsg?"Msg_container_pr":"Msg_container_pr_close"}`}>
                    <div className="Mdg_bloc01">
                        {svg}
                        <h3 style={{color:`${msg?'green':'red'}`}}>Aviso</h3>
                    </div>
                    <hr></hr>
                    <div style={{color:'#212121'}} className="Msg_conteudoMsg">
                        <div className="MsgAviso">
                            <span>
                            {msgConteudo}
                            </span>
                        </div>
                    </div>

                    <div className="Msg_container_btn">
                        <button 
                        className="Msg_btn"
                        onClick={()=>handleclickOk()}
                        >OK</button>
                    </div>
                    
                </div>
            </div>
        </>
    )
}