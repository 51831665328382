
import "./PopupNovaOrdem.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Ld from '../../loading/BtnLogin/BtnLogin';
import AddmesmaOs from "./Add_mesmaOs";
import { useNavigate } from "react-router-dom";
import MsgAviso from '../../tela_Msg/msg'
axios.defaults.withCredentials = true;
const serverid = ''

const UserComponent = ({ handleuserServico, userServico, setuserServico })=>{

  const [options, setOptions] = useState([]);


  useEffect(()=>{
    const obterUser = async ()=>{
      try {
        const consulta = await axios.post(serverid+'/NovaOrdem/userSelect')
      const result = await consulta.data
      const array = Object.keys(result)
      setOptions(array)
      } catch (error) {
        console.log('Erros ao buscar usuario no banco de dados' + error)
      }
      
    }
    obterUser()
  },[])
  
  return(
    <select onChange={handleuserServico} value={userServico}>
  
  {options.length > 1 && <option></option>}
    
      {options.map((key, index) => (
        <option key={index}>{key}</option>
      ))}
      {options.length === 1 && setuserServico(options[0])}

  </select>
  )
 

}


const CreatOptonProblema = ({ handlprServico, prServico }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(serverid+'/NovaOrdem/creat_Option');
        const result = await response.data;
        const optionKeys = Object.keys(result);
       
        setOptions(optionKeys);
      } catch (error) {
        console.error('Erro ao buscar opções do banco de dados:', error);
      }
    };

    fetchData();
  }, []); // Executa uma vez após a montagem do componente


  return (

      <select onChange={handlprServico} value={prServico}>
        <option></option>
        {options.map((key, index) => (
          <option key={index}>{key}</option>
        ))}
      </select>
   
  );
};



export default  function PopUp_Ordem({ abriOrdem, setAbriOrdem, ToadsOs, setTodasOs }) {
  const [nServico, setServico] = useState("");
  const [hrServico, sethrServico] = useState("");
  const [prServico, setprServico] = useState("");
  const [userServico, setuserServico] = useState("");
  const [maqServico, setmaqServico] = useState("");
  const [optionSelect, setOption] = useState(false);
  const [optionSelect2, setOption2] = useState(false);
  const [btnspn, setbtnSpn] = useState(false);
  const [osOpen2, setosOpen2] = useState(false);
  
  var [msg, setmsg] = useState(false)
  var [msgConteudo, setmsgConteudo] = useState(false) 
  const naveLogin = useNavigate();
  const [AbrirNv, setAbrirNv] = useState()

  const resgistrarOrdem = async (e)=>{
    e.preventDefault();
    if(btnspn){
      
      return
    }
    setbtnSpn(true);
    try {
      const json= {nServico,hrServico,prServico,userServico,maqServico,optionSelect,optionSelect2}
      const sendDados = await axios.post(serverid+"/NovaOrdem/novaOrdem",json);
      const dadosRecebidos = sendDados.data
      setbtnSpn(false);setServico("");sethrServico("");setprServico("");setuserServico("");setmaqServico("");setOption(false);setOption2(false);setbtnSpn(false);
      setmsg(true)
      setmsgConteudo(dadosRecebidos)
      setbtnSpn(false);
    } catch (error) {
      setbtnSpn(false);
      setmsg(false)
          if(error.response.status === 408){
            setmsgConteudo(error.response.data)
                setTimeout(() => {
                  naveLogin("/");
                }, 3700);
              }else{
            setmsgConteudo(error.response.data)
              }
    }
 
  }



  const handleClosePopUp = () => {
     document.body.style.overflow = "";
     setosOpen2(true)
     setTimeout(() => {
      setAbriOrdem(false); 
     }, 300);
      
  };

  const handlehrServico = (e) => {
    sethrServico(e.target.value);
  };
  const handleServico = (e) => {
    setServico(e.target.value);
  };

  const handlprServico = (e) => {
    setprServico(e.target.value);
  };

  const handleuserServico = (e) => {
    setuserServico(e.target.value);
  };

  const handlemaqServico = (e) => {
    setmaqServico(e.target.value);
  };
  const handleOption = (e) => {
    setOption(true);
    setOption2(false);
  };
  const handleOption2 = (e) => {
    setOption2(true);
    setOption(false);
  };


  const handleClickOs = async (e) => {
    try {
      const json = {
        e
      }
      const envioDados = await axios.post(serverid+"/NovaOrdem/Parounovamente",json)
        const resp = envioDados.data
        if(resp){
          setAbrirNv(resp)
        }
    
    } catch (error) {
      console.log('Erro no click da os: ' + error)
      setAbrirNv()
    }
    
    
  }

  const handle_TodasOs = () => {

    if (!ToadsOs || typeof ToadsOs !== 'object') {
      // Trate o caso em que dadosOs não é um objeto válido
      return null; // ou outro comportamento apropriado para o seu aplicativo
    }
    return (
      <div className="Container_Os_Ordem" >
        {Object.keys(ToadsOs).map((key) => {
          const osData = ToadsOs[key];
          const status = osData.SITUACAO || 'OUTRO'; // Se não estiver no JSON, considera 'OUTRO'
  
        let cor;
        switch (status) {
          case 'PARADA':
            cor = 'vermelho_MP';
            break;
          case 'OBSERVACAO':
            cor = 'roxo_MP';
            break;
          case 'PREVENTIVA':
            cor = 'Azul_MP';
            break;
          case 'LIBERADA':
            cor = 'verde_MP';
            break;
          default:
            cor = '';
        }
          return (
            <div onClick={()=>handleClickOs(osData.MAQUINA)} className="dadosOs_Ordem" key={osData.MAQUINA}>
              <div className={`Ordem_Maq ${cor}`} >{osData.MAQUINA}</div>
              <div className="Ordem_Container_Dados">
                <div>
                  <strong>O.S:</strong> {key}
                </div>
                <div>
                  <strong>Hora Parou:</strong> {osData.HORA_PAROU}
                </div>
                <div>
                  <strong>Problema:</strong> {osData.PROBLEMA}
                </div>
                <div>
                  <strong>Tempo Parada:</strong> {osData.TEMPO_PARADA || 'N/A'}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };


  return (
    <>
    {msgConteudo&&<MsgAviso msg={msg} setmsg={setmsg} msgConteudo={msgConteudo} setmsgConteudo={setmsgConteudo} fecharForm={()=>handleClosePopUp()}/>}
    {AbrirNv && <AddmesmaOs AbrirNv={AbrirNv} setAbrirNv={setAbrirNv}/>}
      <div
        className={`${
          osOpen2?"BlackFechado" :abriOrdem && "Black_Aberto_Os"}`}
      >
        <div
          className={`NvContainer Container2_Ordem ${
            osOpen2?"fhc" :' aberto'
          }`}
        >
          <div
            className="Fechar_Novaordem"
            style={{
              position: "absolute",
              width: "25px",
              height: "25px",
              right: "5px",
              top: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleClosePopUp}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
          <div className="TituloOrdem">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M80 0v-160h800V0H80Zm160-320h56l312-311-29-29-28-28-311 312v56Zm-80 80v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm560-504-56-56 56 56ZM608-631l-29-29-28-28 57 57Z" />
            </svg>
            Registrar Ordem
          </div>
          <form className="">
            <fieldset>
              <legend className="">Dados Os</legend>

              <div className="Container_Numberordem">
                <label
                  className={`${nServico ? "moveLabel" : "moveLabel_Normal"}`}
                >
                  Nº Serviço
                </label>
                <input
                  type="number"
                  onChange={handleServico}
                  value={nServico}
                ></input>
              </div>

              <div className="Container_Numberordem">
                <label
                  className={`${hrServico ? "moveLabel" : "moveLabel_Normal"}`}
                >
                  Hora Parou
                </label>
                <input
                  type="time"
                  value={hrServico}
                  onChange={handlehrServico}
                ></input>
              </div>
              <div className="Container_Numberordem">
                <label
                  className={`${prServico ? "moveLabel" : "moveLabel_Normal"}`}
                >
                  Problema
                </label>
                
                  <CreatOptonProblema handlprServico={handlprServico} prServico={prServico}/>
                
              </div>

              <div className="Container_Numberordem">
                <label
                  className={`${
                    userServico ? "moveLabel" : "moveLabel_Normal"
                  }`}
                >
                  usuario
                </label>
                <UserComponent handleuserServico={handleuserServico} userServico={userServico} setuserServico={setuserServico}/>
              </div>

              <div className="Container_Numberordem">
                <label
                  className={`${maqServico ? "moveLabel" : "moveLabel_Normal"}`}
                >
                  Selecione Maq.
                </label>
                <select onChange={handlemaqServico} value={maqServico}>
  <option></option>
  {[...Array(23).keys()].map((_, index) => (
    <option key={index + 1}>{`MAQ-${index<9?'0'+(index + 1):index + 1}`}</option>
  ))}
</select>
              </div>

              <div className="Container_Numberordem option_Ordem">
                <label className="moveLabel">Maquina Parou?</label>
                <div className="mQ_PAROU_Option">
                  <div className="MqParou">
                    <label
                      className={`lb ${optionSelect && "colorSelected"}`}
                      for="Mq_Parou_Sim"
                    >
                      SIM
                    </label>
                  </div>

                  <div className="MqParou">
                    <label
                      className={`lb ${optionSelect2 && "colorSelected"}`}
                      for="Mq_Parou_Nao"
                    >
                      NÃO
                    </label>
                  </div>

                  <input
                    name="mqParada"
                    id="Mq_Parou_Sim"
                    type="radio"
                    onChange={handleOption}
                  ></input>
                  <input
                    name="mqParada"
                    id="Mq_Parou_Nao"
                    type="radio"
                    onChange={handleOption2}
                  ></input>
                </div>
              </div>
            </fieldset>
            <div
              style={{
                width: "100%",
                height:'40px',
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "10px",
                position: "relative",
              }}
            >
              <button
              style={{cursor:'pointer',position: "relative",display: "flex",justifyContent:'center',alignItems: "center"}}
                className="btn_registrar_Ordem"
                onClick={resgistrarOrdem}
              >{btnspn ? <Ld /> :<div>Registrar</div>}</button>
            
            </div>
          </form>
          <hr></hr>
        
            {ToadsOs && handle_TodasOs(ToadsOs)}

        </div>
      </div>
    </>
  );
}
