import "./Popups_FinalizarManutencao.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Ld from '../../loading/BtnLogin/BtnLogin'
import MsgAviso from '../../tela_Msg/msg'
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { DNA  } from 'react-loader-spinner';
import img from '../../imagem/InteligencyAi.png'
axios.defaults.withCredentials = true;
const serverid = '';


const ComponenteLoader = ()=>{
  return(
    <>
    <div style={{width:'100%',position:'absolute', height:'100%', display:'flex', justifyContent:'center',alignItems:'center'}}>
    <div>

    <DNA 
        type="Puff"
        color="rgba(53,120,250)"
        height={50}
        width={50}
      />

    </div>
  
</div>
    </>
  )
}



export default function Popups_FinalizarManutencao({
  Abrir_IN_Fo,
  setAbrir_IN_Fo,
  selectMaq,
  dadosApontamento,
}) {

  let [historco, setHistorico] = useState(false);
  const [btnspn ,setbtnspn]=useState(false)
  const [abrirPauseMan, setabrirPauseMan] = useState(false)
  const [userSelector, setuserSelector] = useState({})
  const [dadosOsMaq, setdadosOsMaq] = useState()
  const [vfAbertto, setvfAbertto] = useState(false)
  const [selectUser, setselectUser] = useState(['SELECIONE'])
  const [osPauseSElect, setosPauseSElect] = useState('')
  var [msg, setmsg] = useState(false)
  var [msgConteudo, setmsgConteudo] = useState(false);

  

  useEffect(() =>{
    
    const busCasHistorico = async ()=>{
  
      try {
        const json = {maquina:selectMaq}
        const dados = await axios.post(serverid+"/analise/historico", json)
        const result = await dados.data
        setHistorico(result)
      } catch (error) {
        console.log(error)
      }
      
    }
      if (!historco){
      
        busCasHistorico()
      }

    
    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectMaq])



const handlechange_Slect = (e)=>{
  setuserSelector({
    SELECT:e.target.value,
    MAQUINA:selectMaq
})
}

/* componente busca dados para prencher ordem de serviço  */
  const BuscarDadosOS = ()=>{
   
    useEffect(() => {
      if(!vfAbertto){
      const buscarDadosOS = async () => {
        
        try {
          const json = { selectMaq };
          const dadosServer = await axios.post(serverid+"/inicioManutencao/dadosOs_iniciarManutencao", json);
          const dadoRecebidos = await dadosServer.data
          setdadosOsMaq(dadoRecebidos);
        } catch (error) {
          if(error.response.status === 408){
            setmsgConteudo(error.response.data)
            setmsg(false)
                setTimeout( async () => {
                  await handleSairApp()
                }, 3700);
                
              }else{
                setmsgConteudo(error.response.data)
                setmsg(false)
              }
        }
        setvfAbertto(true)
      };
  
      buscarDadosOS();
    }
    }, []);
    
      return(
      
      <div className="FO_Historico">
      <div>
        <span>
          Ordem de serviço: | <strong>{`${dadosOsMaq?dadosOsMaq["NUMERO_OS"]:'----'}`}</strong>
        </span>
      </div>
      <div>
        <span>
          Hora Parou: | <strong>{`${dadosOsMaq?dadosOsMaq["HORA_PAROU"]:'--:--'}`}</strong>
        </span>
      </div>
      <div>
        <span>
          Serviço / Problema| <strong>{`${dadosOsMaq?dadosOsMaq["PROBLEMA"]:'----'}`}</strong>
        </span>
      </div>
    </div>
      )
 
  }




  const [abrirFm, setFm] = useState(false);

  const handleClickFechar_Ordem = () => {
  
    document.body.style.overflow = "";
    setFm(true)
    setTimeout(() => {
      setAbrir_IN_Fo(false);
    }, 250);
   
  };


  const inciarManutencaoClick = async () => {
if(!btnspn){
  setbtnspn(true)



try{

      const enviarDados = await axios.post(serverid+'/inicioManutencao/clickIniciadoManu',userSelector)
      const dadosrecebidos = await enviarDados.data
      
      setmsg(true)
      setmsgConteudo(dadosrecebidos)
    } catch (error) {
      setmsg(false)
          if(error.response.status === 408){
            setmsgConteudo(error.response.data)
            setTimeout( async () => {
              await handleSairApp()
            }, 3700);
              }else{
            setmsgConteudo(error.response.data)
              }
    }
    setbtnspn(false) 
  }
}
let [corfundo, setcorFundo] = useState('linear-gradient(to bottom, rgba(0,200,150),rgba(255,255,255), rgba(255,255,255))');
useEffect(() => {

  if (dadosOsMaq && dadosOsMaq['STS'] === 'PARADA') {
    setcorFundo('linear-gradient(to bottom, rgb(200, 23, 0),rgba(255,255,255), rgba(255,255,255))');
  } else if (dadosOsMaq && dadosOsMaq['STS'] === 'OBSERVACAO') {
    setcorFundo('linear-gradient(to bottom, rgb(143, 0, 200),rgba(255,255,255), rgba(255,255,255))');
  } else {
    setcorFundo('linear-gradient(to bottom, rgba(0,200,150),rgba(255,255,255), rgba(255,255,255))');
  }
}, [dadosOsMaq]);

  return (
    <>
    {msgConteudo&&<MsgAviso msg={msg} setmsg={setmsg} msgConteudo={msgConteudo} setmsgConteudo={setmsgConteudo} fecharForm ={()=>handleClickFechar_Ordem()}/>}
      <div className={`${abrirFm ? 'fecharFm' : Abrir_IN_Fo && "black_FinalizarOrdem" }`}>
        <div
         style={{transition:"all 0.9s linear", background:corfundo}}
          className={`popupinciar_Manutencao ${
            abrirFm ? "Fo_Fechado":"Fo_Aberto"
          }`}
        >
          
          <div className="FO_TopoTxt">
            <svg
              style={{ marginRight: "5px" }}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M480-480ZM370-80l-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-74 56q-22-11-45-18.5T714-558l63-48-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q17 17 36.5 30.5T400-275q1 57 23.5 107T484-80H370Zm41-279q6-20 14.5-38.5T445-433q-11-8-17-20.5t-6-26.5q0-25 17.5-42.5T482-540q14 0 27 6.5t21 17.5q17-11 35-19.5t38-13.5q-18-32-50-51.5T482-620q-59 0-99.5 41T342-480q0 38 18.5 70.5T411-359Zm269 199 120-120-120-120-28 28 72 72H560v40h163l-71 72 28 28Zm0 80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80Z" />
            </svg>
            <div style={{width:'50%',
          boxSizing:'border-box', color:'white',
          }}>Iniciar Manutenção</div>
            <div style={{ color:'white'}} className="Tree_container_FO">
            {selectMaq}
          </div>
          </div>
     

          <div style={{width:'100%', marginTop:'18px',
          backgroundColor:'white',borderRadius:'7px',boxSizing:'border-box',
          display:'block', padding:'8px'}} className="Quato_container_FO">
            
            <div style={{display:"flex",
            fontSize:'13px', fontWeight:'600',
             alignItems:'center', position:'relative'
          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M824-120 636-308q-41 32-90.5 50T440-240q-90 0-162.5-44T163-400h98q34 37 79.5 58.5T440-320q100 0 170-70t70-170q0-100-70-170t-170-70q-94 0-162.5 63.5T201-580h-80q8-127 99.5-213.5T440-880q134 0 227 93t93 227q0 56-18 105.5T692-364l188 188-56 56ZM397-400l-63-208-52 148H80v-60h160l66-190h60l61 204 43-134h60l60 120h30v60h-67l-47-94-50 154h-59Z" />
            </svg>
              <div style={{width:'50%',}}>Analise Rápida</div>
              <div
            style={{
              color:'rgb(120,120,110)',fontWeight:'500', width:"85%",
            }}
          >
            Disponibilidade dos ultimos 5 dias
          </div>
              </div>
         

          <ComponenteHistorico5dias 
          selectMaq={selectMaq} 
          setHistorico={setHistorico} 
          historco={historco} />
    
        </div>
        

          <div style={{position:'relative',
         display: 'block',width:'100%', backgroundColor:'white',
         borderRadius:'8px', boxShadow:'0 2px 5px rgba(0, 0, 0, 0.5)',
         padding:'8px', boxSizing:'border-box',marginTop:'20px'
        }} >
          <div
            className="Fechar_Novaordem"
            style={{
              backgroundColor:"rgba(255,20,50)",
              position: "absolute",
              width: "25px",
              height: "25px",
              right: "10px",
              top: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleClickFechar_Ordem}
          >
            <svg
            style={{fill:'white'}}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
            
            <div style={{display:"flex",
            fontSize:'13px', fontWeight:'600',
             alignItems:'center'
          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="18"
              viewBox="0 -960 960 960"
              width="30"
            >
              <path d="M480-400ZM80-160v-400q0-33 23.5-56.5T160-640h120v-80q0-33 23.5-56.5T360-800h240q33 0 56.5 23.5T680-720v80h120q33 0 56.5 23.5T880-560v400H80Zm240-200v40h-80v-40h-80v120h640v-120h-80v40h-80v-40H320ZM160-560v120h80v-40h80v40h320v-40h80v40h80v-120H160Zm200-80h240v-80H360v80Z" />
            </svg>
              Serviço Atual</div>
          
          <div className="cinco_container_FO">
            <div className="FO_Historico">
              <BuscarDadosOS/>
            </div>
          </div>

          <div className="seven_container_FO">
            
            <ComponenteuserIniciarMan 
            vfAbertto={vfAbertto}
            setselectUser={setselectUser}
            setuserSelector={setuserSelector}
            selectUser={selectUser}
            selectMaq={selectMaq}
            setmsgConteudo={setmsgConteudo}
            setmsg={setmsg}
            setvfAbertto={setvfAbertto}
            userSelector={userSelector}
            handlechange_Slect={handlechange_Slect}
            />
           
            <button 
            className="FO_Btn"
            onClick={inciarManutencaoClick}
            >
              {btnspn ? <Ld />:<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="m216-160-56-56 384-384H440v80h-80v-160h233q16 0 31 6t26 17l120 119q27 27 66 42t84 16v80q-62 0-112.5-19T718-476l-40-42-88 88 90 90-262 151-40-69 172-99-68-68-266 265Zm-96-280v-80h200v80H120ZM40-560v-80h200v80H40Zm739-80q-33 0-57-23.5T698-720q0-33 24-56.5t57-23.5q33 0 57 23.5t24 56.5q0 33-24 56.5T779-640Zm-659-40v-80h200v80H120Z" />
              </svg>
              Iniciar</div>}
            </button>
          </div>
        </div>{/* Fim  */}

          <div className="oito_container_Fo">
            

           <ComponenteInicioManut
           dadosApontamento={dadosApontamento}
           setabrirPauseMan={setabrirPauseMan}
           setosPauseSElect={setosPauseSElect}
           />
            
          </div>
          {historco&&<div style={{marginTop:'10px',boxSizing:'border-box',margin:'0 auto', width: '95%', padding: '5px', height: '' }}>
          <img alt="ok" src={img} style={{ boxSizing:'border-box',width: '100%', height: '100%', objectFit: 'contain',boxShadow:'0 0 5px rgba(0,0,0,0.8)' }} />
        </div>}
          <div style={{width:'100%', display:'flex',
        justifyContent:'center',alignItems:"center", padding:'15px 5px',boxSizing:'border-box',}}>
          {historco?<textarea
          
          style={{border:'1px solid rgba(0,0,0,0.3)'
        , width:'100%',height:'250px',padding:'10px', maxWidth:'95%',minWidth:'95%',
        minHeight:'calc(100%)', boxSizing:'border-box'
        }} defaultValue={historco&&historco['textoInformativo']} readOnly
          
          ></textarea>:<ComponenteLoader/>}
          
          </div>
        </div>
      </div>
      {abrirPauseMan&&<PauseManutencao osPauseSElect={osPauseSElect} abrirPauseMan={abrirPauseMan} setabrirPauseMan={setabrirPauseMan} setmsg={setmsg} setmsgConteudo={setmsgConteudo} />}
    </>
  );
}

/* Fim poup export default */

function PauseManutencao({osPauseSElect, abrirPauseMan, setabrirPauseMan, setmsg, setmsgConteudo }) {
  const [selectChange,setselectChange] = useState('')
  const [textAreaChange,settextAreaChange] = useState('')
  const [seLiberado,setseLiberado] = useState('')
  const [closePause, setclosePause] = useState(true)
  const [btnspn ,setbtnspn]=useState(false)
  const handleSelect = (e) => {
    setselectChange(e.target.value)
  }
  const handletextArea = (e) => {
    let textArea = e.target.value
    textArea = textArea.toUpperCase()
    settextAreaChange(textArea)
  }
  const handleClickSeliberado = (e) => {
    setseLiberado(e.target.id)
  }
  const handleClickFechar_PauseMan= (e) => {
    setclosePause(false)
    setTimeout(() => {
      setabrirPauseMan(false)
    }, 300);
  }

const handleClickPause = async (e) => {
    e.preventDefault()
    if (!btnspn){
  setbtnspn(true)
      try {
        const json = {
         DADOS:osPauseSElect,
         OBSERVACAO:textAreaChange,
         CAUSA:selectChange,
         SITUACAO:seLiberado
        }
        const envioDados = await axios.post(serverid+'/pauseManServer/pauseMachine',json)
        setmsg(true)
        setmsgConteudo(envioDados.data)
        handleClickFechar_PauseMan()
      } catch (error) {
        setmsg(false)
        if(error.response.status === 408){
         
          setmsgConteudo(error.response.data)
          setTimeout( async () => {
            await handleSairApp()
          }, 3700);
              
            }else{
          setmsgConteudo(error.response.data)
            }
      }


    
    
    setbtnspn(false)  
  }
}

  return (
    <>
    
    <div className={`${closePause?'blck_PauseMan':'blck_PauseMan_fecha'}`}>
      <div className={`${closePause?'second_Pause_Man':'second_Pause_Man_fecha'}`}>
        
      <div
            className="Fechar_Novaordem"
            style={{
              position: "absolute",
              width: "25px",
              height: "25px",
              right: "30px",
              top: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleClickFechar_PauseMan}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>

        <div
          style={{
            width: '100%',margin:'0 auto',display: 'flex',justifyContent:"center", alignItems:'center'
          }}
        >
          <svg style={{fill:'rgb(110,205,40'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M650-160h40v-160h-40v160Zm100 0h40v-160h-40v160ZM240-600h480v-80H240v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40ZM120-80v-680q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v267q-28-14-58.5-20.5T720-520H240v80h284q-17 17-31.5 37T467-360H240v80h203q-2 10-2.5 19.5T440-240q0 42 11.5 80.5T486-86l-6 6-60-60-60 60-60-60-60 60-60-60-60 60Z"/></svg>
          <h3 style={{margin:'5px'}}>Pausar Manutenção</h3>
        </div>
        <hr></hr>

          <form style={{/* border:'1px solid red' */margin:'10px', marginTop:'25px'}}>
            <div style={{position:'relative'}}>
              <label style={{transition:'all 0.35s ease-in-out',pointerEvents: 'none',textIndent:'3px', top:selectChange?'-18px':'2px',position:'absolute', fontSize:selectChange?'clamp(12px,1.8vw,14px)':'clamp(14px,1.8vw,15px)'}}>Causa do Problema</label>
              <select
              value={selectChange}
              onChange={(e)=>handleSelect(e)}
              style={{outline:'none',backgroundColor:'transparent',border:'none', borderBottom:'1px solid black',height:'20px'}}>
                <option></option>
                <option>FALHA OPERACIONAL</option>
                <option>FALHA EQUIPAMENTO</option>
                <option>FALHA LIDERANÇA</option>
                <option>FALHA GABARITO</option>
                <option>FALHA PROGRAMA</option>
                <option>QUEDA ENERGIA</option>
              </select>
            </div>

            <div style={{position:'relative',/* border:'1px solid red', */ boxSizing:"border-box",width:'100%',marginTop:'25px'}}>
            <label style={{transition:'all 0.35s ease-in-out',pointerEvents: 'none',textIndent:'3px', top:textAreaChange?'-18px':'2px',position:'absolute', fontSize:textAreaChange?'clamp(12px,1.8vw,14px)':'clamp(14px,1.8vw,15px)'}}>Descreva o que foi feito</label>
              <textarea 
              value={textAreaChange}
              onChange={(e)=>handletextArea(e)}
              style={{outline:'none',maxHeight:'65px', maxWidth:'250px',minHeight:'65px', minWidth:'250px'}}></textarea>
            </div>

            <h4 style={{margin:'0 auto',marginTop:'10px',marginBottom:'5px'}}>Esta Liberada?</h4>
            
            
            <div style={{alignItems:'stretch',display:'inline-flex', width:'100%'}}>
            <div 
              id="OBS"
              onClick={(e)=>{handleClickSeliberado(e)}}
              style={{overflow:"hidden",position:'relative',boxShadow:'0 3PX 5PX RGBA(0,0,0,0.2',borderRadius:'20px',margin:'5px',border:'1px solid #404040',height:'25px',width:'20%',display:"flex", justifyContent:'center',alignItems:'center'}}>
              
              <div style={{pointerEvents:'none',backgroundColor:seLiberado==='OBS'?'rgb(190, 112, 227)':''}} className={`${seLiberado==='OBS' ? 'simSElect':''}`}>
              <label style={{pointerEvents:'none'}}>OBS</label>
              </div>
              </div>

              <div 
              id="NAO"
              onClick={(e)=>{handleClickSeliberado(e)}}
              style={{overflow:"hidden",position:'relative',boxShadow:'0 3PX 5PX RGBA(0,0,0,0.2',borderRadius:'20px',margin:'5px',border:'1px solid #404040',height:'25px',width:'20%',display:"flex", justifyContent:'center',alignItems:'center'}}>
              
              <div style={{pointerEvents:'none',backgroundColor:seLiberado==='NAO'?'rgba(226, 86, 86, 0.861)':''}} className={`${seLiberado==='NAO' ? 'simSElect':''}`}>
              <label style={{pointerEvents:'none'}}>NAO</label>
              </div>
              </div>

              <div 
              id="SIM"
              onClick={(e)=>{handleClickSeliberado(e)}}
              style={{overflow:"hidden",position:'relative',boxShadow:'0 3PX 5PX RGBA(0,0,0,0.2',borderRadius:'20px',margin:'5px',border:'1px solid #404040',height:'25px',width:'20%',display:"flex", justifyContent:'center',alignItems:'center'}}>
              
              <div style={{pointerEvents:'none',backgroundColor:seLiberado==='SIM'?'rgb(13, 224, 112)':''}} className={`${seLiberado==='SIM' ? 'simSElect':''}`}>
              <label style={{pointerEvents:'none'}}>SIM</label>
              </div>
                  
                
              </div>
            </div>
            
          <div style={{width:'100%',display:"flex",justifyContent:'flex-end',marginTop:'25px'}}>
            <button 
            onClick={(e)=>{handleClickPause(e)}}
            className="Pausar_fim">{btnspn ? <Ld />: <div  style={{width:'100%',display:"flex",justifyContent:'center',alignItems:'center'}}><svg style={{marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M650-160h40v-160h-40v160Zm100 0h40v-160h-40v160ZM240-600h480v-80H240v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40ZM120-80v-680q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v267q-28-14-58.5-20.5T720-520H240v80h284q-17 17-31.5 37T467-360H240v80h203q-2 10-2.5 19.5T440-240q0 42 11.5 80.5T486-86l-6 6-60-60-60 60-60-60-60 60-60-60-60 60Z"/></svg>Pausar</div>}
           
            </button>
          </div>

          </form>

    </div>
    </div>
  </>
  )
}

async function limparCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var igualPos = cookie.indexOf("=");
    var nome = igualPos > -1 ? cookie.substr(0, igualPos) : cookie;
    document.cookie = nome + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

const handleSairApp = async (e) => {
  document.cookie = "usuario=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  await limparCookies()
  localStorage.clear();
  window.location.href = "/";
}


const ComponenteuserIniciarMan  = ({
  vfAbertto,
  setselectUser,
  setuserSelector,
  selectUser,
  selectMaq,
  setmsgConteudo,
  setmsg,
  setvfAbertto,
  userSelector,
  handlechange_Slect
}) => {



  useEffect(()=>{

    if(!vfAbertto){
    const buscarDadosUser = async () => {
      try {
        const api = await axios.post(serverid+'/inicioManutencao/selectUSer')
        const response = await api.data
        setselectUser(response)
  
        setuserSelector({
          SELECT:selectUser[0],
          MAQUINA:selectMaq
      })
      } catch (error) {
        console.log(error)
        if(error.response.status === 408){
      setmsgConteudo(error.response.data)
      setmsg(false)
          setTimeout( async () => {
            await handleSairApp()
          }, 3700);
          
        }else{
          setmsgConteudo(error.response.data)
          setmsg(false)
        }
      }
      
    }
    buscarDadosUser()
  }

  setvfAbertto(true)


  },[selectMaq, selectUser, setmsg, setmsgConteudo, setselectUser, setuserSelector, setvfAbertto, vfAbertto])
  return(
    <select
    value={userSelector?userSelector.SELECT:'SELECIONE'}
    onChange={(e)=>handlechange_Slect(e)}
    >
      {selectUser.length > 1 && <option>SELECIONE</option>}
  
      {selectUser.map((user, index)=>(
        
        <option key={index}>{user}</option>
       
      ))}     
    </select>
  )
      
}


const ComponenteInicioManut = ({
  dadosApontamento, 
  setabrirPauseMan, 
  setosPauseSElect 
})=>{


  const Cronometro = ({ horaParada, situacao }) => {
    const [tempoDecorrido, setTempoDecorrido] = useState(0);

    useEffect(() => {
      if (situacao === 'PARADA') {
        const intervalId = setInterval(() => {
          const agora = new Date();
          const [data, hora] = horaParada.split(' ');
          const [dia, mes, ano] = data.split('-');
          const [horaStr, minuto] = hora.split(':');
          const horaParadaDate = new Date(ano, mes - 1, dia, horaStr, minuto);
          const diferenca = agora - horaParadaDate;
          setTempoDecorrido(diferenca);
        }, 1000);
        return () => clearInterval(intervalId);
      }
    }, [horaParada, situacao]);
  
    const formatarTempo = (milissegundos) => {
      const segundosTotais = Math.floor(milissegundos / 1000);
      const segundos = segundosTotais % 60;
      const minutos = Math.floor(segundosTotais / 60) % 60;
      const horas = Math.floor(segundosTotais / 3600);
  
      const formatoDuasCasas = (valor) => (valor < 10 ? `0${valor}` : valor);
  
      return `${formatoDuasCasas(horas)}:${formatoDuasCasas(minutos)}:${formatoDuasCasas(segundos)}`;
    };
  
    return <span>{formatarTempo(tempoDecorrido)}</span>;
  };
  




const hanlePauseMec = (e)=>{
  setabrirPauseMan(true)
  setosPauseSElect(e.currentTarget.getAttribute('dadosPause'))
}

  return(

    
    Object.keys(dadosApontamento).map((user, index)=>(
      <div key={index} className="Container_Mec_Fo">
        <div className="FO_userIn">{user}</div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
          </svg>
          <Cronometro
  dataParada={dadosApontamento[user]['DATA_PAROU']}
  horaParada={dadosApontamento[user]['HORA_INICOU']}
  situacao={'PARADA'}
/>
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="m40-120 440-760 440 760H40Zm440-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Z" />
          </svg>
          {dadosApontamento[user]['PROBLEMA']}
        </div>

        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm100-200h46v-240h-36l-70 50 24 36 36-26v180Zm124 0h156v-40h-94l-2-2q21-20 34.5-34t21.5-22q18-18 27-36t9-38q0-29-22-48.5T458-600q-26 0-47 15t-29 39l40 16q5-13 14.5-20.5T458-558q15 0 24.5 8t9.5 20q0 11-4 20.5T470-486l-32 32-54 54v40Zm296 0q36 0 58-20t22-52q0-18-10-32t-28-22v-2q14-8 22-20.5t8-29.5q0-27-21-44.5T678-600q-25 0-46.5 14.5T604-550l40 16q4-12 13-19t21-7q13 0 21.5 7.5T708-534q0 14-10 22t-26 8h-18v40h20q20 0 31 8t11 22q0 13-11 22.5t-25 9.5q-17 0-26-7.5T638-436l-40 16q7 29 28.5 44.5T680-360Z" />
          </svg>
          {dadosApontamento[user]['NUMERO_OS']}
        </div>

        <div>
           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Z"/></svg>
          
          {dadosApontamento[user]['MAQUINA']}
        </div>

        <button
        id={`${dadosApontamento[user]['NUMERO_OS']}`}
        dadosPause = {JSON.stringify({MECANICO:user,NUMER_oS:dadosApontamento[user]['NUMERO_OS'],MAQ:dadosApontamento[user]['MAQUINA']})}
        onClick={(e)=>hanlePauseMec(e)}
        className="Btn_Pause_Im">Pause</button>
       
      </div>
      
      ))
              


      
  )
  
}

//let attiv

var ComponenteHistorico5dias = ({ historco })=>{

  


  return(
    <>
      

          <div className="cinco_container_FO">
            <div className="FO_Historico">
              
              <div style={{
                width: "100%",
                minHeight:'120px',
                margin:'0 auto',

                boxSizing:'border-box', 
                display: 'flex', 
                position: 'relative',
              }}>
                {/* consainer grafico*/}
              {historco ? <div style={{
                width:'70%',
                display:"flex",
                flexDirection:'row',
                boxSizing:'border-box',
                marginRight:'3px',
                height:'120px', padding:'1px',
                position:'relative', justifyContent:'center', alignItems:'center',
              }}>
                {/* Primeiro quadrante */}
    
              {historco && Object.keys(historco['MesaMes']).map((key, index) => (
                <BarraAnimada1 key={index} data={key} percent={historco['MesaMes'][key]}
                
                />
              ))}

              

              </div>:<ComponenteLoader/>}

              {historco && <div style={{
                width:'50%',
                height:'100%',
                /* border:'1px solid blue' */
              }}>
               {<div style={{
                  fontSize:'11px',
                }}>Totais de paradas: <strong style={{fontWeight:'600', fontSize:"13px"}}>{historco['paradas']['Turno1']+historco['paradas']['Turno2']+historco['paradas']['Turno3']}</strong></div>}
                
                <div>
                  <hr></hr>

                  <div style={{display:'block', color:'rgb(90,90,90)'}}>
                    <div style={{display:'flex'}}>
                      T1: <div style={{fontWeight:'600',marginLeft:'5px', marginRight:'5px'}}>{historco['paradas']['Turno1']}  </div> Paradas 
                    </div>
                    <div style={{display:'flex'}}>
                      T2: <div style={{fontWeight:'600',marginLeft:'5px', marginRight:'5px'}}>{historco['paradas']['Turno2']}</div> Paradas
                    </div>
                   {/*  <div style={{width:'100%'
                  , backgroundColor:'rgba(0,0,0,0.05)',
                  borderRadius:'10px', boxSizing:'border-box',
                  }}>
                      
                    </div> */}
                  </div>

                </div>

              </div>}

              

              </div>
             
            </div>
          </div>
    </>
  )
}

const BarraAnimada1 = ({percent , data}) =>{
  const props = useSpring({
    from: { height: '20%' },
    to: { height: `${percent}%` },
    config: { duration: 1000 }
  });
  return(
    <div style={{
      width:'25%',
      height:'100%',
      margin:'1px',
      boxSizing:'border-box',
      display:'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '1px'
    }}>
      {/* Segundo quadrante */}
      <div style={{
        width:'90%',
        height:'100%',
        boxSizing:'border-box',
        position:'relative',
        justifyContent: 'center',
        alignItems: 'center',
        background:'linear-gradient(to bottom, rgba(0,0,0,0.08), rgba(255,255,255))',
        borderTopRightRadius:'20px',
        borderTopLeftRadius:'20px',
        overflow: 'hidden',
      }}>

        <animated.div style={{
        width:'100%',
        height:`${percent}%`,
        boxSizing:'border-box',
        position:'absolute',
        bottom:'0',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background:`${percent>92?'linear-gradient(to top, rgba(0,0,0,0.00),rgba(0,250,0))':'linear-gradient(to top, rgba(0,0,0,0.00),rgba(250,0,0))'}`,
        borderTopRightRadius:'20px',
        borderTopLeftRadius:'20px',
          ...props
      }}  className='clickBarra_Animation'>
        <div style={{
        fontWeight:'600', fontSize:'clamp(13px,1.8vw,14px)',
          }}>{percent + '%'}</div>
      </animated.div>

      </div>
      <div style={{ 
      display:'flex', 
      justifyContent:'center',color:'rgb(100,100,100)',
      alignItems:'center', textAlign:'center',fontSize:'clamp(11px,1.8vw,14px)'}}>{data}</div>
    </div>
  )
}