import { Doughnut } from "react-chartjs-2";

const GraficoApontamentoMes = ({ graficoTodoApontMes }) => {
  // Verificar se graficoTodoApontMes está definido
  if (!graficoTodoApontMes) {
    return null; // Ou retorne algum componente indicando que não há dados
  }

  // Verificar se PERCENT está definido e é um número
  const percent = parseInt(graficoTodoApontMes.PERCENT, 10);
  if (isNaN(percent)) {
    return null; // Ou trate o erro de conversão de forma apropriada
  }

  let restoApont = 100 - percent;
  let apontado = percent;

  // Garantir que os valores não ultrapassem os limites
  restoApont = Math.max(0, restoApont);
  apontado = Math.min(100, apontado);

  const grGrafico = [apontado, restoApont];

  // Cores
  const corVermelho = ["rgb(247, 71, 71)", "rgba(30, 30, 30, 0.2)"];
  const corVerde = ["rgb(13, 224, 112)", "rgba(30, 30, 30, 0.2)"];

  const data = {
    labels: [],
    datasets: [
      {
        label: "Tf",
        data: grGrafico,
        backgroundColor: grGrafico[0] < 95 ? corVermelho : corVerde,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '85%', // Ajuste este valor para alterar a espessura do gráfico
  };

  return <Doughnut data={data} options={options} />;
};

export default GraficoApontamentoMes;
