
import './Msg_Instalacao.css'
import { useEffect, useState } from 'react'
const  MsgInstalacao = ( { handleInstallClick, setShowInstallMessage } )=>{
    const handleclose = () => {
        setShowInstallMessage(false)
    }

    const [mostrarBtn, setMostrarBtn] = useState(false)
    useEffect(() =>{
        setTimeout(() => {
            setMostrarBtn(true)
        }, 6000);
    },[])
   
    return(
        <>
            <div className='Msg_tel_escura'>
                <div className='telaInfor_instalacao'>
                
                <div 
                style={{
                    width:'100%',
                    height:'50px',
                    display:'flex',
                    justifyContent: 'center',
                    alignItems:'center'
                    }}>
                    <img className='imgLogoRtc' style={{  height:'100%' }} src="../.././192x192connecct.png" alt=""></img>
                    <h3 style={{marginLeft:'10px'}}>Aplicativo disponivel</h3>
                </div>
                    <p style={{lineHeight:'1.55'}}>
                      Para uma experiência ainda mais eficiente em tempo real das máquinas, 🚀 recomendamos que você instale o aplicativo. Isso proporcionará uma utilização mais fluida e dinâmica, permitindo que usabilidade seja mais eficiente! 🛠️💻
                    </p>


                    <div 
                    style={{
                        width:'100%',
                        boxSizing: 'border-box',
                        margin:'0 auto',
                        marginTop: '20px',
                        display:mostrarBtn?'flex':'none',
                        justifyContent: 'flex-end',
                        alignContent: 'center',
                        position:'relative',

                        }}>
                        <button 
                        onClick={()=>{handleclose()}}
                        className='buttonDisp'>Dispensar</button>
                        <button 
                        onClick={(event)=>{handleInstallClick(event)}}
                        className='buttonInstall'>Instalar Agora</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MsgInstalacao