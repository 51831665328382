
import { useState } from "react";
import axios from "axios";
import Ld from '../../loading/BtnLogin/BtnLogin';
import MsgAviso from '../../tela_Msg/msg'
const serverid = ''
axios.defaults.withCredentials = true;
const AddmaqParada = ({ AbrirNv, setAbrirNv })=>{
const [btnspn,setbtnspn] = useState(false)
const [flecharNv, setfecharNsv]=useState(false);
var [msg, setmsg] = useState(false)
var [msgConteudo, setmsgConteudo] = useState(false) 
let maquina = [];

if (AbrirNv && typeof AbrirNv === 'object') {
  Object.keys(AbrirNv).forEach((osKey, index) => {
    maquina.push(AbrirNv[osKey]['MAQUINA']);
  });
}


const [selectedElement, setSelectedElement] = useState(null);

// Função para lidar com o clique em um elemento
const handleClick = (id) => {
  setSelectedElement(id);
};
  
const clickPararNv = async (e) => {
  if(btnspn){
    return
  }
  setbtnspn(true)
  
    try {
        const json = {
            Os:selectedElement,
            SITUACAO:'PARADA'
        }
        const envio = await axios.post(serverid+"/NovaOrdem/ConfirmarParar",json)
        const dados = envio.data
        setbtnspn(false)
        setmsg(true)
      setmsgConteudo(dados)
    } catch (error) {
      setbtnspn(false)
      setmsg(false)
          if(error.response.status === 408){
            setmsgConteudo(error.response.data)
               
              }else{
            setmsgConteudo(error.response.data)
              }
    }
}

const clickObservarNv = async (e) => {
  if(btnspn){
    return
  }
  setbtnspn(true)
  try {
      const json = {
          Os:selectedElement,
          SITUACAO:'OBSERVACAO'
      }
      const envio = await axios.post(serverid+"/NovaOrdem/ConfirmarParar",json)
      const dados = envio.data
      setbtnspn(false)
      setmsg(true)
      setmsgConteudo(dados)
  } catch (error) {
    setbtnspn(false)
    setmsg(false)
          if(error.response.status === 408){
            setmsgConteudo(error.response.data)
               ;
              }else{
            setmsgConteudo(error.response.data)
              }
  }
}

const AbrirNvOS = () => {
    document.body.style.overflow = "hidden";
    if (AbrirNv && typeof AbrirNv === 'object') {
    return Object.keys(AbrirNv).map((osKey, index) => (
      <div  className="Nv_ContainerDados_Os"
      key={AbrirNv[osKey]['Os']}
      onClick={()=>{handleClick(AbrirNv[osKey]['Os'])}}
      style={{
        cursor:'pointer',
        backgroundColor:selectedElement === AbrirNv[osKey]['Os'] ? 'rgba(151, 252, 129, 0.4)' : 'tranparent',
      }}
      >
            
        <svg xmlns="http://www.w3.org/2000/svg" height="28" viewBox="0 -960 960 960" width="28">
          <path d="M120-160v-80h110l-16-14q-52-46-73-105t-21-119q0-111 66.5-197.5T360-790v84q-72 26-116 88.5T200-478q0 45 17 87.5t53 78.5l10 10v-98h80v240H120Zm717-360h-81q-5-35-21.5-67T690-648l-10-10v98h-80v-240h240v80H730l16 14q41 42 63 89t28 97ZM680-40l-12-60q-12-5-22.5-10.5T624-124l-58 18-40-68 46-40q-2-14-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T668-380l12-60h80l12 60q12 5 22.5 11t21.5 15l58-20 40 70-46 40q2 12 2 25t-2 25l46 40-40 68-58-18q-11 8-21.5 13.5T772-100l-12 60h-80Zm40-120q33 0 56.5-23.5T800-240q0-33-23.5-56.5T720-320q-33 0-56.5 23.5T640-240q0 33 23.5 56.5T720-160Z" />
        </svg>
        <div className="Nv_dadosOs_container">
          <div>{AbrirNv[osKey]['Os']}</div>
          <div>{AbrirNv[osKey]['PROBLEMA']} </div>
          <div>{AbrirNv[osKey]['DATA_PAROU']}</div>
        </div>
        <div className='svgSet_Nv'>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
          </svg>
        </div>
      </div>
    ));
    }
  };
            


    const handleClosePopUp = () => {
        setfecharNsv(true)
        
        setTimeout(() => {
            document.body.style.overflow = "";
            setAbrirNv(null)
        }, 400);
        
         
     };

    return (
      <>
        {msgConteudo&&<MsgAviso msg={msg} setmsg={setmsg} msgConteudo={msgConteudo} setmsgConteudo={setmsgConteudo} fecharForm={()=>handleClosePopUp()}/>}
     
        <div className={`black_NvParada ${flecharNv?'black_NvParada_Fechar':''}`}>
            <div className={`Container_NvParada ${flecharNv?"Container_NvParada_close":""}`}>
            <div
            className="Fechar_Novaordem"
            style={{
              position: "absolute",
              width: "25px",
              height: "25px",
              right: "5px",
              top: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleClosePopUp}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
          </div>
                
                <div className="Titulo_NvParada">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M120-40q-33 0-56.5-23.5T40-120v-720q0-33 23.5-56.5T120-920h720q33 0 56.5 23.5T920-840v720q0 33-23.5 56.5T840-40H120Zm160-40 56-58-62-62h486v-240h-80v160H274l62-62-56-58-160 160L280-80Zm-80-440h80v-160h406l-62 62 56 58 160-160-160-160-56 58 62 62H200v240Z"/></svg>
                    <span>Repetir Problema</span>
                </div>
              
               
                <div className="Nv_MaquinaSelect">
                    <div>{maquina?maquina[0]:'ERRO-04'}</div>
                </div>
                <div className='tituloNv' >
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z"/></svg>
                    Selecione o serviço
                </div>

                <div className="Nv_ContainerOs">
                        {AbrirNv&&<AbrirNvOS/>}
                    
                </div>

                <div className="container_btn_Nv">
                
              <button
              style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}
               onClick={(e)=>clickObservarNv(e)} 
               type="button" 
               className="btn">{btnspn ? <Ld />:<div>Observação</div>}
               </button>
            
                    <button 
                    style={{display: 'flex', justifyContent:'center', alignItems:'center'}}
                    onClick={(e)=>clickPararNv(e)} 
                    type="button" 
                    className="btn">{btnspn ? <Ld />:<div>Parada</div>}</button>
                </div>  
            </div>
                          
        </div>
      </>
    )
}

export default AddmaqParada